import { Fira2020, Fira2020Value } from 'types/Classification'
import firebase, { database } from 'firebaseConfig'
import { Fira2020Key } from 'types/Supplier'

const FIRA2020_REF = database.collection('classification').doc('fira2020')

/**
 * Old Fira2020 document type combined with the new one.
 * Replace with the Fira2020Document type when all database entries
 * have been converted.
 */
export type OldFira2020 = Record<Fira2020Key, Fira2020Value | string[]>
export interface OldFira2020Document {
  fira2020: OldFira2020 | undefined
}

/**
 * manage fira2020 classifications
 */
class Classification {
  getFira2020 = async () => {
    const doc = await FIRA2020_REF.get()
    const data = { ...doc.data() } as OldFira2020Document

    const supplierCategories = data.fira2020
    if (!supplierCategories) {
      return undefined
    }

    // Convert from old format to new one
    const newFormat: Fira2020 = {}
    for (const key in supplierCategories) {
      const sc = supplierCategories[key]
      if (!sc) {
        newFormat[key] = undefined
      } else if (Array.isArray(sc)) {
        // Convert to new format
        newFormat[key] = {
          names: sc,
          salesforceCategoryId: ''
        }
      } else {
        // Already in the new format
        newFormat[key] = sc
      }
    }

    return newFormat
  }

  addFira2020 = async (fira2020: Fira2020) => {
    await FIRA2020_REF.set({ fira2020 })
  }

  updateFira2020 = async (key: string, values: Fira2020Value) => {
    const mapKey = `fira2020.${key}`
    await FIRA2020_REF.update({ [mapKey]: values })
  }
  deleteFira2020 = async (key: string) => {
    const mapKey = `fira2020.${key}`
    await FIRA2020_REF.update({ [mapKey]: firebase.firestore.FieldValue.delete() })
  }
}

export default new Classification()
