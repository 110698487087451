import { AppBar, Toolbar, Typography } from '@material-ui/core'
import React, { FC, useContext } from 'react'

import { ReactComponent as FiraLogo } from 'assets/svg/FiraLogoGreen.svg'
import NavMenu from './NavMenu'
import { RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { routes } from 'routes'
import styled from 'styled-components'
import { userContext } from 'stores'
import NavBarBackground from 'assets/svg/NavBarBackground.svg'

interface Props extends RouteComponentProps {}

const StyledAppBar = styled(AppBar)`
  display: flex;
  justify-content: center;
  background: url(${NavBarBackground}) right no-repeat;
  background-color: #002e33;
  border-left: 6px solid #01ff95;
`
const Container = styled.div`
  flex-grow: 1;
`

const Title = styled(Typography)`
  margin-top: 10px;
  @media all and (max-width: 600px) {
    font-size: 14px;
    margin-top: 0;
    width: 100%;
    order: 3;
  }
`

const StyledToolbar = styled(Toolbar)`
  flex-wrap: wrap;

  @media all and (max-width: 600px) {
    padding-right: 0;
  }
`

const StyledLogo = styled(FiraLogo)`
  margin-left: 56px;
  margin-right: 1em;
  &:hover {
    cursor: pointer;
  }
  @media all and (max-width: 600px) {
    width: 120px;
    margin-left: 0;
  }
`

const NavBar: FC<Props> = observer(({ history }) => {
  const userStore = useContext(userContext)
  const handleLogout = () => {
    userStore.logout(history)
  }

  return (
    <Container>
      <StyledAppBar position="sticky" elevation={0}>
        <StyledToolbar>
          <StyledLogo onClick={() => history.push(routes.suppliers)} />
          <Title variant="subtitle1">Toimittajarekisteri</Title>
          <NavMenu
            history={history}
            logout={handleLogout}
            name={userStore.name}
            initials={userStore.initials}
          />
        </StyledToolbar>
      </StyledAppBar>
    </Container>
  )
})

export default NavBar
