import React, { FC, useContext, useEffect, useState } from 'react'
import { SupplierComment } from 'types/Supplier'
import { ReactComponent as OvalPlusIcon } from 'assets/svg/oval-plus.svg'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { createTimestamp, formatIsoDate } from 'utils/utils'
import { SupplierForm } from 'api/db'
import styled from 'styled-components'
import { dbContext } from 'stores'
import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import { TableCell, TableRow, TextField, Typography } from '@material-ui/core'

interface Props {
  control: Control<SupplierForm>
  setIsDirtyComments: (value: boolean) => void
}

const FieldContainer = styled.div`
  margin-bottom: 20px;
`
const Close = styled.div`
  margin-right: 16px;
  margin-bottom: -25px;
  float: right;
  cursor: pointer;
`
const HiddenTextField = styled(TextField)`
  display: none;
`
const Cell = styled(TableCell)`
  width: 100%;
  border-bottom: none;
  padding: 0;
`
const Title = styled(Typography)`
  font-weight: 500;
  display: inline;
  margin-bottom: 6px;
`
const Timestamp = styled(Typography)`
  font-size: 11px;
  color: #888b8d;
  display: inline;
  margin-left: 3px;
  margin-bottom: 6px;
`
const ContentRow = styled.div`
  width: 364px;
`
const AddNewContainer = styled.div`
  margin-right: 16px;
  float: right;
  cursor: pointer;
`

const EditComments: FC<Props> = ({ control, setIsDirtyComments }) => {
  const [initialLength, setInitialLength] = useState(0)
  const { fields, append, remove } = useFieldArray<SupplierComment>({ control, name: 'comments' })
  const dbStore = useContext(dbContext)

  useEffect(() => {
    setInitialLength(fields.length)
    // eslint-disable-next-line
  }, [])

  // use setIsDirtyComments to handle removal of item (does not appear in dirtyFields)
  useEffect(() => {
    setIsDirtyComments(initialLength > fields.length)
    // eslint-disable-next-line
  }, [fields.length])

  return (
    <>
      {fields.map((comment, index) => (
        <FieldContainer key={comment.id}>
          <Close onClick={() => remove(index)}>
            <ClearIcon />
          </Close>
          <TableRow>
            <Cell>
              <Title as="div">{comment.createdBy}</Title>
              <Timestamp as="div">{formatIsoDate(comment.timestamp)}</Timestamp>
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>
              <ContentRow>
                <Controller
                  as={<TextField variant="outlined" fullWidth multiline minRows={4} />}
                  name={`comments[${index}].comment`}
                  control={control}
                  defaultValue={comment.comment || ''}
                />
                {/* 
                    createdBy and timestamp fields are rendered as a hidden textfields.
                    This is because these properties needs to be also passed with the
                    changed comment text, but we don't want these to be edited manually.
                */}
                <Controller
                  as={<HiddenTextField />}
                  name={`comments[${index}].createdBy`}
                  control={control}
                  defaultValue={comment.createdBy || ''}
                />
                <Controller
                  as={<HiddenTextField />}
                  name={`comments[${index}].timestamp`}
                  control={control}
                  defaultValue={comment.timestamp || ''}
                />
              </ContentRow>
            </Cell>
          </TableRow>
        </FieldContainer>
      ))}
      <AddNewContainer
        onClick={() =>
          append({
            createdBy: dbStore.getUserIdentifier(),
            timestamp: createTimestamp(),
            comment: ''
          })
        }
      >
        <OvalPlusIcon />
      </AddNewContainer>
    </>
  )
}

export default EditComments
