import { Box, Button, Grid, Typography } from '@material-ui/core'
import React, { FC, useContext, useEffect } from 'react'
import { userContext, userManagementContext } from 'stores'

import { ReactComponent as BackArrowIcon } from 'assets/svg/backArrow.svg'
import ButtonContent from 'components/common/ButtonContent'
import { Loading } from 'components/common/Styled'
import { RouteComponentProps } from 'react-router-dom'
import UserRoleList from './UserRoleList'
import { observer } from 'mobx-react-lite'
import { redirectToSuppliers } from 'routes'
import styled from 'styled-components'

interface Props extends RouteComponentProps {}

const Container = styled(Grid)`
  overflow-x: scroll;
  overflow-y: hidden;
`

const UserManagement: FC<Props> = observer(({ history }) => {
  const userManagementStore = useContext(userManagementContext)
  const userStore = useContext(userContext)
  const email = userStore.user?.email || undefined

  useEffect(() => {
    userManagementStore.getUserManagementData()
  }, [userManagementStore])

  const { admins, editors, editorRequests, users } = userManagementStore

  return (
    <>
      <Container item xs={12}>
        <Box mt={1} />
        <Button onClick={() => redirectToSuppliers(history)}>
          <ButtonContent icon={<BackArrowIcon />} text="Kaikki toimittajat" secondary />
        </Button>
        <Box mt={8} />
        <Typography variant="h5">Hallitse käyttäjiä</Typography>
        {userManagementStore.isLoading && <Loading className="small" />}
        <Box mt={3} />
        <Typography variant="body2" color="secondary">
          Käyttäjien hallinta
        </Typography>
        <Box mt={3} />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <UserRoleList users={admins} title="Ylläpitäjät" type="admin" currentEmail={email} />
          <UserRoleList users={editors} title="Muokkaajat" type="editor" currentEmail={email} />
          <UserRoleList
            users={editorRequests}
            title="Muokkauspyynnöt"
            type="editorRequest"
            currentEmail={email}
          />
          <UserRoleList
            users={users}
            authorizedUsers={[...admins, ...editors]}
            title="Käyttäjät"
            type="users"
            currentEmail={email}
          />
        </Grid>
      </Container>
    </>
  )
})

export default UserManagement
