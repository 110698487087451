import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core'
import React, { FC, useContext } from 'react'

import { dataContext } from 'stores'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const StyledCheckBox = styled(Checkbox)`
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  padding: 0px;
`

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`
const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0px;
  margin-top: 8px;
`

const NewSupplierFiltering: FC = observer(() => {
  const dataStore = useContext(dataContext)
  return (
    <StyledFormGroup row>
      <StyledFormControlLabel
        control={
          <StyledCheckBox
            checked={dataStore.showCurrentUserResponsibility}
            onChange={() => dataStore.toggleShowCurrentUserResponsibility()}
            color="secondary"
            disabled={dataStore.showMissingResponsibilities}
          />
        }
        label={<Typography variant="body2">Näytä omat toimittajat</Typography>}
      />
      <StyledFormControlLabel
        control={
          <StyledCheckBox
            checked={dataStore.showMissingResponsibilities}
            onChange={() => dataStore.toggleShowMissingResponsibilities()}
            color="secondary"
            disabled={dataStore.showCurrentUserResponsibility}
          />
        }
        label={<Typography variant="body2">Näytä toimittajat ilman vastuuhenkilöä</Typography>}
      />
    </StyledFormGroup>
  )
})

export default NewSupplierFiltering
