import { Box, Button, Grid, Typography } from '@material-ui/core'
import React, { FC, useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { redirectToSuppliers } from 'routes'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import { classificationContext, uiContext } from 'stores'
import { ReactComponent as BackArrowIcon } from 'assets/svg/backArrow.svg'
import ButtonContent from 'components/common/ButtonContent'
import { ReactComponent as CheckmarkIcon } from 'assets/svg/checkmark-green.svg'
import ClassificationAdd from './ClassificationAdd'
import ClassificationItems from './ClassificationItems'
import ClassificationList from './ClassificationList'
import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import CustomTooltip from 'components/common/CustomTooltip'
import DeleteDialog from './DeleteDialog'
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg'
import { ReactComponent as HashtagIcon } from 'assets/svg/hashtag.svg'
import { Loading } from 'components/common/Styled'
import { SupplierCategory } from 'types/Classification'

interface Props extends RouteComponentProps {}

const Item = styled(Grid)`
  max-width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  &.selected {
    background: #f8f7f4;
  }
`

const Container = styled(Grid)`
  width: 850px;
  max-width: 100%;
`

const Count = styled(Typography)`
  margin-right: 16px;
`

const Hashtag = styled(HashtagIcon)`
  margin-right: 16px;
  margin-top: 2px;
`
const StyledButton = styled(Button)`
  text-transform: none;
  padding-top: 0;
  padding-bottom: 0;
`

export interface Fira2020Value {
  value: string
}
export interface Fira2020Form {
  fira2020Values: Fira2020Value[]
}

const Classification: FC<Props> = observer(({ history }) => {
  const classificationStore = useContext(classificationContext)
  const uiStore = useContext(uiContext)
  const occurences = classificationStore.fira2020Occurrences

  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined)
  const [editedItem, setEditedItem] = useState<string | undefined>(undefined)
  const { handleSubmit, errors, control, setValue } = useForm<Fira2020Form>()

  useEffect(() => {
    classificationStore.countFira2020Occurrences()
  }, [classificationStore])

  useEffect(() => {
    if (!editedItem) {
      return
    }

    const supplierCategory = classificationStore.fira2020[editedItem]
    if (!supplierCategory) {
      setValue('fira2020Values', [])
      return
    }

    const fira2020Values = [
      { value: supplierCategory.salesforceCategoryId },
      ...supplierCategory.names.map((value) => ({ value }))
    ]
    setValue('fira2020Values', fira2020Values)
  }, [editedItem, classificationStore, setValue])

  const onSubmit = (data: Fira2020Form) => {
    const values = data.fira2020Values.map((fira2020Value) => fira2020Value.value)
    const salesforceCategoryId = values.shift() || ''
    if (!editedItem || !!errors.fira2020Values || values.length === 0) {
      return
    }
    const supplierCategory: SupplierCategory = {
      names: values,
      salesforceCategoryId: salesforceCategoryId
    }
    classificationStore.updateFira2020(editedItem, supplierCategory)
    setEditedItem(undefined)
    setSelectedItem(undefined)
  }

  const addFira2020 = (value: SupplierCategory) => {
    classificationStore.addFira2020(value)
  }

  const deleteFira2020 = (value: string) => {
    classificationStore.deleteFira2020(value)
    setEditedItem(undefined)
    setSelectedItem(undefined)
  }

  return (
    <>
      <Grid item xs={12}>
        <Box mt={1} />
        <Button onClick={() => redirectToSuppliers(history)}>
          <ButtonContent icon={<BackArrowIcon />} text="Kaikki toimittajat" secondary />
        </Button>
        <Box mt={4} />
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <HashtagIcon />
          <Box ml={2}>
            <Typography variant="h5">
              Hallitse työluokkia ({classificationStore.sortedFira2020Keys.length})
            </Typography>
            {uiStore.isLoading && <Loading />}
          </Box>
        </Grid>
        <Box mt={3} />
        <Typography variant="body2" color="secondary">
          Täältä voit hallita toimittajarekisterissä käytettäviä luokkia, joilla voidaan kuvata
          toimittajan tarjoamia palveluita.
        </Typography>
        <Box mt={3} />
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Container item>
            <ClassificationAdd handleSubmit={addFira2020} />
            <form onSubmit={handleSubmit(onSubmit)}>
              {classificationStore.sortedFira2020Keys.map((key) => {
                const supplierCategory = classificationStore.fira2020[key]
                if (!supplierCategory) {
                  return undefined
                }

                const supplierCategoryNames = supplierCategory.names
                const currentCount = occurences[key]?.length || 0
                const isEditing = editedItem === key
                const isSelected = selectedItem === key
                return (
                  <Item
                    key={key}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    onClick={() => setSelectedItem(key)}
                    className={`${isSelected && 'selected'}`}
                  >
                    <Hashtag />
                    <Grid item xs={5}>
                      {isEditing ? (
                        <ClassificationItems control={control} errors={errors} />
                      ) : (
                        <ClassificationList data={supplierCategory} />
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <CustomTooltip disableHover={false} title={occurences[key]?.join(', ') || ''}>
                        <Count color="primary">{currentCount} osumaa</Count>
                      </CustomTooltip>
                    </Grid>
                    <Grid item xs={4}>
                      {isSelected && !editedItem && (
                        <StyledButton startIcon={<EditIcon />} onClick={() => setEditedItem(key)}>
                          Muokkaa
                        </StyledButton>
                      )}
                      {isSelected && !editedItem && currentCount === 0 && (
                        <DeleteDialog
                          deleteFira2020={() => deleteFira2020(key)}
                          value={supplierCategoryNames[0]}
                        />
                      )}
                      {isEditing && (
                        <>
                          <StyledButton startIcon={<CheckmarkIcon />} type="submit">
                            Tallenna
                          </StyledButton>
                          <StyledButton
                            startIcon={<ClearIcon />}
                            onClick={() => setEditedItem(undefined)}
                          >
                            Hylkää
                          </StyledButton>
                        </>
                      )}
                    </Grid>
                  </Item>
                )
              })}
            </form>
          </Container>
        </Grid>
        <Box mt={4} />
      </Grid>
    </>
  )
})

export default Classification
