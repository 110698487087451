import React, { FC, useContext } from 'react'

import MuiAlert from '@material-ui/lab/Alert'
import { Snackbar } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { uiContext } from 'stores'

const Notifications: FC = observer(() => {
  const uiStore = useContext(uiContext)
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={uiStore.isOpen}
      onClose={uiStore.toggleSnackbar}
      autoHideDuration={6000}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={uiStore.toggleSnackbar}
        severity={uiStore.severity}
      >
        {uiStore.message}
      </MuiAlert>
    </Snackbar>
  )
})

export default Notifications
