import { Box, ButtonBase, Grid, Paper, Typography } from '@material-ui/core'
import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import React, { FC } from 'react'
import { Supplier } from 'types/Supplier'
import { ModalContent } from 'types/Modal'
import Feedback from './Feedback'
import DeliveryChallenge from './DeliveryChallenge'
import styled from 'styled-components'

interface Props {
  supplier: Supplier
  closeFeedback: (drawer?: boolean) => void
  content: ModalContent
}

const StyledContainer = styled(Grid)`
  min-height: 60vh;
`

const Title = styled(Typography)`
  word-break: break-word;
  margin-top: 24px;
  max-width: 90%;
`

const StyledPaper = styled(Paper)`
  margin-top: 16px;
  max-width: 473px;
`

const ModalContentSelector: FC<Props> = ({ supplier, closeFeedback, content }) => {
  return (
    <>
      <Grid item xs={12}>
        <Box mt={8} />
        <StyledContainer container direction="column" alignItems="center" justifyContent="center">
          <Grid item>
            <StyledPaper elevation={3}>
              <Box m={3}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Title variant="h5" align="center">
                    {supplier.supplier}
                  </Title>
                  <Box component={ButtonBase} onClick={() => closeFeedback()} mt={3}>
                    <ClearIcon />
                  </Box>
                </Grid>
                <Box mb={5} />
              </Box>
              {content === ModalContent.Feedback ? (
                <Feedback supplier={supplier} closeFeedback={closeFeedback} />
              ) : (
                <DeliveryChallenge supplier={supplier} closeFeedback={closeFeedback} />
              )}
              <Box mb={2} />
            </StyledPaper>
          </Grid>
        </StyledContainer>
      </Grid>
    </>
  )
}

export default ModalContentSelector
