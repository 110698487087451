import { Box, TableBody, TableCell, TableRow } from '@material-ui/core'
import { DrawerButton, DrawerDivider, DrawerTable, Timestamp } from 'components/common/Styled'
import React, { FC, useContext } from 'react'
import { dbContext, userContext } from 'stores'

import DisplayLog from './DisplayLog'
import DrawerSubHeader from '../shared/DrawerSubHeader'
import { formatIsoDate } from 'utils/utils'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Cell = styled(TableCell)`
  width: 100%;
  border-bottom: none;
  padding-left: 0;
  word-break: break-all;
`

interface Props {
  businessId: string
}

const ChangeLogs: FC<Props> = observer(({ businessId }) => {
  const dbStore = useContext(dbContext)
  const userStore = useContext(userContext)

  if (userStore.isViewer) {
    return <></>
  }

  return (
    <>
      <DrawerDivider />
      <DrawerSubHeader header="Muutosloki" />
      <DrawerButton variant="contained" onClick={() => dbStore.getChangelog(businessId)}>
        Hae
      </DrawerButton>
      <DrawerTable>
        <TableBody>
          {dbStore.changelog.map((changelog, key) => (
            <TableRow key={key}>
              <Cell>
                <Timestamp>{formatIsoDate(changelog.timestamp)}</Timestamp>
                <Box mt={0.5}>
                  <DisplayLog log={changelog.log} />
                </Box>
              </Cell>
            </TableRow>
          ))}
        </TableBody>
      </DrawerTable>
    </>
  )
})

export default ChangeLogs
