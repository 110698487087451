import { DrawerTable, LinkText } from 'components/common/Styled'
import { Link, TableBody } from '@material-ui/core'
import React, { FC } from 'react'

import DetailRow from './DetailRow'
import { SeasonContract } from 'types/Supplier'
import { formatMfilesDate } from 'utils/utils'
import { sortSeasonContracts } from 'utils/sorting'

interface Props {
  contracts: SeasonContract[] | undefined
}

const DrawerContracts: FC<Props> = ({ contracts }) => {
  return (
    <>
      {sortSeasonContracts(contracts).map((contract, i) => (
        <DrawerTable key={i}>
          <TableBody>
            <DetailRow
              title={
                <Link
                  href={contract.viewUrl}
                  color="inherit"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <LinkText>M-Files Näkymä</LinkText>
                </Link>
              }
              content=""
            />
            <DetailRow title="Nimi tai otsikko" content={contract.name} />
            <DetailRow title="Vastuuhenkilö" content={contract.personInCharge} />
            <DetailRow title="Päättymispäivämäärä" content={formatMfilesDate(contract.endDate)} />
            <DetailRow title="Hankinta" content={contract.acquisition} />
            <DetailRow title="Sitova sopimus" content={contract.bindingType} />
          </TableBody>
        </DrawerTable>
      ))}
    </>
  )
}

export default DrawerContracts
