import {
  ArrowDownIcon,
  DrawerContentContainer,
  DrawerTable,
  Input,
  PrimaryButton,
  StyledSelectCss
} from 'components/common/Styled'
import { Box, LinearProgress, MenuItem, Select, TableBody } from '@material-ui/core'
import { Country, displayCountry } from 'types/Country'
import React, { ChangeEvent, FC, useContext, useState } from 'react'
import { dbContext, uiContext } from 'stores'

import DetailRow from '../shared/DetailRow'
import DrawerSubHeader from '../shared/DrawerSubHeader'
import { Supplier } from 'types/Supplier'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

const HelperText = styled.div`
  position: absolute;
  font-size: 14px;
  color: #2c2a29;
`

const ExampleText = styled.div`
  color: #888b8d;
`

const Loading = styled(LinearProgress)`
  width: 200px;
`

const StyledSelect = styled((props) => (
  <Select
    {...props}
    variant="outlined"
    IconComponent={ArrowDownIcon}
    MenuProps={{
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      transformOrigin: { vertical: 'top', horizontal: 'center' },
      getContentAnchorEl: null
    }}
  />
))`
  ${StyledSelectCss}
`

interface NewSupplier {
  businessId: string
}

interface Props {
  allSuppliers: Supplier[]
}

const AddSupplier: FC<Props> = ({ allSuppliers }) => {
  const dbStore = useContext(dbContext)
  const uiStore = useContext(uiContext)

  const [isCountrySelect, setIsCountrySelect] = useState(true)
  const [country, setCountry] = useState<Country>(Country.Finland)

  const { register, handleSubmit, errors } = useForm<NewSupplier>()

  const existingBusinessIds = allSuppliers.map((supplier) => supplier.businessId)

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setCountry(event.target.value as Country)
  }

  const onSubmit = handleSubmit((data) => {
    if (isCountrySelect) {
      setIsCountrySelect(false)
      return
    }

    dbStore.getSupplierLiability(data.businessId, country)
  })

  return (
    <form onSubmit={onSubmit}>
      <DrawerContentContainer pt={2}>
        <DrawerSubHeader
          header={
            <>
              Perustiedot
              <br />
              <HelperText>
                {isCountrySelect
                  ? 'Aloita valitsemalla yrityksen perustusmaa'
                  : 'Jatka kirjoittamalla yrityksen Y-tunnus'}
              </HelperText>
            </>
          }
        />
        <Box mt={4} />
        <DrawerTable>
          <TableBody>
            {isCountrySelect ? (
              <DetailRow
                title="Perustusmaa"
                content={
                  <StyledSelect onChange={handleChange} defaultValue={Country.Finland}>
                    {Object.values(Country).map((country, key) => (
                      <MenuItem key={key} value={country}>
                        {displayCountry(country)}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                }
              />
            ) : (
              <DetailRow
                title={
                  <>
                    Y-tunnus {country === Country.Finland && <ExampleText>(1234567-8)</ExampleText>}
                  </>
                }
                content={
                  <>
                    <Input
                      autoFocus
                      name="businessId"
                      error={errors.businessId}
                      helperText={
                        errors.businessId
                          ? 'Y-tunnus löytyy jo toimittajarekisteristä tai on virheellinen'
                          : ''
                      }
                      inputRef={register({
                        required: true,
                        minLength: 3,
                        validate: (businessId) =>
                          !existingBusinessIds.includes(businessId) &&
                          (country !== Country.Finland || businessId.length === 9)
                      })}
                    />
                    {uiStore.isLoadingLiability && <Loading />}
                  </>
                }
              />
            )}
            <DetailRow
              title=""
              content={
                <PrimaryButton type="submit" variant="contained">
                  {isCountrySelect ? 'Seuraava' : 'Etsi'}
                </PrimaryButton>
              }
            />
          </TableBody>
        </DrawerTable>
      </DrawerContentContainer>
    </form>
  )
}

export default AddSupplier
