import { Feedback, Liability, SeasonContract, Supplier, creditRatingCategory } from 'types/Supplier'
import sort, { IComparer, ISortBy, ISortByObjectSorter } from 'fast-sort'

import { SortOrder } from 'components/table/base/SupplierTable'
import { SupplierCategory } from 'types/SupplierCategory'
import { Fira2020Value } from 'types/Classification'

/**
 * ranking of credit ratings
 */
export const creditRatingRanking: { [key in creditRatingCategory]: number } = {
  AAA: 1,
  AA: 2,
  A: 3,
  AN: 4,
  EIRA: 5,
  B: 6,
  C: 7,
  '': 8
}

const getCreditRatingCategory = (rating?: creditRatingCategory) => {
  if (!rating) {
    return creditRatingRanking['']
  }
  return creditRatingRanking[rating]
}

/**
 * ranking of credit ratings
 */
const liabilityRanking: { [key in Liability]: number } = {
  Ok: 1,
  Huomioi: 2,
  'Tietoja odotetaan': 3,
  Selvitä: 4,
  Seis: 5,
  '': 6
}

const getLiabilityRanking = (liability?: Liability) => {
  if (!liability) {
    return liabilityRanking['']
  }
  return liabilityRanking[liability]
}

/**
 * ranking of credit ratings
 */
const supplierCategoryRanking: { [key in SupplierCategory]: number } = {
  key: 1,
  contract: 2,
  recommended: 3,
  approved: 4,
  clarify: 5,
  denied: 6,
  pending: 7,
  prohibited: 8,
  screening: 9,
  archived: 10
}

const getSupplierCategoryRanking = (supplierCategory?: SupplierCategory) => {
  if (!supplierCategory) {
    return supplierCategoryRanking.archived
  }
  return supplierCategoryRanking[supplierCategory]
}

/**
 * Sort by binding:
 * active binding contracts -> 3
 * active non-binding contracts -> 2
 * non-active contract -> 1
 * none -> 0
 */
const contractRanking = (seasonContracts?: SeasonContract[]): number => {
  if (!seasonContracts || seasonContracts.length === 0) {
    return 0
  }
  const isActiveAndBinding = seasonContracts.some((contract) => contract.binding && contract.active)
  if (isActiveAndBinding) {
    return 3
  }
  const isActiveAndNonBinding = seasonContracts.some(
    (contract) => !contract.binding && contract.active
  )
  return isActiveAndNonBinding ? 2 : 1
}

/**
 * feedback Ranking
 * Rank based on like to dislike ratio:
 * - like-ratio == 1 -> return amount of likes
 * - like-ratio != 1 -> return value between 0-1
 * - no feedback -> return -1
 */
const feedbackRanking = (feedback?: Feedback[]): number => {
  if (!feedback || feedback.length === 0) {
    return -1
  }
  const likes = feedback.filter((x) => x.rating !== 0)
  const likeRatio = likes.length / feedback.length
  if (likeRatio === 1) {
    return likes.length
  }
  return likeRatio
}

/**
 * Sorting order
 */
export type Order = 'asc' | 'desc' | undefined

/**
 * sort null values to the top for ascending sort
 * (default is to the bottom)
 */
const nullComparer = (a: number, b: number) => {
  if (a == null) return -1
  if (b == null) return 1
  if (a < b) return -1
  if (a === b) return 0
  return 1
}

/**
 * comparer based on Intl.Collator for finnish language
 */
const finnishComparer = new Intl.Collator('fi').compare

/**
 * create object sorter based on order, sorter function, and comparer
 */
const createObjectSorter = (
  order: Order,
  sorter: ISortBy<Supplier>,
  comparer?: IComparer
): ISortByObjectSorter<Supplier> => {
  if (order === 'asc') {
    return { asc: sorter, comparer }
  }
  return { desc: sorter, comparer }
}

/**
 * Custom sorting based on fast-sort
 * @param sortOrder first item in array will be ordered first, second secondly, ...
 */
export const customSort = (suppliers: Supplier[], sortOrder: SortOrder[]) => {
  // perform sorting on a copy
  const list = suppliers.slice()

  const sortables = sortOrder.map(({ property, order }) => {
    if (property === 'feedback') {
      return createObjectSorter(order, (supplier) => feedbackRanking(supplier.feedback))
    }
    if (property === 'seasonContracts') {
      return createObjectSorter(order, (supplier) => contractRanking(supplier.seasonContracts))
    }
    if (property === 'liability') {
      const inverted: Order = order === 'asc' ? 'desc' : 'asc'
      return createObjectSorter(inverted, (supplier) => getLiabilityRanking(supplier.liability))
    }
    if (property === 'creditRating') {
      const inverted: Order = order === 'asc' ? 'desc' : 'asc'
      return createObjectSorter(inverted, (supplier) =>
        getCreditRatingCategory(supplier.creditRating)
      )
    }
    if (property === 'runningSpend') {
      return createObjectSorter(order, (supplier) => supplier[property], nullComparer)
    }
    if (property === 'supplierCategory') {
      const inverted: Order = order === 'asc' ? 'desc' : 'asc'
      return createObjectSorter(inverted, (supplier) =>
        getSupplierCategoryRanking(supplier.supplierCategory)
      )
    }
    if (property === 'supplier') {
      return createObjectSorter(order, (supplier) => supplier[property], finnishComparer)
    }
    return createObjectSorter(order, (supplier) => supplier[property])
  })

  return sort(list).by(sortables)
}

export const sortAlphabetically = (list: { key: string; value: Fira2020Value }[]) => {
  return sort(list).by({
    asc: (u) => {
      const names = u.value?.names || []
      return names.length > 0 ? names[0] : ''
    },
    comparer: finnishComparer
  })
}

export const sortSeasonContracts = (contracts: SeasonContract[] | undefined) => {
  if (!contracts) {
    return []
  }
  return contracts.slice().sort((a, b) => b.binding - a.binding)
}
