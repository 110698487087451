import { Box, Button, Grid, Hidden, Modal, Typography } from '@material-ui/core'
import React, { FC, useContext, useState } from 'react'

import ButtonContent from 'components/common/ButtonContent'
import CollapsibleDropdown from 'components/common/CollapsibleDropdown'
import Guide from './Guide'
import { ReactComponent as InfoDarkIcon } from 'assets/svg/info-dark.svg'
import SegmentSelect from './SegmentSelect'
import SupplierSearch from './SupplierSearch'
import SupplierTableFiltering from './SupplierTableFiltering'
import SupplierTableMenu from './SupplierTableMenu'
import { dataContext } from 'stores'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const TitleRow = styled(Grid)`
  margin-top: 30px;
`

const StyledModal = styled(Modal)`
  overflow-y: scroll;
`

const Container = styled(Grid)`
  margin-top: 24px;
`
const StyledButton = styled(Button)`
  padding-left: 0;
  padding-right: 16px;
  float: right;
`

const MobileButton = styled(Button)`
  padding-left: 0;
`

const StyledTitle = styled(Typography)`
  margin-right: 14px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const SupplierTableInfo: FC = observer(() => {
  const dataStore = useContext(dataContext)

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Container item xs={12}>
      <Grid container direction="column" justifyContent="space-between">
        <Grid item>
          <Hidden xsDown>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <SupplierSearch />
              </Grid>
              <Grid item>
                <SegmentSelect />
              </Grid>
              <Grid item md lg xl>
                <StyledButton onClick={handleOpen}>
                  <ButtonContent icon={<InfoDarkIcon />} text="Selitteet" />
                </StyledButton>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <SupplierSearch />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <CollapsibleDropdown title="Näytä muut hakuehdot" isLarge>
                <SegmentSelect />
                <Box mt={1} />
                <SupplierTableFiltering />
                <Box mt={1} />
                <SupplierTableMenu isMobile />
                <Box mt={1} />
                <MobileButton onClick={handleOpen}>
                  <ButtonContent icon={<InfoDarkIcon />} text="Selitteet" />
                </MobileButton>
              </CollapsibleDropdown>
            </Grid>
          </Hidden>
        </Grid>
        <Hidden xsDown>
          <Grid item>
            <SupplierTableFiltering />
          </Grid>
        </Hidden>
        <TitleRow item>
          <Grid container direction="row" justifyContent="space-between">
            <TitleContainer>
              <StyledTitle variant="h3">Toimittajat </StyledTitle>
              <Typography>
                ({`${dataStore.suppliers.length}/${dataStore.totalSuppliers}`})
              </Typography>
            </TitleContainer>
            <Hidden xsDown>
              <SupplierTableMenu />
            </Hidden>
          </Grid>
        </TitleRow>
      </Grid>
      <StyledModal open={open} onClose={handleClose}>
        <>
          <Guide handleClose={handleClose} />
        </>
      </StyledModal>
    </Container>
  )
})

export default SupplierTableInfo
