import { DrawerTable, LinkText } from 'components/common/Styled'
import { Link, TableBody } from '@material-ui/core'
import React, { FC, ReactNode } from 'react'
import {
  currency,
  formatBisnodeDate,
  formatIsoDate,
  getEmploymentCategory,
  getRevenueCategory,
  isBankruptcyRisk,
  withHttps
} from 'utils/utils'
import AdditionalInfo from './AdditionalInfo'
import DetailRow from './DetailRow'
import DrawerSubHeader from './DrawerSubHeader'
import LiabilityIndicator from '../edit/LiabilityIndicator'
import { Supplier } from 'types/Supplier'
import { getRatingColor } from 'components/table/base/SupplierTableRow'
import DisplayContacts from '../view/DisplayContacts'

interface Props {
  supplier: Supplier
  isNewSupplier?: boolean
  name: string | ReactNode
  classifications: string | ReactNode
  placeOfBusiness: string | ReactNode
  supplierCategory: string | ReactNode
  isViewer: boolean
}

const BasicInformation: FC<Props> = ({
  supplier,
  isNewSupplier,
  name,
  classifications,
  placeOfBusiness,
  supplierCategory,
  isViewer
}) => {
  return (
    <>
      <DrawerSubHeader header="Perustiedot" />
      <DrawerTable>
        <TableBody>
          <DetailRow title="Toimittajan nimi" content={name} />
          <DetailRow
            title="Y-tunnus"
            content={
              isNewSupplier ? (
                <LiabilityIndicator
                  businessId={supplier.businessId}
                  liability={supplier.liability}
                />
              ) : (
                supplier.businessId
              )
            }
          />
          <DetailRow title="Luokat" content={classifications} />
          <DisplayContacts
            additionalInfo={supplier.additionalInfo}
            contacts={supplier.contacts}
            isViewer={isViewer}
            responsibleUser={supplier.responsibleUser}
            renderOnlyDetailRow={true}
          />
          <DetailRow
            title="Yrityksen koko"
            content={getEmploymentCategory(supplier.employees)}
            convertCase
          />
          <DetailRow
            title="Yrityksen liikevaihto"
            content={getRevenueCategory(supplier.revenue)}
            convertCase
          />
          <DetailRow title="Juoksevat ostot (12kk)" content={currency(supplier.runningSpend)} />
          <DetailRow title="Päätoimipiste" content={placeOfBusiness} />
          <DetailRow title="Toimittajaluokka" content={supplierCategory} />
          <DetailRow
            title="Luottoluokka"
            content={supplier.creditRating}
            color={getRatingColor(supplier.creditRating)}
          />
          <DetailRow
            title="Luottoluokitus päivä"
            content={formatBisnodeDate(supplier.creditRatingDate)}
          />
          <DetailRow
            title="Edellinen luottoluokitus"
            content={supplier.previousCreditRating}
            color={getRatingColor(supplier.previousCreditRating)}
          />
          <DetailRow
            title="Kotisivu"
            content={
              <Link
                href={withHttps(supplier.website)}
                color="inherit"
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkText>{supplier.website}</LinkText>
              </Link>
            }
          />
          <DetailRow
            title="Tilaajavastuu"
            content={
              <LiabilityIndicator
                businessId={supplier.businessId}
                liability={supplier.liability}
                isInline
              />
            }
          />
          {supplier.defaultCode && isBankruptcyRisk(supplier.defaultCode) && (
            <>
              {supplier.defaultCode && (
                <DetailRow title="Konkurssimerkintä" content={supplier.defaultCode} />
              )}
              {supplier.defaultDate && (
                <DetailRow
                  title="Konkurssimerkintä päivämäärä"
                  content={formatBisnodeDate(supplier.defaultDate)}
                />
              )}
            </>
          )}
          {supplier.tradeRegister && (
            <DetailRow
              title="Kaupparekisteristä poistettu"
              content={formatBisnodeDate(supplier.tradeRegisterDate)}
            />
          )}
          {isNewSupplier && supplier.source && (
            <DetailRow title="Lähde" content={supplier.source} convertCase />
          )}
          {supplier.modifiedBy && <DetailRow title="Muokkaaja" content={supplier.modifiedBy} />}
          {supplier.modifiedAt && (
            <DetailRow title="Muokattu" content={formatIsoDate(supplier.modifiedAt)} />
          )}
        </TableBody>
      </DrawerTable>
      {supplier.additionalInfo && <AdditionalInfo additionalInfo={supplier.additionalInfo} />}
    </>
  )
}

export default BasicInformation
