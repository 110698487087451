import React, { FC, ReactNode, isValidElement } from 'react'
import { TableCell, TableRow } from '@material-ui/core'

import { ConvertUpperCaseCss } from 'components/common/Styled'
import styled from 'styled-components'

export const TitleCell = styled(TableCell)`
  border-bottom: none;
  font-weight: 600;
  width: 140px;
  padding-left: 0;
  vertical-align: top;

  &.grayTitle {
    color: #888b8d;
  }
`

export const Cell = styled(TableCell)`
  border-bottom: none;
  word-break: break-word;
  width: 180px;
  height: 10px;
  @media all and (max-width: 404px) {
    width: 50%;
  }

  &.capitalize {
    text-transform: capitalize;
  }
  &.td-reactnode {
    padding-top: 6.5px;
  }
  &.green {
    color: green;
  }
  &.orange {
    color: orange;
  }
  &.red {
    color: red;
  }
  ${ConvertUpperCaseCss}
`

interface Props {
  title: string | ReactNode
  content: string | ReactNode
  convertCase?: boolean
  grayTitle?: boolean
  capitalize?: boolean
  color?: string
}

const DetailRow: FC<Props> = ({ title, content, convertCase, grayTitle, color, capitalize }) => {
  return (
    <TableRow>
      <TitleCell className={`${grayTitle && 'grayTitle'}`}>{title}</TitleCell>
      <Cell
        className={
          isValidElement(content)
            ? 'td-reactnode'
            : `${convertCase && 'convertUpperCase'} ${color} ${capitalize && 'capitalize'}`
        }
      >
        {content}
      </Cell>
    </TableRow>
  )
}

export default DetailRow
