import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { PrimaryButton, SecondaryButton } from 'components/common/Styled'
import React, { ChangeEvent, FC, useContext, useState } from 'react'
import { dbContext } from 'stores'
import { Supplier } from 'types/Supplier'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

interface Props {
  supplier: Supplier
  closeFeedback: (drawer?: boolean) => void
}

const StyledTextfield = styled(TextField)`
  padding: 0;
  width: 100%;
`

const ContentContainer = styled(Grid)`
  padding: 24px;
  @media all and (min-width: 550px) {
    width: 473px;
  }
`

const DeliveryChallenge: FC<Props> = observer(({ supplier, closeFeedback }) => {
  const dbStore = useContext(dbContext)
  const [issueSite, setIssueSite] = useState('')
  const [issueDetails, setIssueDetails] = useState('')

  const handleIssueSiteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIssueSite(event.target.value)
  }

  const handleIssueDetailsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIssueDetails(event.target.value)
  }

  const handleSubmit = () => {
    if (issueSite && issueDetails) {
      dbStore.addSupplierIssue(supplier, issueSite, issueDetails)
      closeFeedback(true)
    }
  }

  const isSubmitEnabled: boolean = !!issueSite && !!issueDetails

  return (
    <>
      <Box m={3}>
        <Typography component="h1" variant="body1" align="left">
          Onko yrityksellä ollut toimitushaasteita?
          <br />
          Ilmoita niistä täällä.
        </Typography>
      </Box>
      <ContentContainer container direction="column" alignItems="center">
        <Box mb={2} />
        <StyledTextfield
          placeholder="Toimitushaastetta koskeva työmaa"
          multiline
          minRows={1}
          variant="outlined"
          onChange={handleIssueSiteChange}
          style={{ marginTop: '9px' }}
        />
        <StyledTextfield
          placeholder="Kuvaile minkälaisia ja millä aikavälillä toimittajalla on ollut toimitusvaikeuksia teidän työmaallanne"
          multiline
          minRows={4}
          variant="outlined"
          onChange={handleIssueDetailsChange}
          style={{ marginTop: '9px' }}
        />
        <Box mb={5} />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <SecondaryButton variant="contained" onClick={() => closeFeedback()}>
            Peruuta
          </SecondaryButton>
          <Box ml={3} />
          <PrimaryButton variant="contained" onClick={handleSubmit} disabled={!isSubmitEnabled}>
            Lähetä palaute
          </PrimaryButton>
        </Grid>
      </ContentContainer>
    </>
  )
})

export default DeliveryChallenge
