/**
 * Toimittajaluokittelu, vaihtoehdot
 */
export enum SupplierCategoryValues {
  key = 'Avain',
  contract = 'Kausisopimus',
  recommended = 'Suositeltu',
  approved = 'Hyväksytty',
  clarify = 'Selvitä',
  denied = 'Ei hyväksytty',
  prohibited = 'Ostokielto',
  archived = 'Arkistoitu',
  pending = 'Ilman hyväksyntää',
  screening = 'Taustatarkistus'
}

export const getCategoryValue = (category: SupplierCategory | undefined) => {
  return category ? SupplierCategoryValues[category] : SupplierCategoryValues.pending
}

/**
 * Toimittajaluokittelu
 */
export type SupplierCategory = keyof typeof SupplierCategoryValues

/**
 * Toimittajaluokittelu, disabled options
 */
export const DisabledSupplierCategory: SupplierCategory[] = ['pending', 'screening']
