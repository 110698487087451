import { Grid, Typography } from '@material-ui/core'
import React, { FC } from 'react'

import styled from 'styled-components'

import { Fira2020Value } from 'types/Classification'

interface Props {
  data: Fira2020Value
}

const Title = styled(Typography)`
  &.synonym {
    color: #888b8d;
    font-size: 0.8rem;
  }
`
const IdView = styled(Typography)`
  font-size: 0.8rem;
`

const ClassificationList: FC<Props> = ({ data }) => {
  const nameList: string[] = data?.names || []
  const salesforceCategoryId = data?.salesforceCategoryId

  const header = nameList[0]
  const synonyms = []
  for (let i = 1; i < nameList.length; i++) {
    synonyms.push(nameList[i])
  }

  return (
    <Grid item xs={8}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <Title key={'header'}>{header}</Title>
        {salesforceCategoryId && (
          <IdView key={'id'}>{`Kategorian ID: ${salesforceCategoryId}`}</IdView>
        )}
        {synonyms.map((value, index) => (
          <Title key={index} className="synonym">
            {`Synonyymi: ${value}`}
          </Title>
        ))}
      </Grid>
    </Grid>
  )
}

export default ClassificationList
