import { Control, Controller, DeepMap, FieldError, useFieldArray } from 'react-hook-form'
import { Fira2020Form, Fira2020Value } from './Classification'
import { Grid, TextField, Typography } from '@material-ui/core'
import React, { FC } from 'react'

import { Action } from 'components/common/Styled'
import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import { ReactComponent as OvalPlusIcon } from 'assets/svg/oval-plus.svg'
import styled from 'styled-components'

interface Props {
  control: Control<Fira2020Form>
  errors: DeepMap<Fira2020Form, FieldError>
}

const Input = styled(TextField)`
  width: 290px;
  margin-right: 10px;
  margin-bottom: 8px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 2px;
  background: white;
`

const Header = styled(Typography)`
  font-size: 0.8rem;
`

const ClassificationItems: FC<Props> = ({ control, errors }) => {
  const { fields, append, remove } = useFieldArray<Fira2020Value>({
    control,
    name: 'fira2020Values'
  })
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
      {fields.map((item, index) => (
        <React.Fragment key={index}>
          {index === 0 && <Header>Kategorian ID:</Header>}
          {index === 1 && <Header>Nimet:</Header>}
          <Grid container direction="row" alignItems="center">
            <Controller
              as={
                <Input
                  variant="outlined"
                  error={errors.fira2020Values && !!errors.fira2020Values[index]}
                />
              }
              name={`fira2020Values[${index}].value`}
              control={control}
              rules={{ required: index > 0, minLength: 1 }}
              defaultValue={item.value}
            />
            {index > 1 && (
              <Action onClick={() => remove(index)} className="small-height">
                <ClearIcon />
              </Action>
            )}
          </Grid>
        </React.Fragment>
      ))}
      <Action onClick={() => append({ value: '' })} className="margin-large">
        <OvalPlusIcon />
      </Action>
    </Grid>
  )
}

export default ClassificationItems
