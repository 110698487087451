import 'mobx-react-lite/batchingForReactDom'
import './index.css'

import App from 'App'
import { CssBaseline } from '@material-ui/core'
import GlobalStyles from 'components/GlobalStyles'
import React from 'react'
import ReactDOM from 'react-dom'

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
