import { Feedback, SupplierIssue, Supplier, SupplierDocument } from 'types/Supplier'
import firebase, { database } from 'firebaseConfig'

import { createTimestamp } from 'utils/utils'
import pickBy from 'lodash.pickby'

const SUPPLIER_COLLECTION = 'suppliers'
const NEW_SUPPLIER_COLLECTION = 'suppliersNew'
const CHANGELOG = 'changelog'

type BaseForm = Omit<SupplierDocument, 'businessId'>

export interface SupplierForm extends BaseForm {
  [key: string]: unknown
}

export interface Changelog {
  businessId: string
  log: string
  timestamp: string
}

class DB {
  /**
   * create locally a SupplierDocument from the supplier form
   * return only values that are not undefined
   * (-> we only need to store modified values)
   */
  createSupplierDocument = (modifiedBy: string, supplier: Supplier, supplierForm: SupplierForm) => {
    const supplierDocument: SupplierDocument = {
      ...supplierForm,
      businessId: supplier.businessId,
      modifiedAt: createTimestamp(),
      modifiedBy
    }
    // only return values that are defined
    const cleanedDocument = pickBy(supplierDocument, (v) => v !== undefined) as SupplierDocument
    return cleanedDocument
  }

  /**
   * getAllSuppliers with cache option.
   * cache prevents unnessesarely reading the entire suppliers collection if nothing has changed
   */
  getAllSuppliers = async () => {
    const snapshot = await database.collection(SUPPLIER_COLLECTION).get({ source: 'server' })
    return snapshot.docs.map((doc) => ({ ...doc.data() } as SupplierDocument))
  }

  getAllNewSuppliers = async () => {
    const snapshot = await database.collection(NEW_SUPPLIER_COLLECTION).get()
    return snapshot.docs.map((doc) => ({ ...doc.data() } as SupplierDocument))
  }

  /**
   *  add or update existing document in NEW_SUPPLIER_COLLECTION
   * @param supplier document content
   */
  addNewSupplier = async (supplier: SupplierDocument) => {
    await database
      .collection(NEW_SUPPLIER_COLLECTION)
      .doc(supplier.businessId)
      .set({ ...supplier }, { merge: true })
  }

  /**
   *  get new supplier
   */
  getNewSupplier = async (businessId: string) => {
    const docRef = await database.collection(NEW_SUPPLIER_COLLECTION).doc(businessId).get()
    return docRef.data() as SupplierDocument | undefined
  }

  /**
   * update or create document in SUPPLIER_COLLECTION
   * @param supplier document content
   */
  updateSupplier = async (supplier: SupplierDocument) => {
    await database
      .collection(SUPPLIER_COLLECTION)
      .doc(supplier.businessId)
      .set({ ...supplier }, { merge: true })
  }

  /**
   * update and delete supplier document in a batch
   */
  updateAndDeleteNewSupplier = async (supplier: SupplierDocument, isNew: boolean) => {
    const batch = database.batch()
    const supplierRef = database.collection(SUPPLIER_COLLECTION).doc(supplier.businessId)
    const newSupplierRef = database.collection(NEW_SUPPLIER_COLLECTION).doc(supplier.businessId)
    batch.set(supplierRef, { ...supplier }, { merge: true })
    if (isNew) {
      batch.delete(newSupplierRef)
    }
    await batch.commit()
  }

  /**
   *  get changelog by businessId
   */
  getChangelog = async (businessId: string) => {
    const snapshot = await database
      .collection(CHANGELOG)
      .where('businessId', '==', businessId)
      .orderBy('timestamp', 'desc')
      .get()
    return snapshot.docs.map((doc) => ({ ...doc.data() } as Changelog))
  }

  /**
   *  add a rating by businessId
   *  create or append the feedback array
   *  return updated supplier
   */
  addFeedback = async (supplier: Supplier, newFeedback: Feedback) => {
    const supplierDocument = {
      businessId: supplier.businessId,
      modifiedAt: createTimestamp(),
      feedback: firebase.firestore.FieldValue.arrayUnion(newFeedback)
    }
    await database
      .collection(SUPPLIER_COLLECTION)
      .doc(supplier.businessId)
      .set(supplierDocument, { merge: true })
    return {
      ...supplier,
      feedback: [...(supplier.feedback || []), newFeedback]
    } as Supplier
  }

  /**
   *  add a supplier issue report by businessId
   *  create or append the issues array
   *  return updated supplier
   */
  addSupplierIssue = async (supplier: Supplier, newIssue: SupplierIssue) => {
    const supplierDocument = {
      businessId: supplier.businessId,
      modifiedAt: createTimestamp(),
      issues: firebase.firestore.FieldValue.arrayUnion(newIssue)
    }
    await database
      .collection(SUPPLIER_COLLECTION)
      .doc(supplier.businessId)
      .set(supplierDocument, { merge: true })
    return {
      ...supplier,
      issues: [...(supplier.issues || []), newIssue]
    } as Supplier
  }
}

export default new DB()
