import { Button, Grid } from '@material-ui/core'
import React, { FC } from 'react'

import { FallbackProps } from 'react-error-boundary'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ErrorFallback: FC<FallbackProps> = ({ error, componentStack, resetErrorBoundary }) => {
  if (!error) return <></>
  return (
    <Grid container direction="column" alignItems="center">
      Jotain meni pieleen.
      <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
        Lataa uudestaan
      </Button>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
    </Grid>
  )
}
