import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core'
import React, { FC, useContext } from 'react'
import { dataContext, userContext } from 'stores'

import { Loading } from 'components/common/Styled'
import { SupplierFilter } from 'types/Supplier'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const StyledCheckBox = styled(Checkbox)`
  border-radius: 4px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  padding: 0px;
`

const StyledFormGroup = styled(FormGroup)`
  margin-top: 16px;
`
const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0px;
  margin-top: 8px;
`

const SupplierTableFiltering: FC = observer(() => {
  const dataStore = useContext(dataContext)
  const userStore = useContext(userContext)
  const hasContracts = dataStore.activeFilters.includes(SupplierFilter.ActiveContracts)
  const archivedOnly = dataStore.activeFilters.includes(SupplierFilter.ArchivedSuppliersOnly)
  const ownSuppliers = dataStore.activeFilters.includes(SupplierFilter.OwnSuppliers)
  const hasPersonalWorksiteSuppliers = dataStore.activeFilters.includes(
    SupplierFilter.PersonalWorksiteSuppliers
  )

  const handlePersonalWorksiteSuppliersToggle = () => {
    if (!userStore.hasLoadedPersonalWorksiteSupplierIds) {
      userStore.setIsLoadingPersonalWorksiteSupplierIds(true)
      userStore.getPersonalWorksiteSupplierIds()
    }
    dataStore.setActiveFilters(
      SupplierFilter.PersonalWorksiteSuppliers,
      !hasPersonalWorksiteSuppliers
    )
  }

  return (
    <StyledFormGroup row>
      <StyledFormControlLabel
        control={
          <StyledCheckBox
            checked={hasContracts}
            onChange={() =>
              dataStore.setActiveFilters(SupplierFilter.ActiveContracts, !hasContracts)
            }
            color="secondary"
          />
        }
        label={<Typography variant="body2">Vain voimassa olevat kausisopimukset</Typography>}
      />
      <StyledFormControlLabel
        control={
          <StyledCheckBox
            disabled={userStore.isLoadingPersonalWorksiteSupplierIds}
            checked={
              hasPersonalWorksiteSuppliers && !userStore.isLoadingPersonalWorksiteSupplierIds
            }
            onChange={handlePersonalWorksiteSuppliersToggle}
            color="secondary"
          ></StyledCheckBox>
        }
        label={
          <>
            <Typography variant="body2">Näytä omien työmaiden toimittajat</Typography>
            {userStore.isLoadingPersonalWorksiteSupplierIds && <Loading className="x-small" />}
          </>
        }
      />
      {!userStore.isViewer && (
        <StyledFormControlLabel
          control={
            <StyledCheckBox
              checked={archivedOnly}
              onChange={() =>
                dataStore.setActiveFilters(SupplierFilter.ArchivedSuppliersOnly, !archivedOnly)
              }
              color="secondary"
            />
          }
          label={<Typography variant="body2">Näytä vain arkistoidut toimittajat</Typography>}
        />
      )}
      {!userStore.isViewer && (
        <StyledFormControlLabel
          control={
            <StyledCheckBox
              checked={ownSuppliers}
              onChange={() =>
                dataStore.setActiveFilters(SupplierFilter.OwnSuppliers, !ownSuppliers)
              }
              color="secondary"
            />
          }
          label={<Typography variant="body2">Näytä omat toimittajat</Typography>}
        />
      )}
    </StyledFormGroup>
  )
})

export default SupplierTableFiltering
