import { observable, runInAction, makeObservable } from 'mobx'

import ClassificationStore from './ClassificationStore'
import DBStore from './DBStore'
import DataStore from './DataStore'
import { Supplier } from 'types/Supplier'
import UIStore from './UIStore'
import UserManagementStore from './UserManagementStore'
import UserStore from './UserStore'
import db from 'api/db'
import { getSuppliersJson } from 'api/storage'

/**
 * RootStore instantiates all stores, and share the references.
 * Access references from each store.
 * Makes potential unit tests easy as you just have to instantiate a root store
 */
export default class RootStore {
  dataStore: DataStore
  dbStore: DBStore
  uiStore: UIStore
  userStore: UserStore
  userManagementStore: UserManagementStore
  classificationStore: ClassificationStore

  // instantiate and provide root store for each store
  constructor() {
    this.dataStore = new DataStore(this)
    this.dbStore = new DBStore(this)
    this.uiStore = new UIStore(this)
    this.userStore = new UserStore(this)
    this.userManagementStore = new UserManagementStore(this)
    this.classificationStore = new ClassificationStore(this)
    makeObservable(this)
  }

  @observable isInitialized = false

  // initialize data by loading supplier json and firestore data
  initialize = async () => {
    this.uiStore.isLoading = true
    try {
      const [jsonSuppliers, dbSuppliers, newSuppliers] = await Promise.all([
        getSuppliersJson(),
        db.getAllSuppliers(),
        db.getAllNewSuppliers(),
        this.classificationStore.getFira2020(),
        this.userStore.setUserLoginState()
      ])

      // apply firestore data and categorize suppliers based on supplier category
      const baseData: Supplier[] = this.dataStore.updateDataset(jsonSuppliers, dbSuppliers)
      const categorizedSuppliers = baseData.filter(
        (supplier) => supplier.supplierCategory && supplier.businessId
      )
      const uncategorizedSuppliers = baseData.filter((supplier) => !supplier.supplierCategory)

      runInAction(() => {
        this.dataStore.initializeSuppliers(categorizedSuppliers)
        this.dataStore.initializeNewSuppliers(uncategorizedSuppliers, newSuppliers)
        this.uiStore.isLoading = false
      })
    } catch (error) {
      this.uiStore.handleError(error)
      this.uiStore.isLoading = false
    }
    this.isInitialized = true
  }
}
