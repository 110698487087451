import { Button, Grid } from '@material-ui/core'
import React, { FC } from 'react'

import ButtonContent from 'components/common/ButtonContent'
import { ReactComponent as CheckmarkDisabledIcon } from 'assets/svg/checkmarkDisabled.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/svg/checkmark.svg'
import { ReactComponent as ClearDisabledIcon } from 'assets/svg/clearDisabled.svg'
import { ReactComponent as ClearWhiteIcon } from 'assets/svg/clearWhite.svg'
import styled from 'styled-components'

const ButtonContainer = styled(Grid)`
  height: 45px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  position: fixed;
  top: 64px;
  width: 404px;
  overflow-x: hidden;
  z-index: 10000;
`

interface Props {
  disableSubmit?: boolean
  submitText: string
  cancelText: string
  handleCancel: () => void
}

const FormButtons: FC<Props> = ({ disableSubmit, submitText, cancelText, handleCancel }) => {
  return (
    <ButtonContainer container direction="row" justifyContent="space-evenly">
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        type="submit"
        disabled={disableSubmit}
      >
        <ButtonContent
          icon={disableSubmit ? <CheckmarkDisabledIcon /> : <CheckmarkIcon />}
          text={submitText}
        />
      </Button>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        onClick={handleCancel}
        disabled={disableSubmit}
      >
        <ButtonContent
          icon={disableSubmit ? <ClearDisabledIcon /> : <ClearWhiteIcon />}
          text={cancelText}
        />
      </Button>
    </ButtonContainer>
  )
}

export default FormButtons
