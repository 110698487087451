import { ListItem, ListItemText, TableBody } from '@material-ui/core'
import React, { FC } from 'react'

import CollapsibleDropdown from 'components/common/CollapsibleDropdown'
import DetailRow from 'components/drawer/shared/DetailRow'
import { DrawerTable } from 'components/common/Styled'
import { SupplierContact } from 'types/Supplier'
import { displayContacts } from 'utils/utils'
import styled from 'styled-components'

interface Props {
  additionalInfo?: string
  contacts?: SupplierContact[]
  isViewer: boolean
  responsibleUser?: string
  renderOnlyDetailRow?: boolean
}

const ItemText = styled(ListItemText)`
  & > span {
    font-size: 13px;
  }
`

const Container = styled.div`
  margin-top: 6px;
`

const DisplayContacts: FC<Props> = ({
  additionalInfo,
  contacts,
  isViewer,
  responsibleUser,
  renderOnlyDetailRow
}) => {
  const displayedContacts = displayContacts(contacts, additionalInfo)

  const content = () => (
    <>
      <DetailRow
        title="Yhteyshenkilöt"
        content={
          <Container>
            {displayedContacts.map((contact, index) => (
              <CollapsibleDropdown
                key={index}
                title={`${contact.firstName || ''} ${contact.lastName || ''}`}
                secondaryList
              >
                <ListItem dense disableGutters>
                  <ItemText primary={contact.role} />
                </ListItem>
                <ListItem dense disableGutters>
                  <ItemText primary={contact.email} />
                </ListItem>
                <ListItem dense disableGutters>
                  <ItemText primary={contact.phone} />
                </ListItem>
              </CollapsibleDropdown>
            ))}
          </Container>
        }
      />
      {!isViewer && <DetailRow title="Firan vastuuhenkilö" content={responsibleUser} />}
    </>
  )

  return (
    <>
      {renderOnlyDetailRow ? (
        content()
      ) : (
        <DrawerTable>
          <TableBody>{content()}</TableBody>
        </DrawerTable>
      )}
    </>
  )
}

export default DisplayContacts
