import { Liability } from 'types/Supplier'
import { UserRole } from 'stores/UserStore'
import { functions } from 'firebaseConfig'

interface LiabilityResponse {
  supplier: string
  liability: Liability
}

interface PdfResponse {
  data: string
}

interface WorksiteSuppliers {
  businessIds: string[]
}

const getSupplierLiability = functions.httpsCallable('getSupplierLiability')
const auth = functions.httpsCallable('auth')
const setCustomClaimsNewUser = functions.httpsCallable('setCustomClaimsNewUser')
const getLiabilityPdf = functions.httpsCallable('getLiabilityPdf')
const getPersonalWorksiteSuppliers = functions.httpsCallable('getPersonalWorksiteSuppliers')

/**
 * Firebase callable function to get supplier liability (tilaajavaastuu)
 */
export const callGetSupplierLiability = async (businessId: string) => {
  const callableResult = await getSupplierLiability({ businessId })
  return callableResult.data as LiabilityResponse | null
}

/**
 * Firebase callable function to get supplier liability (tilaajavaastuu)
 */
export const callGetLiabilityPdf = async (businessId: string) => {
  const callableResult = await getLiabilityPdf({ businessId })
  const result = callableResult.data as PdfResponse | null

  if (!result) {
    return result
  }
  return result.data
}

/**
 * Firebase callable function to validate and generate a custom firebase auth token
 */
export const callAuth = async (token: string, email: string) => {
  const callableResult = await auth({ token, email })
  return callableResult.data as { firebaseToken: string }
}

/**
 * Firebase callable function to apply custom claims for new users
 */
export const callSetCustomClaimsNewUser = async () => {
  const callableResult = await setCustomClaimsNewUser()
  return callableResult.data as UserRole | null
}

/**
 * Firebase callable function to get personal worksite suppliers
 */
export const callGetPersonalWorksiteSuppliers = async (email: string) => {
  const callableResult = await getPersonalWorksiteSuppliers({ email })
  return callableResult.data as WorksiteSuppliers | null
}
