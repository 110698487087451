import { Box, Grid, Modal, Typography } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { PrimaryButton, Timestamp, DrawerInnerDivider } from 'components/common/Styled'
import { calculatePercent, filterOutOldFeedbacks, formatIsoDate } from 'utils/utils'
import { redirectToDeliveryChallenge, redirectToFeedback, redirectToSupplier } from 'routes'
import { useHistory, useParams } from 'react-router-dom'
import { ModalContent } from 'types/Modal'
import CollapsibleDropdown from 'components/common/CollapsibleDropdown'
import CustomTooltip from 'components/common/CustomTooltip'
import ModalContentSelector from './ModalContentSelector'
import FeedbackButton from './FeedbackButton'
import { Feedback as FeedbackType } from 'types/Supplier'
import { Supplier } from 'types/Supplier'
import { SupplierRouteParams } from 'components/table/Suppliers'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  overflow-y: scroll;
`

const Grade = styled(Grid)`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  &.number {
    font-weight: 600;
  }
  &.info {
    font-size: 10px;
    line-height: 16px;
    color: #888b8d;
  }
`

const Title = styled.div`
  font-size: 13px;
`
const GradeTooltip = styled.div`
  padding: 14px;
`
const GradeSummary = styled.div`
  margin-bottom: 12px;
`
const Count = styled.div`
  color: #888b8d;
  display: inline-block;
`

const Text = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
`
const Comment = styled(Typography)`
  font-size: 14px;
  font-style: italic;
`

const IssueSite = styled(Typography)`
  font-size: 11px;
  color: #888b8d;
  margin-left: 3px;
  width: 100%;
`

const FeedBackContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`

interface Props {
  supplier: Supplier
  interactable?: boolean
  isNewSuppliers?: boolean
  closeDrawer?: () => void
  tableView?: boolean // component is rendered in front page table view
}

const createTitle = (feedback: FeedbackType[] | undefined) => {
  let title = ''
  let hasMoreLikes = false
  const modifiedFeedback = filterOutOldFeedbacks(feedback)
  const like = modifiedFeedback.filter((x) => x.rating !== 0)
  const dislike = modifiedFeedback.filter((x) => x.rating === 0)
  if (modifiedFeedback && modifiedFeedback.length > 0 && like && dislike) {
    hasMoreLikes = like.length >= dislike.length
    if (hasMoreLikes) {
      title = `${calculatePercent(like.length, modifiedFeedback.length)}% Suosittelee`
    } else {
      title = `${calculatePercent(dislike.length, modifiedFeedback.length)}% Ei suosittele`
    }
  } else {
    title = 'Ei suositteluja'
  }
  return { hasMoreLikes, title }
}

const DisplayFeedback: FC<Props> = ({
  supplier,
  interactable,
  isNewSuppliers,
  closeDrawer,
  tableView
}) => {
  const history = useHistory()
  const params = useParams<SupplierRouteParams>()
  const [open, setOpen] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContent>(ModalContent.Feedback)

  useEffect(() => {
    if (interactable && supplier.businessId) {
      if (params.path === 'palaute') {
        setModalContent(ModalContent.Feedback)
        setOpen(true)
        redirectToFeedback(supplier.businessId, history)
      }
      if (params.path === 'toimitushaaste') {
        setModalContent(ModalContent.DeliveryChallenge)
        setOpen(true)
        redirectToDeliveryChallenge(supplier.businessId, history)
      }
    }
  }, [params.path, history, supplier.businessId, interactable])

  const handleOpen = (type: ModalContent) => {
    setModalContent(type)
    setOpen(true)
    if (type === ModalContent.Feedback) {
      redirectToFeedback(supplier.businessId, history)
    }
    if (type === ModalContent.DeliveryChallenge) {
      redirectToDeliveryChallenge(supplier.businessId, history)
    }
  }

  const handleClose = (drawer?: boolean) => {
    setOpen(false)
    if (drawer && closeDrawer) {
      closeDrawer()
    } else {
      redirectToSupplier(supplier.businessId, history)
    }
  }

  const unfilteredRatings = supplier.feedback?.length || 0
  const filteredRatings = filterOutOldFeedbacks(supplier.feedback).length || 0

  const totalIssues = supplier.issues?.length || 0
  const { hasMoreLikes, title } = createTitle(supplier.feedback)

  return (
    <>
      {tableView ? null : <Text>{`Suosittelupalaute (${filteredRatings})`}</Text>}
      <FeedBackContainer>
        <FeedbackButton
          rating={hasMoreLikes ? 1 : 0}
          totalRatings={filteredRatings}
          enableMenu={!interactable}
          supplier={supplier}
        />
        <CustomTooltip
          disableHover={!!interactable}
          title={
            <GradeTooltip>
              <GradeSummary>
                Laaja arvio <Count>({supplier.grade?.count || 0})</Count>
              </GradeSummary>
              <b>{supplier.grade?.average || 0}</b> keskiarvo
            </GradeTooltip>
          }
          isSmall
        >
          <Title>{title}</Title>
        </CustomTooltip>
        {tableView ? (
          <Box
            ml={0.5}
            mt={filteredRatings === 0 ? 0.7 : undefined}
            color="text.secondary"
            whiteSpace="nowrap"
          >
            {filteredRatings > 0 && `(${filteredRatings})`}
          </Box>
        ) : null}
      </FeedBackContainer>
      {interactable && (
        <>
          <DrawerInnerDivider />
          <CollapsibleDropdown title={`Palaute (${unfilteredRatings})`} secondary titleBolded>
            <>
              {supplier.feedback
                ?.sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
                .map((feedback, index) => (
                  <div key={index}>
                    <Box mt={1} mb={1}>
                      <FeedbackButton
                        rating={feedback.rating}
                        totalRatings={1}
                        supplier={supplier}
                      />
                    </Box>
                    <Text>{feedback.ratedBy}</Text>
                    <Timestamp>{formatIsoDate(feedback.timestamp)}</Timestamp>
                    <Comment>{feedback.comment}</Comment>
                  </div>
                ))}
            </>
          </CollapsibleDropdown>
          <DrawerInnerDivider />
          <CollapsibleDropdown
            title={`Laaja toimittaja-arvio (${supplier.grade?.count || 0})`}
            fullWidth
            titleBolded
          >
            {supplier.grade && (
              <>
                <Grid container>
                  <Grade item xs={12} className="info">
                    Asteikko 1-5
                  </Grade>
                  <Grade item xs={2} className="number">
                    {supplier.grade.average || '-'}
                  </Grade>
                  <Grade item xs={10}>
                    Keskiarvo
                  </Grade>
                  <Grade item xs={2} className="number">
                    {supplier.grade.safety || '-'}
                  </Grade>
                  <Grade item xs={10}>
                    Työturvallisuus
                  </Grade>
                  <Grade item xs={2} className="number">
                    {supplier.grade.quality || '-'}
                  </Grade>
                  <Grade item xs={10}>
                    Laatu
                  </Grade>
                  <Grade item xs={2} className="number">
                    {supplier.grade.schedule || '-'}
                  </Grade>
                  <Grade item xs={10}>
                    Aikataulu
                  </Grade>
                  <Grade item xs={2} className="number">
                    {supplier.grade.cooperation || '-'}
                  </Grade>
                  <Grade item xs={10}>
                    Yhteistyö
                  </Grade>
                  <Grade item xs={2} className="number">
                    {supplier.grade.compilance || '-'}
                  </Grade>
                  <Grade item xs={10}>
                    Sopimuksen noudattaminen
                  </Grade>
                  <Grade item xs={2} className="number">
                    {supplier.grade.billing || '-'}
                  </Grade>
                  <Grade item xs={10}>
                    Laskutus
                  </Grade>
                  <Grade item xs={2} className="number">
                    {supplier.grade.additionalWork || '-'}
                  </Grade>
                  <Grade item xs={10}>
                    Lisä-ja muutostyöt
                  </Grade>
                  {supplier.grade.recyclingAndEnvironment && (
                    <>
                      <Grade item xs={2} className="number">
                        {supplier.grade.recyclingAndEnvironment}
                      </Grade>
                      <Grade item xs={10}>
                        Kierrätys ja ympäristö
                      </Grade>
                    </>
                  )}
                  {supplier.grade.managementOfSubcontractors && (
                    <>
                      <Grade item xs={2} className="number">
                        {supplier.grade.managementOfSubcontractors}
                      </Grade>
                      <Grade item xs={10}>
                        Aliurakoitsijoiden johtaminen
                      </Grade>
                    </>
                  )}
                </Grid>
              </>
            )}
          </CollapsibleDropdown>
          <PrimaryButton
            variant="contained"
            onClick={() => handleOpen(ModalContent.Feedback)}
            disabled={isNewSuppliers}
          >
            Arvioi toimittaja
          </PrimaryButton>
          <DrawerInnerDivider />
          <CollapsibleDropdown title={`Toimitushaaste (${totalIssues})`} secondary titleBolded>
            <>
              {supplier.issues?.map((issue, index) => (
                <div key={index}>
                  <Text>{issue.createdBy}</Text>
                  <Timestamp>{formatIsoDate(issue.timestamp)}</Timestamp>
                  <IssueSite>{issue.site}</IssueSite>
                  <Comment>{issue.details}</Comment>
                </div>
              ))}
            </>
          </CollapsibleDropdown>
          <PrimaryButton
            variant="contained"
            onClick={() => handleOpen(ModalContent.DeliveryChallenge)}
            disabled={isNewSuppliers}
          >
            Ilmoita toimitushaasteista
          </PrimaryButton>
          <StyledModal open={open}>
            <>
              <ModalContentSelector
                supplier={supplier}
                closeFeedback={handleClose}
                content={modalContent}
              />
            </>
          </StyledModal>
        </>
      )}
    </>
  )
}

export default DisplayFeedback
