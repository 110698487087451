import { Grid, MenuItem, Select } from '@material-ui/core'
import React, { FC, useContext } from 'react'
import styled, { css } from 'styled-components'

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg'
import { MainSegment } from 'types/Supplier'
import { dataContext } from 'stores'
import { observer } from 'mobx-react-lite'

const ArrowDownIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
  margin-top: 9px;
  margin-right: 7px;
`

const EmptyLinja = styled.div`
  color: #888b8d;
`

const SelectStyles = css`
  fieldset {
    border-radius: 2px;
  }
  && .MuiSelect-select:focus {
    background-color: transparent;
  }
  && .MuiOutlinedInput-input {
    padding: 0px 5px;
    font-size: 14px;
    line-height: 40px;
    width: 251px;
    height: 40px;
    margin-left: 16px;
    padding-right: 28px;
  }
`

const StyledSelect = styled((props) => (
  <Select {...props} displayEmpty variant="outlined" multiple IconComponent={ArrowDownIcon} />
))`
  ${SelectStyles}
`

const SegmentSelect: FC = observer(() => {
  const dataStore = useContext(dataContext)

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dataStore.setSelectedLinja(event.target.value as MainSegment[])
  }

  const handleClose = () => {
    dataStore.search(dataStore.latestSearch)
  }

  return (
    <Grid item xs={12}>
      <StyledSelect
        value={dataStore.selectedLinja}
        multiple
        onClose={handleClose}
        onChange={handleChange}
        displayEmpty
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          getContentAnchorEl: null
        }}
        renderValue={(value: string[]) => {
          if (value.length === 0) {
            return <EmptyLinja>Rakentamisen Linja</EmptyLinja>
          }
          return value.join(', ')
        }}
      >
        <MenuItem value={MainSegment.ResidentalConstruction}>
          {MainSegment.ResidentalConstruction}
        </MenuItem>
        <MenuItem value={MainSegment.CommercialConstruction}>
          {MainSegment.CommercialConstruction}
        </MenuItem>
        <MenuItem value={MainSegment.Modernisation}>{MainSegment.Modernisation}</MenuItem>
        <MenuItem value={MainSegment.PipeRenovations}>{MainSegment.PipeRenovations}</MenuItem>
      </StyledSelect>
    </Grid>
  )
})

export default SegmentSelect
