import { Cell, TitleCell } from './DetailRow'
import { ListItem, ListItemText, TableBody, TableRow } from '@material-ui/core'
import { MainSegments, SupplierPurchase } from 'types/Supplier'
import React, { FC, useContext } from 'react'
import { currency, groupBy } from 'utils/utils'

import CollapsibleDropdown from 'components/common/CollapsibleDropdown'
import { DrawerTable } from 'components/common/Styled'
import { SupplierProject } from 'types/Supplier'
import { dataContext } from 'stores'
import styled from 'styled-components'

const StyledCurrency = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #92918e;
`

interface Props {
  projects?: SupplierProject[]
  purchases?: SupplierPurchase[]
}

const DrawerProjects: FC<Props> = ({ projects, purchases }) => {
  const segments: SupplierProject[][] = groupBy(projects, (project) => project.projectSegmentName)
  const dataStore = useContext(dataContext)

  return (
    <DrawerTable>
      <TableBody>
        {MainSegments.map((displayedSegment, index) => {
          // select current grouped segments
          const currentSegment = segments.find(
            (segment) => segment[0].projectSegmentName === displayedSegment
          )
          const currentTotal = purchases?.find(
            (purchase) => purchase.projectSegmentName === displayedSegment
          )

          const segment = currentSegment || []
          const segmentTotal = currentTotal?.amount || 0
          return (
            <TableRow key={index}>
              <TitleCell>
                {displayedSegment}
                <br />
                <StyledCurrency>{currency(segmentTotal)}</StyledCurrency>
              </TitleCell>
              <Cell>
                <CollapsibleDropdown title={`${segment.length} työmaata`} secondaryList>
                  {segment.map(({ projectName, promisee }, key) => (
                    <ListItem key={key} dense disableGutters>
                      <ListItemText
                        primary={`${projectName} / Sopimuskumppani: ${dataStore.getSupplierName(
                          promisee
                        )}`}
                      />
                    </ListItem>
                  ))}
                </CollapsibleDropdown>
              </Cell>
            </TableRow>
          )
        })}
      </TableBody>
    </DrawerTable>
  )
}

export default DrawerProjects
