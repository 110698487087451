import {
  Button,
  ButtonGroup,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import React, { FC, useContext } from 'react'

import { ReactComponent as CheckmarkIcon } from 'assets/svg/checkmark-green.svg'
import { ReactComponent as ClearIcon } from 'assets/svg/clear-small.svg'
import { UserDocument } from 'api/userManagement'
import { ReactComponent as UserLargeGrayIcon } from 'assets/svg/user-large-gray.svg'
import { formatIsoDate } from 'utils/utils'
import styled from 'styled-components'
import { userManagementContext } from 'stores'

interface Props {
  currentEmail?: string
  title: string
  users: UserDocument[]
  authorizedUsers?: UserDocument[]
  type: ListType
}

type ListType = 'admin' | 'editor' | 'editorRequest' | 'users'

type ButtonText = {
  accept: string
  deny: string
}

const ButtonContent: Record<ListType, ButtonText> = {
  admin: { accept: '', deny: 'Poista oikeus' },
  editor: { accept: 'Tee ylläpitäjäksi', deny: 'Poista oikeus' },
  editorRequest: { accept: 'Hyväksy', deny: 'Hylkää' },
  users: { accept: 'Tee muokkaajaksi', deny: 'Kirjaa ulos' }
}

const ButtonContainer = styled(ButtonGroup)`
  display: block;
`

const Container = styled(Grid)`
  min-width: 578px;
`

const StyledButton = styled(Button)`
  text-transform: none;
  border: none;
  &:hover {
    border: none;
  }
`

const StyledListItemIcon = styled(ListItemIcon)`
  margin-bottom: 12px;
`

const StyledList = styled(List)`
  & > li {
    height: 72px;
  }
  & > li:nth-of-type(even) {
    background-color: #f6f5f1;
  }
`

const UserRoleList: FC<Props> = ({ users, title, type, currentEmail, authorizedUsers }) => {
  const userManagementStore = useContext(userManagementContext)

  let displayedUsers = [...users]

  // only display users that are not editors/admins
  if (type === 'users') {
    const authorizedEmails = authorizedUsers?.map((user) => user.email) || []
    displayedUsers = displayedUsers.filter(({ email }) => !authorizedEmails.includes(email))
  }

  const handleDeny = (userData: UserDocument) => {
    userManagementStore.setIsLoading(true)
    if (type === 'editorRequest') {
      userManagementStore.removeEditorRequest(userData)
    }
    if (type === 'editor') {
      userManagementStore.removeEditor(userData)
    }
    if (type === 'admin') {
      userManagementStore.removeAdmin(userData)
    }
  }

  const handleAccept = (userData: UserDocument) => {
    userManagementStore.setIsLoading(true)
    if (type === 'users') {
      userManagementStore.addEditor(false, userData)
    }
    if (type === 'editorRequest') {
      userManagementStore.addEditor(true, userData)
    }
    if (type === 'editor') {
      userManagementStore.addAdmin(userData)
    }
  }

  return (
    <Container item xs={12} md={6}>
      <Typography variant="subtitle1" color="secondary">
        {title}
      </Typography>
      <StyledList>
        {displayedUsers.map((user, index) => (
          <ListItem key={index}>
            <StyledListItemIcon>
              <UserLargeGrayIcon />
            </StyledListItemIcon>
            <ListItemText
              primary={user.name}
              secondary={
                <>
                  {user.email && (
                    <>
                      {user.email} <br />
                    </>
                  )}
                  {type === 'users'
                    ? `Viimeksi kirjautunut: ${formatIsoDate(user.timestamp)}`
                    : formatIsoDate(user.timestamp)}
                </>
              }
            />
            {user.email !== currentEmail && (
              <ButtonContainer color="secondary" size="small">
                {type !== 'users' && (
                  <StyledButton startIcon={<ClearIcon />} onClick={() => handleDeny(user)}>
                    {ButtonContent[type].deny}
                  </StyledButton>
                )}
                {type !== 'admin' && (
                  <StyledButton startIcon={<CheckmarkIcon />} onClick={() => handleAccept(user)}>
                    {ButtonContent[type].accept}
                  </StyledButton>
                )}
              </ButtonContainer>
            )}
          </ListItem>
        ))}
      </StyledList>
    </Container>
  )
}

export default UserRoleList
