import React, { FC, useContext, useState } from 'react'

import BubbleIcon from 'assets/svg/bubble.svg'
import { Popover } from '@material-ui/core'
import { Supplier } from 'types/Supplier'
import { ReactComponent as ThumbsDownSmallIcon } from 'assets/svg/thumbs-down-small.svg'
import { ReactComponent as ThumbsUpSmallIcon } from 'assets/svg/thumbs-up-small.svg'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { dbContext } from 'stores'
import styled from 'styled-components'

interface Props {
  rating: number | null
  totalRatings: number
  enableMenu?: boolean
  supplier: Supplier
}

const StyledPaper = styled.div`
  background: transparent;
  background-image: url(${BubbleIcon});
  background-position: center top;
  width: 97px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;

  && > button {
    margin-bottom: 7px;
    margin-left: 5px;
    margin-right: 6px;
    width: 34px;
    height: 34px;

    & > span > svg {
      width: 18.92px;
      height: 18.92px;
    }
  }
`

export const StyledThumb = styled(ToggleButton)`
  background: #f5f2ed;
  margin-left: 24px;
  margin-right: 24px;

  @keyframes pulse {
    0% {
      transform: scale(1) rotate(0deg);
    }
    50% {
      transform: scale(1.3) rotate(-5deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }

  &.MuiToggleButtonGroup-groupedHorizontal,
  &.MuiToggleButton-sizeSmall {
    border: none;
    border-radius: 50%;
  }
  &.selected:not(.hoverable) {
    & > span {
      animation: pulse 0.7s ease 0s 1 normal;
    }
    & > span > svg > path {
      fill: white;
    }
  }
  &.hoverable:hover {
    & > span {
      animation: pulse 0.7s ease 0s 1 normal;
    }
  }

  &.up.selected {
    background: #6fcf97;
  }
  &.up.gray {
    background: #c8c9c7;
  }
  &.down.selected {
    background: #fa1228;
  }

  &.small {
    margin: 0;
    margin-right: 8px;
  }
`

const FeedbackButton: FC<Props> = ({ supplier, rating, totalRatings, enableMenu }) => {
  const dbStore = useContext(dbContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!enableMenu) {
      return
    }
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation()
    setAnchorEl(null)
  }
  const hasRatings = totalRatings > 0
  const isNegative = rating === 0 && hasRatings

  return (
    <>
      <StyledThumb
        className={hasRatings ? `${isNegative ? 'down' : 'up'} selected small` : `up gray small`}
        size="small"
        value={rating}
        onClick={handleClick}
      >
        {isNegative ? <ThumbsDownSmallIcon /> : <ThumbsUpSmallIcon />}
      </StyledThumb>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        getContentAnchorEl={null}
        PaperProps={{ component: StyledPaper, elevation: 0 }}
      >
        <StyledThumb
          className={`up selected small hoverable`}
          size="small"
          onClick={(e) => {
            dbStore.addFeedback(supplier, 1, '')
            handleClose(e)
          }}
        >
          <ThumbsUpSmallIcon />
        </StyledThumb>
        <StyledThumb
          className={`down selected small hoverable`}
          size="small"
          onClick={(e) => {
            dbStore.addFeedback(supplier, 0, '')
            handleClose(e)
          }}
        >
          <ThumbsDownSmallIcon />
        </StyledThumb>
      </Popover>
    </>
  )
}

export default FeedbackButton
