import { Collapse, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import React, { FC, useState } from 'react'

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg'
import styled from 'styled-components'

const StyledListItem = styled(ListItem)`
  padding-top: 3px;
  margin-top: -15px;

  &.secondary {
    margin-top: 0px;
    padding-top: 0px;
  }

  &.basic {
    margin-top: -9px;
  }
  &.fullWidth {
    width: 100%;
    padding-bottom: 0;
  }
  &.fullDrawer {
    width: 50%;
    padding-bottom: 0;
  }

  &.isLarge {
    margin-top: 0px;
    width: 185px;

    div > span {
      font-size: 15px;
    }
  }
`

const StyledChildList = styled(List)`
  &.secondaryList {
    color: #888b8d;
  }
`
const ArrowDownIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
`

const Title = styled(ListItemText)`
  max-width: 95%;
`

const BoldedTitle = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
`

interface Props {
  title: string
  secondary?: boolean
  isLarge?: boolean
  fullWidth?: boolean
  secondaryList?: boolean
  basic?: boolean
  fullDrawer?: boolean
  titleBolded?: boolean
}

const CollapsibleDropdown: FC<Props> = ({
  title,
  secondary,
  isLarge,
  children,
  fullWidth,
  secondaryList,
  basic,
  fullDrawer,
  titleBolded
}) => {
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }
  return (
    <List>
      <StyledListItem
        dense
        button
        disableGutters
        disableRipple
        onClick={handleToggle}
        className={`
        ${secondary && 'secondary'} 
        ${isLarge && 'isLarge'} 
        ${fullDrawer && 'fullDrawer'} 
        ${basic && 'basic'} ${fullWidth && 'fullWidth'}`}
      >
        {titleBolded ? (
          <BoldedTitle>{title}</BoldedTitle>
        ) : (
          <Title primary={title} color={secondary ? 'text.secondary' : undefined} />
        )}
        {open ? <ArrowIcon /> : <ArrowDownIcon />}
      </StyledListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <StyledChildList disablePadding className={`${secondaryList && 'secondaryList'}`}>
          {children}
        </StyledChildList>
      </Collapse>
    </List>
  )
}

export default CollapsibleDropdown
