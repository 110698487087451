import { MainSegment, Supplier, SupplierDocument, SupplierFilter } from 'types/Supplier'
import { SortOrder, sortBySupplierCategory } from 'components/table/base/SupplierTable'
import { action, computed, makeObservable, observable } from 'mobx'
import { isSeasonContractInSegment, updateDataset } from 'utils/utils'

import RootStore from './RootStore'
import SearchEngine from 'api/SearchEngine'

/**
 * manage search, filtering, pagination, supplier arrays
 */
export default class DataStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }
  private searchEngine: SearchEngine = new SearchEngine([])

  @observable suppliersAll: Supplier[] = []
  // visible suppliers
  @observable suppliers: Supplier[] = []
  @observable sortOrder: SortOrder[] = [sortBySupplierCategory]
  // searching
  @observable searchResult: Supplier[] = []
  @observable searchMatches: string[] = []
  @observable latestSearch = ''
  @observable exactMatch = false
  @observable hasSearchEngineSorting = false
  // used to rerender search field
  @observable searchFieldKey = false
  // filtering
  @observable activeFilters: SupplierFilter[] = []
  // selected linja
  @observable selectedLinja: MainSegment[] = []
  // pagination
  @observable page = 0
  // uudet toimittajat
  @observable newSuppliers: Supplier[] = []
  @observable pageNewSuppliers = 0
  @observable showCurrentUserResponsibility = false
  @observable showMissingResponsibilities = false

  @computed get totalSuppliers() {
    const archivedOnly = this.activeFilters.includes(SupplierFilter.ArchivedSuppliersOnly)
    if (archivedOnly) {
      return this.suppliersAll.filter((sup) => sup.supplierCategory === 'archived').length
    }
    return this.suppliersAll.filter((sup) => sup.supplierCategory !== 'archived').length
  }

  @computed get totalNewSuppliers() {
    return this.newSuppliers.length
  }

  @computed get ownNewSuppliers() {
    return this.newSuppliers.filter(
      (supplier) =>
        supplier.responsibleUser === this.rootStore.userManagementStore.currentAuthorizedUser?.email
    )
  }
  @computed get noneResponsibilityNewSuppliers() {
    return this.newSuppliers.filter((supplier) => !supplier.responsibleUser)
  }

  /**
   * initialize supplier and search data
   */
  @action
  initializeSuppliers(suppliers: Supplier[]) {
    this.suppliersAll = suppliers
    this.applySetSuppliers(suppliers)
  }

  @action
  clearSearch = async (preservePaging?: boolean) => {
    this.applySetSuppliers(this.suppliersAll)
    this.searchMatches = []
    this.hasSearchEngineSorting = false
    this.exactMatch = false
    this.latestSearch = ''
    this.searchFieldKey = !this.searchFieldKey
    this.rootStore.uiStore.selectedSupplier = undefined
    this.rootStore.uiStore.editSupplier = undefined
    if (!preservePaging) {
      this.page = 0
    }
  }

  updateDataset = (suppliers: Supplier[], dbSuppliers: SupplierDocument[] | Supplier[]) => {
    const fira2020 = this.rootStore.classificationStore.fira2020
    const data: Supplier[] = updateDataset(suppliers, dbSuppliers, fira2020)
    return data
  }

  /**
   * reindex search engine by applying updated fira2020 values
   */
  reindexSearchValues = () => {
    const fira2020 = this.rootStore.classificationStore.fira2020
    this.suppliersAll.forEach((supplier) => {
      supplier.fira2020AllValues = supplier.fira2020?.flatMap((item) => fira2020[item]?.names || [])
    })
    this.applySetSuppliers(this.suppliersAll)
  }

  @action
  initializeNewSuppliers = (suppliers: Supplier[], newSuppliers: SupplierDocument[]) => {
    const data: Supplier[] = this.updateDataset(suppliers, newSuppliers)
    this.newSuppliers = data
  }

  /**
   * handle updating supplier data locally
   */
  @action
  handleUpdateSuppliers = (dbSupplier: SupplierDocument | Supplier) => {
    const data: Supplier[] = this.updateDataset(this.suppliersAll, [dbSupplier])
    this.initializeSuppliers(data)
    this.search(this.latestSearch, undefined, true)
  }

  /**
   * handle remove new supplier locally
   */
  @action
  handleRemoveNewSupplier = (businessId: string) => {
    this.newSuppliers = this.newSuppliers.filter((supplier) => supplier.businessId !== businessId)
  }

  /**
   * set the sorted suppliers
   */
  @action
  setSortOrder = (sortOrder: SortOrder[]) => {
    this.sortOrder = sortOrder
  }

  /**
   * handle adding new supplier locally
   */
  @action
  handleAddNewSupplier = (dbSupplier: SupplierDocument) => {
    const data: Supplier[] = this.updateDataset(this.newSuppliers, [dbSupplier])
    this.newSuppliers = data
    this.rootStore.uiStore.selectedSupplier = undefined
  }

  @action
  search = async (pattern: string, exactMatch?: boolean, preservePaging?: boolean) => {
    if (!pattern.length) {
      this.clearSearch(preservePaging)
      return
    }
    if (exactMatch !== undefined) {
      this.exactMatch = exactMatch
    }
    if (!preservePaging) {
      this.page = 0
    }

    this.latestSearch = pattern
    // filter and apply results to the searchengine
    const filteredSuppliers = this.filterSuppliers(this.suppliersAll)
    this.searchEngine.setCollection(filteredSuppliers)
    const searchResults = this.searchEngine.search(pattern, this.exactMatch)
    this.searchResult = searchResults.suppliers
    this.applySetSuppliers(searchResults.suppliers)
    this.searchMatches = searchResults.matches
    this.hasSearchEngineSorting = true
  }

  @action
  setHasSearchEngineSorting = (value: boolean) => {
    this.hasSearchEngineSorting = value
  }

  @action
  setPage = (page: number) => {
    this.page = page
  }
  @action
  setPageNewSuppliers = (page: number) => {
    this.pageNewSuppliers = page
  }

  @action
  setSelectedLinja = (linja: MainSegment[]) => {
    this.selectedLinja = linja
  }

  @action
  /**
   * RULE for filtering:
   * - toimittaja on ollut linjan projektilla Site Managerissa
   * - TAI toimittajalla on viimeisen 12 kk aikana ollut laskuja linjan projektille
   * - TAI toimittajalla on sitova kausisopimus linjalle (M-files)
   */
  applyLinjaFilter = (suppliers: Supplier[]) => {
    return suppliers.filter(
      (supplier) =>
        // toimittaja on ollut linjan projektilla Site Managerissa
        supplier.projects?.some(({ projectSegmentName }) =>
          this.selectedLinja.includes(projectSegmentName as MainSegment)
        ) ||
        // toimittajalla on viimeisen 12 kk aikana ollut laskuja linjan projektille
        supplier.purchases?.some(({ projectSegmentName }) =>
          this.selectedLinja.includes(projectSegmentName as MainSegment)
        ) ||
        // toimittajalla on sitova kausisopimus linjalle (M-files)
        isSeasonContractInSegment(this.selectedLinja, supplier.seasonContracts)
    )
  }

  @action
  private applySetSuppliers = (suppliers: Supplier[]) => {
    const filteredSuppliers = this.filterSuppliers(suppliers)
    this.searchEngine.setCollection(filteredSuppliers)
    this.suppliers = filteredSuppliers
  }

  @action
  setActiveFilters = (filter: SupplierFilter, active: boolean) => {
    this.activeFilters = active
      ? [...this.activeFilters, filter]
      : this.activeFilters.filter((current) => current !== filter)
    this.search(this.latestSearch)
  }

  @action
  toggleShowCurrentUserResponsibility = () => {
    this.showCurrentUserResponsibility = !this.showCurrentUserResponsibility
  }

  @action
  toggleShowMissingResponsibilities = () => {
    this.showMissingResponsibilities = !this.showMissingResponsibilities
  }

  getSupplierName = (businessId: string) => {
    if (businessId === '') {
      return 'Fira'
    }
    const name = this.suppliersAll.find((supplier) => supplier.businessId === businessId)?.supplier
    return name || businessId || ''
  }

  /**
   * filter suppliers
   * firstly, filter based on (rakentamisen linja)
   * secondly apply activeFilters
   */
  private filterSuppliers(suppliers: Supplier[]) {
    let filteredSuppliers = suppliers

    if (this.selectedLinja.length > 0) {
      filteredSuppliers = this.applyLinjaFilter(suppliers)
    }

    // filter contracts based on if they are active (voimassaoleva)
    if (this.activeFilters.includes(SupplierFilter.ActiveContracts)) {
      filteredSuppliers = filteredSuppliers.filter((sup) =>
        sup.seasonContracts?.some((contract) => contract.active)
      )
    }
    // filter archived only
    if (this.activeFilters.includes(SupplierFilter.ArchivedSuppliersOnly)) {
      filteredSuppliers = filteredSuppliers.filter((sup) => sup.supplierCategory === 'archived')
    } else {
      filteredSuppliers = filteredSuppliers.filter((sup) => sup.supplierCategory !== 'archived')
    }

    // filter own suppliers
    if (this.activeFilters.includes(SupplierFilter.OwnSuppliers)) {
      filteredSuppliers = filteredSuppliers.filter(
        (sup) =>
          sup.responsibleUser === this.rootStore.userManagementStore.currentAuthorizedUser?.email
      )
    }
    // filter personal worksite suppliers
    if (this.activeFilters.includes(SupplierFilter.PersonalWorksiteSuppliers)) {
      filteredSuppliers = filteredSuppliers.filter((sup) =>
        this.rootStore.userStore.personalWorksiteSupplierIds.includes(sup.businessId)
      )
    }

    return filteredSuppliers
  }
}
