import { Box, Button, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core'
import React, { FC, useContext } from 'react'
import { dataContext, uiContext } from 'stores'

import { ReactComponent as BackArrowIcon } from 'assets/svg/backArrow.svg'
import ButtonContent from 'components/common/ButtonContent'
import { Loading } from 'components/common/Styled'
import NewSupplierFiltering from './controls/NewSupplierFiltering'
import { RouteComponentProps } from 'react-router-dom'
import { Supplier } from 'types/Supplier'
import SupplierDrawer from 'components/drawer/SupplierDrawer'
import SupplierTable from './base/SupplierTable'
import { observer } from 'mobx-react-lite'
import { redirectToSuppliers } from 'routes'

interface Props extends RouteComponentProps {}

const SuppliersNew: FC<Props> = observer(({ history }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  const dataStore = useContext(dataContext)
  const uiStore = useContext(uiContext)

  const handleChangePage = (_e: unknown, newPage: number) => {
    dataStore.setPageNewSuppliers(newPage)
  }

  let suppliers: Supplier[] = dataStore.newSuppliers

  if (dataStore.showCurrentUserResponsibility) {
    suppliers = dataStore.ownNewSuppliers
  }
  if (dataStore.showMissingResponsibilities) {
    suppliers = dataStore.noneResponsibilityNewSuppliers
  }

  return (
    <>
      <Grid item xs={12}>
        <Box mt={1} />
        <Button onClick={() => redirectToSuppliers(history)}>
          <ButtonContent icon={<BackArrowIcon />} text="Kaikki toimittajat" secondary />
        </Button>
        <Box mt={8} />
        <Typography variant="h5">
          Uudet Toimittajat ({`${suppliers.length}/${dataStore.newSuppliers.length}`})
        </Typography>
        {uiStore.isLoading && <Loading className="small" />}
        <Box mt={3} />
        <Typography variant="body2" color="secondary">
          Täältä löydät uudet toimittajat. Tarkista toimittajien tiedot ja hyväksy tai hylkää
          toimittajat. <br />
          Hyväksyessäsi toimittajan, toimittaja siirtyy toimittajarekisteriin muiden nähtäväksi.
        </Typography>
        <Box mt={3} />
        <NewSupplierFiltering />
      </Grid>
      <SupplierTable
        suppliers={suppliers}
        page={dataStore.pageNewSuppliers}
        handleChangePage={handleChangePage}
        hasSearchEngineSorting={false}
        columns={isMobile ? uiStore.mobileColumns : uiStore.selectedColumns}
        isNewSuppliers
      />
      <SupplierDrawer supplier={uiStore.selectedSupplier} isNewSuppliers={true} />
    </>
  )
})

export default SuppliersNew
