import { Grid, Typography } from '@material-ui/core'
import React, { FC, ReactNode } from 'react'

import styled from 'styled-components'

interface Props {
  header: string | ReactNode
}

const Container = styled(Grid)`
  margin-bottom: 16px;
`

const DrawerSubHeader: FC<Props> = ({ header }) => {
  return (
    <Container container direction="row" alignItems="center">
      <Grid item>
        <Typography variant="h6">{header}</Typography>
      </Grid>
    </Container>
  )
}

export default DrawerSubHeader
