import { Supplier } from 'types/Supplier'
import { analytics } from 'firebaseConfig'

export const logSearch = (searchTerm: string) => {
  try {
    analytics.logEvent('search', { search_term: searchTerm })
  } catch (error) {
    console.log('failed to log analytics: logSearch')
    console.log(error)
  }
}

export const logViewSupplier = (supplier: Supplier) => {
  try {
    analytics.logEvent('view_supplier', {
      business_id: supplier.businessId,
      name: supplier.supplier
    })
  } catch (error) {
    console.log('failed to log analytics: logViewSupplier')
    console.log(error)
  }
}
