import { action, computed, observable, makeObservable } from 'mobx'
import userManagement, { EditorDocument, UserDocument } from 'api/userManagement'

import RootStore from './RootStore'
import { createTimestamp } from 'utils/utils'

export default class UserManagementStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable isLoading = false

  @observable users: UserDocument[] = []
  /**
   * Users from the user collection that have a role other than viewer
   */
  @observable authorizedUsers: UserDocument[] = []

  @observable admins: EditorDocument[] = []
  @observable editors: EditorDocument[] = []
  @observable editorRequests: UserDocument[] = []

  @computed get currentAuthorizedUser() {
    return this.authorizedUsers.find((user) => user.email === this.rootStore.userStore.user?.email)
  }

  @action
  setIsLoading = async (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  @action
  getUserManagementData = async () => {
    this.setIsLoading(true)
    await Promise.all([this.getUsers(), this.getEditors(), this.getEditorRequests()])
    this.setIsLoading(false)
  }

  @action
  getUsers = async () => {
    try {
      const users = await userManagement.getUsers()
      this.users = users
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  /**
   * Get authorized users from users collection.
   * Filter out user emails not in editor collection.
   */
  @action
  getAuthorizedUsers = async () => {
    try {
      const [allUsers, authorizedEditors] = await Promise.all([
        userManagement.getUsers(),
        userManagement.getEditors()
      ])
      const authorizedUids = authorizedEditors.map((user) => user.email)
      this.authorizedUsers = allUsers.filter(
        (user) => authorizedUids.includes(user.email) && !!user.email
      )
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  getEditors = async () => {
    try {
      const editors = await userManagement.getEditors()
      this.editors = editors.filter((editor) => !editor.isAdmin)
      this.admins = editors.filter((editor) => editor.isAdmin)
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  getEditorRequests = async () => {
    try {
      const editorRequests = await userManagement.getEditorRequests()
      this.editorRequests = editorRequests
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  addEditor = async (isEditorRequest: boolean, editor: UserDocument) => {
    try {
      await userManagement.addEditor(isEditorRequest, editor)
      if (isEditorRequest) {
        this.editorRequests = this.editorRequests.filter(({ email }) => email !== editor.email)
      }
      this.editors = [...this.editors, editor]
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.setIsLoading(false)
  }

  @action
  addAdmin = async (adminData: UserDocument) => {
    try {
      await userManagement.addAdmin(adminData)
      this.editors = this.editors.filter(({ email }) => email !== adminData.email)
      this.admins = [...this.admins, adminData]
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.setIsLoading(false)
  }

  @action
  addEditorRequest = async () => {
    if (!this.rootStore.userStore.user) {
      this.rootStore.uiStore.handleError()
      return
    }
    const { email, uid } = this.rootStore.userStore.user
    const editorRequest: UserDocument = {
      uid,
      email: email || uid,
      name: this.rootStore.userStore.name,
      timestamp: createTimestamp()
    }
    try {
      const hasRequest = await userManagement.checkEditorRequest(editorRequest.email)
      if (hasRequest) {
        this.rootStore.uiStore.displaySuccess('Pyyntö jo olemassa')
        return
      }
      await userManagement.addEditorRequest(editorRequest)
      this.rootStore.uiStore.displaySuccess('Pyyntö tallennettu')
      this.editorRequests = [...this.editorRequests, editorRequest]
      this.editors = [...this.editors, editorRequest]
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  removeEditorRequest = async (editorRequest: UserDocument) => {
    try {
      await userManagement.removeEditorRequest(editorRequest.email)
      this.editorRequests = this.editorRequests.filter(({ email }) => email !== editorRequest.email)
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.setIsLoading(false)
  }

  @action
  removeEditor = async (editor: UserDocument) => {
    try {
      await userManagement.removeEditor(editor.email)
      this.editors = this.editors.filter(({ email }) => email !== editor.email)
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.setIsLoading(false)
  }

  @action
  removeAdmin = async (admin: UserDocument) => {
    try {
      await userManagement.removeAdmin(admin.email)
      this.admins = this.admins.filter(({ email }) => email !== admin.email)
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.setIsLoading(false)
  }

  @action
  setUserLoginState = async (uid: string, email: string, name: string) => {
    try {
      const userData: UserDocument = { uid, email, name, timestamp: createTimestamp() }
      await userManagement.setUserLoginState(userData)
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }
}
