import React, { FC } from 'react'

import { SeasonContract } from 'types/Supplier'
import { sortSeasonContracts } from 'utils/sorting'

interface Props {
  seasonContracts: SeasonContract[]
}
const ContractTooltip: FC<Props> = ({ seasonContracts }) => {
  const contracts = sortSeasonContracts(seasonContracts)

  const bindingTypes = contracts.map((contract) => contract.bindingType)
  const distinctBindingTypes = bindingTypes
    .flatMap((bindingType) => bindingType.split('; '))
    .filter((x, i, a) => a.indexOf(x) === i)
    .join('; ')

  const binding = contracts
    .filter((c) => c.active === 1 && c.binding === 1)
    .map((c) => c.acquisition)
    .join('; ')

  const nonBinding = contracts
    .filter((c) => c.active === 1 && c.binding === 0)
    .map((c) => c.acquisition)
    .join('; ')
  return (
    <>
      {binding && (
        <>
          <b>Sitova sopimus: </b>
          {binding}
          {nonBinding && <br />}
        </>
      )}
      {nonBinding && (
        <>
          <b>Kausitarjous: </b>
          {nonBinding}
        </>
      )}
      {binding && (
        <>
          <br />
          <br />
          <b>Koskee linjoja: </b>
          {distinctBindingTypes}
        </>
      )}
    </>
  )
}

export default ContractTooltip
