import { ThemeOptions, createTheme } from '@material-ui/core/styles'

const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920
    }
  },
  typography: {
    fontFamily: 'GT America, sans-serif',
    color: '#2C2A29',
    subtitle1: {
      fontFamily: 'Roobert, serif',
      fontWeight: 300,
      fontSize: '20px',
      lineHeight: '24px'
    },
    subtitle2: {
      fontFamily: 'Roobert, serif',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '24px'
    },
    h3: {
      fontFamily: 'Roobert, serif',
      fontWeight: 400,
      fontSize: '34px'
    },
    h5: {
      fontFamily: 'Roobert, serif',
      color: '#2C2A29',
      fontWeight: 300,
      fontSize: '20px',
      lineHeight: '24px'
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        height: '88px'
      }
    },
    MuiDrawer: {
      paper: {
        maxWidth: '100%'
      }
    }
  },
  shape: {
    borderRadius: 0
  },
  palette: {
    text: {
      primary: '#2C2A29',
      secondary: '#888b8d'
    },
    primary: { main: '#002E33' },
    secondary: { main: '#2C2A29' }
  },
  props: {
    MuiButton: {
      disableElevation: true
    }
  }
}

export type Theme = typeof theme

export default createTheme(theme as ThemeOptions)
