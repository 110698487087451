import { History } from 'history'
interface Routes {
  root: string
  suppliers: string
  supplier: string
  suppliersNew: string
  login: string
  feedback: string
  deliveryChallenge: string
  userManagement: string
  classification: string
}

export const routes: Routes = {
  root: '/',
  suppliers: '/toimittajat',
  supplier: '/toimittajat/:businessId?/:path?',
  suppliersNew: '/toimittajat/uudet',
  login: '/login',
  feedback: '/palaute',
  deliveryChallenge: '/toimitushaaste',
  userManagement: '/hallinnoi',
  classification: '/luokat'
}

export const redirectToSupplier = (businessId: string, history: History<History.UnknownFacade>) => {
  history.push(`${routes.suppliers}/${businessId}`)
}

export const redirectToFeedback = (businessId: string, history: History<History.UnknownFacade>) => {
  history.push(`${routes.suppliers}/${businessId}/palaute`)
}

export const redirectToDeliveryChallenge = (
  businessId: string,
  history: History<History.UnknownFacade>
) => {
  history.push(`${routes.suppliers}/${businessId}/toimitushaaste`)
}

export const redirectToSuppliers = (history: History<History.UnknownFacade>) => {
  history.push(routes.suppliers)
}
