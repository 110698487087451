import { Box, Drawer, Theme, useMediaQuery } from '@material-ui/core'
import React, { FC, RefObject, useContext, useEffect, useRef } from 'react'
import { dataContext, rootContext, uiContext, userContext, userManagementContext } from 'stores'
import { redirectToSupplier, redirectToSuppliers } from 'routes'
import { useHistory, useParams } from 'react-router-dom'

import AddSupplier from './edit/AddSupplier'
import DrawerHeader from './DrawerHeader'
import EditSupplier from './edit/EditSupplier'
import { Supplier } from 'types/Supplier'
import { SupplierRouteParams } from 'components/table/Suppliers'
import ViewSupplier from './view/ViewSupplier'
import { observer } from 'mobx-react-lite'

interface Props {
  supplier: Supplier | undefined
  isNewSuppliers?: boolean
}

const SupplierDrawer: FC<Props> = observer(({ supplier, isNewSuppliers }) => {
  const history = useHistory()
  const params = useParams<SupplierRouteParams>()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const dataStore = useContext(dataContext)
  const uiStore = useContext(uiContext)
  const userStore = useContext(userContext)
  const rootStore = useContext(rootContext)
  const userManagementStore = useContext(userManagementContext)
  const scrollComponentChildRef = useRef<HTMLDivElement>(null)
  const closeDrawer = () => {
    uiStore.removeSupplierSelection()

    if (!isNewSuppliers) {
      redirectToSuppliers(history)
    }
  }

  // space in pixels the Drawer header and navbar takes
  const HEADER_OFFSET = isMobile ? 120 : 104

  useEffect(() => {
    // disable url redirect for new suppliers
    if (isNewSuppliers) {
      return
    }

    // append businessId to url if it's missing
    if (!params.businessId && supplier?.businessId) {
      redirectToSupplier(supplier.businessId, history)
    }

    /**
     * - on initial load:
     * - display supplier with businessId from url if it exists in suppliers array
     */
    if (params.businessId && !supplier && userStore.hasEntryUrl && rootStore.isInitialized) {
      // set hasEntryUrl to false to trigger only once
      userStore.setHasEntryUrl(false)
      const supplierFromUrl = dataStore.suppliers.find((s) => s.businessId === params.businessId)
      if (supplierFromUrl) {
        uiStore.setSelectedSupplier(supplierFromUrl)
        return
      }
      redirectToSuppliers(history)
    } else if (rootStore.isInitialized) {
      userStore.setHasEntryUrl(false)
    }
  }, [
    isNewSuppliers,
    supplier,
    history,
    params,
    rootStore.isInitialized,
    dataStore.suppliers,
    uiStore,
    userStore,
    userStore.hasEntryUrl
  ])

  /**
   * Scrolling happens in the most inner child of the material-ui Drawer component.
   * This child is not accessable through Drawer, so that's why there is an empty div component with reference.
   * We can access the wanted component by accessing the parent of that div.
   */
  const handleScroll = (elementToScroll: RefObject<HTMLDivElement>) => {
    if (
      scrollComponentChildRef.current &&
      scrollComponentChildRef.current.parentElement &&
      elementToScroll.current
    ) {
      scrollComponentChildRef.current.parentElement.scrollTo({
        top: elementToScroll.current.offsetTop - HEADER_OFFSET,
        behavior: 'smooth'
      })
    }
  }

  const renderContent = () => {
    if (!supplier) {
      return
    }
    if (uiStore.displayAddSupplier) {
      return <AddSupplier allSuppliers={dataStore.suppliersAll} />
    }
    if (uiStore.displayAddSupplier) {
      return <AddSupplier allSuppliers={dataStore.suppliersAll} />
    }
    if (uiStore.editSupplier === supplier.businessId) {
      return (
        <EditSupplier
          supplier={supplier}
          isNewSupplier={uiStore.isNewSupplier}
          isNewSupplierEdit={uiStore.isNewSupplierEdit}
          isSupplierAction={uiStore.isSupplierAction}
          closeDrawer={closeDrawer}
          authorizedUsers={userManagementStore.authorizedUsers}
        />
      )
    }
    return (
      <ViewSupplier
        supplier={supplier}
        isNewSuppliers={isNewSuppliers}
        closeDrawer={closeDrawer}
        isViewer={userStore.isViewer}
        handleScroll={handleScroll}
      />
    )
  }

  return (
    <Drawer
      open={!!supplier}
      anchor="right"
      onClose={closeDrawer}
      BackdropProps={{ invisible: true }}
      ModalProps={{ disableScrollLock: !isMobile }}
      elevation={5}
    >
      <div ref={scrollComponentChildRef}>
        <Box width={424} maxWidth="100%">
          {supplier && (
            <>
              <DrawerHeader
                supplier={supplier}
                closeDrawer={closeDrawer}
                displayAddSupplier={uiStore.displayAddSupplier}
              />
              {renderContent()}
            </>
          )}
        </Box>
      </div>
    </Drawer>
  )
})

export default SupplierDrawer
