import { Action, DrawerTable, Input } from 'components/common/Styled'
import { Control, Controller, DeepMap, FieldError, useFieldArray } from 'react-hook-form'
import React, { FC, useEffect, useState } from 'react'

import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import DetailRow from '../shared/DetailRow'
import { ReactComponent as OvalPlusIcon } from 'assets/svg/oval-plus.svg'
import { SupplierContact } from 'types/Supplier'
import { SupplierForm } from 'api/db'
import { TableBody } from '@material-ui/core'
import styled from 'styled-components'

interface Props {
  control: Control<SupplierForm>
  setIsDirtyContacts: (dirtyContact: boolean) => void
  getValues: (val: string) => unknown
  errors: DeepMap<SupplierForm, FieldError>
}

const Cell = styled.td`
  padding: 0px;
  font-family: GT America, sans-serif;
  font-weight: 600;
  &.gray {
    color: #888b8d;
  }
`

const EditContacts: FC<Props> = ({ control, setIsDirtyContacts, errors, getValues }) => {
  const [initialLength, setInitialLength] = useState(0)
  const { fields, append, remove } = useFieldArray<SupplierContact>({ control, name: 'contacts' })

  useEffect(() => {
    setInitialLength(fields.length)
    // eslint-disable-next-line
  }, [])

  // use setIsDirtyContacts to handle removal of item (does not appear in dirtyFields)
  useEffect(() => {
    setIsDirtyContacts(initialLength > fields.length)
    // eslint-disable-next-line
  }, [fields.length])

  // focus lastName error on error
  useEffect(() => {
    const errorRef = errors.contacts?.find((c) => c?.lastName)?.lastName?.ref
    if (errorRef) {
      const errorElem = document.getElementsByName(errorRef.name)[0]
      if (errorElem) {
        errorElem.scrollIntoView({ behavior: `smooth`, block: 'center' })
      }
    }
  }, [errors.contacts])

  return (
    <DrawerTable>
      <TableBody>
        <DetailRow title="Toimittajan yhteyshenkilö(t)" content="" grayTitle />
        {fields.map((item, index) => (
          <React.Fragment key={item.id}>
            <tr>
              <Cell className="gray">{index >= initialLength ? 'Uusi yhteyshenkilö' : ''}</Cell>
              <Cell>
                <Action className="small" onClick={() => remove(index)}>
                  <ClearIcon />
                </Action>
              </Cell>
            </tr>
            <DetailRow
              title="Etunimi"
              content={
                <Controller
                  as={<Input />}
                  name={`contacts[${index}].firstName`}
                  control={control}
                  defaultValue={item.firstName || ''}
                />
              }
            />
            <DetailRow
              title="Sukunimi"
              content={
                <Controller
                  as={
                    <Input
                      error={errors?.contacts?.[index]?.lastName}
                      helperText={
                        errors?.contacts?.[index]?.lastName ? 'Sukunimi on pakollinen' : ''
                      }
                    />
                  }
                  name={`contacts[${index}].lastName`}
                  control={control}
                  defaultValue={item.lastName || ''}
                  rules={{
                    validate: (value) => {
                      if (getValues(`contacts[${index}].firstName`)) {
                        return !!value
                      }
                      return true
                    },
                    minLength: 1,
                    maxLength: 99
                  }}
                />
              }
            />
            <DetailRow
              title="Rooli"
              content={
                <Controller
                  as={<Input />}
                  name={`contacts[${index}].role`}
                  control={control}
                  defaultValue={item.role || ''}
                />
              }
            />
            <DetailRow
              title="Sähköposti"
              content={
                <Controller
                  as={<Input />}
                  name={`contacts[${index}].email`}
                  control={control}
                  defaultValue={item.email || ''}
                />
              }
            />
            <DetailRow
              title="Puhelinnumero"
              content={
                <Controller
                  as={<Input />}
                  name={`contacts[${index}].phone`}
                  control={control}
                  defaultValue={item.phone || ''}
                />
              }
            />
          </React.Fragment>
        ))}
        <DetailRow
          title=""
          content={
            <Action
              onClick={() =>
                append({ firstName: '', lastName: '', role: '', email: '', phone: '' })
              }
            >
              <OvalPlusIcon />
            </Action>
          }
        />
      </TableBody>
    </DrawerTable>
  )
}

export default EditContacts
