import { ConvertUpperCaseCss, NotAvailable } from 'components/common/Styled'
import {
  Liability,
  MainSegment,
  SeasonContract,
  Supplier,
  creditRatingCategory
} from 'types/Supplier'
import { Paper, TableCell } from '@material-ui/core'
import React, { FC, useContext } from 'react'
import { currency, isBankruptcyRisk, isRecentlyAdded, isSeasonContractInSegment } from 'utils/utils'

import ClassificationDisplay from 'components/classification/ClassificationDisplay'
import ContractTooltip from './ContractTooltip'
import CustomTooltip from 'components/common/CustomTooltip'
import DisplayFeedback from 'components/feedback/DisplayFeedback'
import NewSupplierAction from 'components/common/NewSupplierAction'
import OvalCross from 'assets/svg/oval-cross.svg'
import { ReactComponent as PinIcon } from 'assets/svg/pin.svg'
import { TableColumns } from 'stores/UIStore'
import { getCategoryValue } from 'types/SupplierCategory'
import { newSupplierColumns } from './SupplierTableHead'
import styled from 'styled-components'
import { uiContext } from 'stores'

interface Props {
  supplier: Supplier
  columns: TableColumns
  isNewSuppliers?: boolean
  selectedLinja?: MainSegment[]
}

const Cell = styled(TableCell)`
  height: 72px;
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid #f5f2ed;
  border-width: 1px 0px 1px 0px;
  border-radius: 4px;
  text-transform: capitalize;
  overflow-wrap: break-word;
  &:first-child {
    border-width: 1px 0px 1px 1px;
  }
  &:last-child {
    border-width: 1px 1px 1px 0px;
  }
  &.green {
    color: green;
  }
  &.orange {
    color: orange;
  }
  &.red {
    color: red;
  }
  &.clarify {
    color: orange;
  }
  &.title {
    position: relative;
  }
  ${ConvertUpperCaseCss}
`

const HighlightLabel = styled.div`
  text-transform: lowercase;
  width: 32px;
  height: 12px;
  background: #2c2a29;
  border-radius: 16px;
  text-align: center;
  vertical-align: middle;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.3px;

  color: #ffffff;

  position: absolute;
  right: -2px;
  top: 8px;

  &.small {
    top: 4px;
  }

  &.newSupplier {
    background: #01ff95;
    color: #191919;
  }

  &.bankruptcy {
    width: 58px;
    background: #cf1627;
  }

  &.deliveryChallenge {
    width: 100px;
    background: #ffb903;
    color: #191919;
  }
`

const StyledRow = styled(Paper)`
  box-shadow: 2px 2px 10px rgba(218, 214, 206, 0.3);
  &:hover {
    cursor: pointer;
  }
  &.not-recommended {
    background: #fbfaf8;
    & > td:first-child {
      color: red;
      padding-right: 0;
      padding-left: 40px;
      min-height: 72px;
      height: 100%;
      position: relative;
    }
    & > td:first-child:before {
      content: url(${OvalCross});
      position: absolute;
      top: 38%;
      margin-left: -24px;
    }
  }
`

const StyledPin = styled(PinIcon)`
  margin-left: 3px;
`

enum Color {
  Green = 'green',
  Orange = 'orange',
  Red = 'red'
}

export const getRatingColor = (rating?: creditRatingCategory): Color => {
  switch (rating) {
    case 'AAA':
    case 'AA':
    case 'A':
      return Color.Green
    case 'AN':
    case 'EIRA':
      return Color.Orange
    case 'B':
    case 'C':
      return Color.Red
    default:
      return Color.Orange
  }
}

const getLiabilityColor = (liability?: Liability): Color => {
  switch (liability) {
    case 'Ok':
      return Color.Green
    case 'Selvitä':
    case 'Tietoja odotetaan':
    case 'Huomioi':
      return Color.Orange
    case 'Seis':
      return Color.Red
    default:
      return Color.Orange
  }
}

export const displaySupplierContract = (seasonContracts?: SeasonContract[], isExcel?: boolean) => {
  if (!seasonContracts?.length) {
    return isExcel ? '' : <NotAvailable />
  }
  if (seasonContracts.every((contract) => contract.active === 0)) {
    return isExcel ? '' : <NotAvailable />
  }
  return seasonContracts.some((contract) => contract.binding) ? 'Sitova' : 'Tarjous'
}

const SupplierTableRow: FC<Props> = (props) => {
  const { supplier, isNewSuppliers, selectedLinja } = props
  const columns = isNewSuppliers ? newSupplierColumns : props.columns

  const uiStore = useContext(uiContext)

  const handleClick = () => {
    uiStore.setSelectedSupplier(supplier)
  }

  return (
    <StyledRow
      key={supplier.supplier}
      component="tr"
      variant="outlined"
      onClick={handleClick}
      className={
        supplier.supplierCategory === 'denied' || supplier.supplierCategory === 'prohibited'
          ? 'not-recommended'
          : ''
      }
    >
      {columns.supplier && (
        <Cell className="title">
          {supplier.supplier}
          {isBankruptcyRisk(supplier.defaultCode) ? (
            <HighlightLabel
              className={
                supplier.supplier && supplier.supplier.length > 16
                  ? `small bankruptcy`
                  : `bankruptcy`
              }
            >
              konkurssi
            </HighlightLabel>
          ) : (
            <>
              {supplier.issues && supplier.issues.length > 0 ? (
                <HighlightLabel className={`deliveryChallenge`}>toimitusvaikeuksia</HighlightLabel>
              ) : (
                isRecentlyAdded(supplier.addedAt) && (
                  <HighlightLabel className={`newSupplier`}>uusi</HighlightLabel>
                )
              )}
            </>
          )}
        </Cell>
      )}
      {columns.supplierCategory && (
        <Cell className={supplier.supplierCategory}>
          {getCategoryValue(supplier.supplierCategory)}
        </Cell>
      )}
      {columns.creditRating && (
        <Cell className={getRatingColor(supplier.creditRating)}>{supplier.creditRating}</Cell>
      )}
      {columns.liability && (
        <Cell className={getLiabilityColor(supplier.liability)}>
          {supplier.liability || <NotAvailable />}
        </Cell>
      )}
      {columns.businessId && <Cell>{supplier.businessId}</Cell>}
      {columns.seasonContracts && (
        <Cell>
          <CustomTooltip
            disableHover={false}
            title={
              supplier.seasonContracts?.length &&
              supplier.seasonContracts.find((c) => c.active === 1) ? (
                <ContractTooltip seasonContracts={supplier.seasonContracts} />
              ) : (
                ''
              )
            }
          >
            <div>
              {displaySupplierContract(supplier.seasonContracts)}
              {isSeasonContractInSegment(selectedLinja, supplier.seasonContracts) && <StyledPin />}
            </div>
          </CustomTooltip>
        </Cell>
      )}
      {columns.runningSpend && (
        <Cell>
          {supplier.runningSpend == null ? <NotAvailable /> : currency(supplier.runningSpend)}
        </Cell>
      )}
      {columns.fira2020 && (
        <Cell>
          <ClassificationDisplay fira2020={supplier.fira2020} isHorizontal />
        </Cell>
      )}
      {columns.feedback && (
        <Cell>
          <DisplayFeedback supplier={supplier} tableView />
        </Cell>
      )}
      {isNewSuppliers && <Cell className="convertUpperCase">{supplier.source}</Cell>}
      {isNewSuppliers && <NewSupplierAction supplier={supplier} />}
    </StyledRow>
  )
}

export default SupplierTableRow
