import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import React, { FC, useState } from 'react'

import { Action } from 'components/common/Styled'
import { ReactComponent as CheckmarkIcon } from 'assets/svg/checkmark-green.svg'
import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import { ReactComponent as OvalPlusDarkIcon } from 'assets/svg/oval-plus-dark.svg'
import styled from 'styled-components'
import { SupplierCategory } from 'types/Classification'

interface Props {
  handleSubmit: (value: SupplierCategory) => void
}

const Input = styled(TextField)`
  width: 290px;
  margin-right: 10px;
  margin-bottom: 4px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 2px;
  background: white;
`
const StyledButton = styled(Button)`
  text-transform: none;
`

const ClassificationAdd: FC<Props> = ({ handleSubmit }) => {
  const [newItem, setNewItem] = useState(false)
  const [text, setText] = useState('')
  const submit = () => {
    if (text && text.length > 0) {
      handleSubmit({
        names: [text],
        salesforceCategoryId: ''
      })
      setNewItem(false)
      setText('')
    }
  }

  return (
    <>
      <Box mt={3} />
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Action onClick={() => setNewItem(true)} className="margin-negative">
          <OvalPlusDarkIcon />
        </Action>
        {newItem ? (
          <>
            <Box ml={1} />
            <Input variant="outlined" value={text} onChange={(e) => setText(e.target.value)} />
            <StyledButton startIcon={<CheckmarkIcon />} onClick={submit}>
              Tallenna
            </StyledButton>
            <StyledButton startIcon={<ClearIcon />} onClick={() => setNewItem(false)}>
              Hylkää
            </StyledButton>
          </>
        ) : (
          <Box ml="9px">
            <Typography>Lisää uusi luokka</Typography>
          </Box>
        )}
      </Grid>
    </>
  )
}

export default ClassificationAdd
