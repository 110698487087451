import { Box, Grid, Link, TextField, Typography } from '@material-ui/core'
import { PrimaryButton, SecondaryButton } from 'components/common/Styled'
import React, { ChangeEvent, FC, MouseEvent, useContext, useState } from 'react'
import { classificationContext, dbContext } from 'stores'
import { Fira2020 } from 'types/Classification'
import { IS_DEV_FEATURE } from 'utils/featureToggle'
import { StyledThumb } from './FeedbackButton'
import { Supplier } from 'types/Supplier'
import { ReactComponent as ThumbsDownIcon } from 'assets/svg/thumbs-down.svg'
import { ReactComponent as ThumbsUpIcon } from 'assets/svg/thumbs-up.svg'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { getFira2020DisplayValues } from 'components/classification/ClassificationDisplay'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

interface Props {
  supplier: Supplier
  closeFeedback: (drawer?: boolean) => void
}

const StyledTextfield = styled(TextField)`
  padding: 0;
  width: 100%;
`

const ContentContainer = styled(Grid)`
  padding: 24px;
  @media all and (min-width: 550px) {
    width: 473px;
  }
`

const FullContainer = styled(Grid)`
  width: 100%;
`

const createFeedbackUrl = (supplier: Supplier, fira2020: Fira2020) => {
  const name = supplier.supplier || ''
  const businessId = supplier.businessId
  if (!IS_DEV_FEATURE) {
    let url = `https://docs.google.com/forms/d/e/1FAIpQLSd3sWlPFr340oP_lR81VOe2u-RgCWgu232HX7agw10xtNW8Xw/viewform?usp=pp_url`
    url += `&entry.1905952613=${businessId}`
    url += `&entry.16819879=${name}`
    const classifications = getFira2020DisplayValues(supplier.fira2020, fira2020)
    if (classifications.length > 0) {
      url += `&entry.179656196=-+`
      url += classifications.join('%0A-+')
    }
    return url
  }

  let url = `https://docs.google.com/forms/d/e/1FAIpQLSfrb9Obzb15axb_Fn6vosHOzC9B1GsIgAua-0Y2rJN08tEuWQ/viewform?usp=pp_url`
  url += `&entry.1905952613=${businessId}`
  url += `&entry.16819879=${name}`
  const classifications = getFira2020DisplayValues(supplier.fira2020, fira2020)
  if (classifications.length > 0) {
    url += `&entry.1194666145=-+`
    url += classifications.join('%0A-+')
  }
  return url
}

const Feedback: FC<Props> = observer(({ supplier, closeFeedback }) => {
  const dbStore = useContext(dbContext)
  const classificationStore = useContext(classificationContext)
  const [comment, setComment] = useState('')
  const [rating, setRating] = useState<number | null>(null)

  const handleRating = (e_: MouseEvent<HTMLElement>, newRating: number | null) => {
    setRating(newRating)
  }

  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value)
  }

  const handleSubmit = () => {
    if (rating !== null) {
      dbStore.addFeedback(supplier, rating, comment)
      closeFeedback(true)
    }
  }

  const isSubmitEnabled: boolean = rating !== null

  return (
    <>
      <Box m={3}>
        <Typography component="h1" variant="body1" align="left">
          Auta muita urakoitsijavalinnassa ja kerro,
          <br />
          suosittelisitko toimittajaa.
        </Typography>
        <Box mt={0.5} />
        <Typography component="h1" variant="body1" align="left" color="primary">
          <Link
            href={createFeedbackUrl(supplier, classificationStore.fira2020)}
            color="inherit"
            rel="noopener noreferrer"
            target="_blank"
          >
            <u>Anna laajempi toimittaja-arvio</u>
          </Link>
        </Typography>
      </Box>
      <ContentContainer container direction="column" alignItems="center">
        <FullContainer container direction="column" alignItems="center">
          <ToggleButtonGroup value={rating} exclusive onChange={handleRating} size="large">
            <StyledThumb value={1} className={`up ${rating === 1 && 'selected'}`}>
              <ThumbsUpIcon />
            </StyledThumb>
            <StyledThumb value={0} className={`down ${rating === 0 && 'selected'}`}>
              <ThumbsDownIcon />
            </StyledThumb>
          </ToggleButtonGroup>
          <Box mb={5} component="span" />
          <StyledTextfield
            placeholder="Kirjoita sanallinen palaute"
            multiline
            minRows={4}
            variant="outlined"
            onChange={handleCommentChange}
          />
        </FullContainer>
        <Box mb={5} />
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <SecondaryButton variant="contained" onClick={() => closeFeedback()}>
            Peruuta
          </SecondaryButton>
          <Box ml={3} />
          <PrimaryButton variant="contained" onClick={handleSubmit} disabled={!isSubmitEnabled}>
            Anna palautetta
          </PrimaryButton>
        </Grid>
      </ContentContainer>
    </>
  )
})

export default Feedback
