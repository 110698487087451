import {
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Table,
  TextField,
  Typography
} from '@material-ui/core'
import styled, { css } from 'styled-components'

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg'
import PageBackground from 'assets/svg/PageBackground.svg'
import React from 'react'

export const BackgroundSVG = styled.div`
  background-color: #47006d;
  background: url(${PageBackground}) repeat center center;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  z-index: -1;
  height: 135vh;
  width: 100%;
  margin: 0px;
`

export const DrawerContentContainer = styled(Box)`
  margin: 104px 16px 50px 24px;

  @media all and (max-width: 404px) {
    margin: 120px 0 0 16px;
  }
`

export const Timestamp = styled(Typography)`
  font-size: 11px;
  color: #888b8d;
  margin-left: 3px;
  width: 100%;
`

export const PrimaryButton = styled(Button)`
  text-transform: none;
  border-radius: 20px;
  min-width: 104px;
  background: #2c2a29;
  color: #ffffff;
  &:hover {
    background: #62615f;
  }
  &:disabled {
    background: #c4c4c4;
  }
`
export const SecondaryButton = styled(Button)`
  text-transform: none;
  border-radius: 20px;
  min-width: 104px;
  background: #f5f3ef;
  border: 1px solid #e8e6e1;
  box-sizing: border-box;
  &:hover {
    background: #f8f7f4;
  }
  &:disabled {
    background: #f5f3ef;
  }
  &:disabled > label {
    color: #c3c2be;
  }
`

export const DrawerButton = styled(Button)`
  text-transform: none;
  border-radius: 20px;
  width: 120px;
  background: #f5f3ef;
  color: #2c2a29;
`

export const ArrowDownIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
  margin-top: 9px;
`

export const StyledSelectCss = css`
  && .MuiOutlinedInput-input {
    padding: 0px 5px;
    font-size: 14px;
    line-height: 35px;
    height: 32px;
  }

  margin-bottom: 2px;
  width: 100%;

  &.extra-margin {
    margin-left: 24px;
    margin-top: 6px;
  }
`

export const NotAvailable = styled.div`
  font-family: GT America, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 118.1%;
  color: #c8c9c7;
  &:before {
    content: 'Ei Saatavilla';
  }
`

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 28px;
`

export const DrawerTable = styled(Table)`
  width: 380px;
  tbody > tr > td {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }

  @media all and (max-width: 404px) {
    width: 100%;
  }
`

export const DrawerDivider = styled(Divider)`
  margin: 20px -16px 20px -24px;
`

export const DrawerInnerDivider = styled(Divider)`
  margin: 20px 0px;
`

const InputStyles = css`
  && .MuiOutlinedInput-input {
    padding: 0px 5px;
    font-size: 14px;
    line-height: 15px;
    height: 32px;
  }
  width: 100%;
`

export const ConvertUpperCaseCss = css`
  &.convertUpperCase {
    text-transform: lowercase;
  }
  &.convertUpperCase::first-letter {
    text-transform: uppercase;
  }
`

export const Input = styled((props) => <TextField {...props} variant="outlined" />)`
  ${InputStyles}
`

export const Action = styled(IconButton)`
  float: right;
  padding: 0;

  &.margin-negative {
    margin-left: -6px;
  }
  &.small {
    margin-right: 16px;
  }
  &.small-height {
    height: 20px;
    margin-bottom: 10px;
  }
  &.margin-large {
    margin-left: 250px;
    margin-bottom: 24px;
    @media all and (max-width: 600px) {
      margin-left: auto;
    }
  }
`

export const Loading = styled(LinearProgress)`
  @media all and (min-width: 600px) {
    width: 300px;
    &.small {
      width: 270px;
    }
    &.x-small {
      width: 235px;
    }
  }
`

export const LinkText = styled.u`
  font-weight: normal;
  &:hover {
    font-weight: 600;
  }
`
