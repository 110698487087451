import React, { FC } from 'react'

import { Tooltip } from '@material-ui/core'
import styled from 'styled-components'

interface Props {
  title: string | JSX.Element
  disableHover: boolean
  isSmall?: boolean
}

const Content = styled.div`
  overflow-y: auto;
  max-height: 118px;
  width: 185px;
  &&.small {
    width: 142px;
  }
`
const StyledTooltip = styled((props) => (
  <Tooltip
    {...props}
    classes={{ popper: props.className, tooltip: 'tooltip', arrow: 'arrow' }}
    arrow
  />
))`
  & .tooltip {
    margin: 15px;
    max-height: 124px;
    font-size: 14px;
    color: #2c2a29;
    background-color: white;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    word-break: break-word;
    font-weight: 400;
  }
  & .arrow {
    font-size: 20px;
    left: 55px !important;
    &:before {
      background-color: white;
      border: 1px solid #dbdbdb;
    }
  }
`

const CustomTooltip: FC<Props> = ({ disableHover, title, children, isSmall }) => {
  return (
    <StyledTooltip
      interactive
      disableHoverListener={disableHover}
      disableFocusListener
      title={<Content className={isSmall ? 'small' : ''}>{title}</Content>}
    >
      {children}
    </StyledTooltip>
  )
}

export default CustomTooltip
