import axios, { AxiosResponse } from 'axios'

import { Supplier } from 'types/Supplier'
import { storage } from 'firebaseConfig'

/**
 * download json with getDownloadURL
 * see `storage.rules` for auth rules
 */
const downloadJson = async () => {
  const url: string = await storage.ref('suppliers.json').getDownloadURL()
  const { data }: AxiosResponse<Supplier[]> = await axios.get(url)
  return data
}

export const getSuppliersJson = async () => {
  return downloadJson()
}
