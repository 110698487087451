import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import React, { FC, useContext, useState } from 'react'
import { dataContext, uiContext, userContext, userManagementContext } from 'stores'
import { displayContacts, getEmploymentCategory, getRevenueCategory, isBoolean } from 'utils/utils'
import json2csv, { parse } from 'json2csv'

import { ReactComponent as AddSupplierIcon } from 'assets/svg/addSupplier.svg'
import ButtonContent from 'components/common/ButtonContent'
import { ReactComponent as ExportIcon } from 'assets/svg/export.svg'
import { ReactComponent as GearIcon } from 'assets/svg/gear.svg'
import { Supplier } from 'types/Supplier'
import { TableColumnKeys } from 'stores/UIStore'
import { customSort } from 'utils/sorting'
import { displaySupplierContract } from '../base/SupplierTableRow'
import { getCategoryValue } from 'types/SupplierCategory'
import { headCells } from '../base/SupplierTableHead'
import { observer } from 'mobx-react-lite'
import { saveAs } from 'file-saver'
import styled from 'styled-components'

interface Props {
  isMobile?: boolean
}

const Container = styled(Grid)`
  margin-right: 10px;
`
const StyledButton = styled(Button)`
  padding-left: 2px;

  &.margin-large {
    margin-right: 30px;
  }
`
const StyledCheckbox = styled(Checkbox)`
  margin-left: 16px;
`

const disabledTableCells: Array<keyof TableColumnKeys> = [
  'supplier',
  'supplierCategory',
  'creditRating',
  'liability'
]
const disabledMobileTableCells: Array<keyof TableColumnKeys> = ['supplier']

enum ExcelType {
  SUPPLIER,
  CONTACT,
  RESPONSIBLE_USER
}
interface Fields<T> {
  label: string
  value: string | ((supplier: T) => string | JSX.Element | string[])
}

interface ContactsExcel {
  businessId: string
  supplierCategory: string
  runningSpend: string
  firstName: string
  lastName: string
  role: string
  email: string
  phone: string
}
interface ResponsibleUserExcel {
  supplier: string
  runningSpend: string
  fira2020: string[]
  responsibleUser: string
  contacts: string
}

const fileSave = <T,>(fields: Fields<T>[], data: T[], fileName: string) => {
  const opts: json2csv.Options<T> = { fields, delimiter: ';' }
  // download as csv
  const csv = parse(data, opts)
  const BOM = '\uFEFF'
  const csvData = new Blob([BOM + csv], { type: 'text/csv;charset=utf-8;' })
  saveAs(csvData, fileName)
}

const saveExcelResponsibleUsers = (suppliers: Supplier[]) => {
  const fields: Fields<ResponsibleUserExcel>[] = []
  fields.push({ label: 'Toimittajan nimi', value: (c: ResponsibleUserExcel) => c.supplier })
  fields.push({
    label: 'Juoksevat ostot (12kk)',
    value: (c: ResponsibleUserExcel) => c.runningSpend
  })
  fields.push({ label: 'Luokat', value: (c: ResponsibleUserExcel) => c.fira2020 })
  fields.push({
    label: 'Firan vastuuhenkilö',
    value: (c: ResponsibleUserExcel) => c.responsibleUser
  })
  fields.push({
    label: 'Yhteystiedot (Sähköposti)',
    value: (c: ResponsibleUserExcel) => c.contacts
  })
  const data: ResponsibleUserExcel[] = []
  for (const supplier of suppliers) {
    const content: ResponsibleUserExcel = {
      supplier: supplier.supplier || '',
      runningSpend: supplier.runningSpend?.toString() || '',
      fira2020: supplier.fira2020AllValues || [],
      responsibleUser: supplier.responsibleUser || '',
      contacts: displayContacts(supplier.contacts, supplier.additionalInfo)
        .map((cont) => cont.email)
        .join(',')
    }
    data.push(content)
  }
  fileSave(fields, data, 'vastuuhenkilöt.csv')
}

const saveExcelContacts = (suppliers: Supplier[]) => {
  const fields: Fields<ContactsExcel>[] = []
  fields.push({ label: 'Y-tunnus', value: (c: ContactsExcel) => c.businessId })
  fields.push({ label: 'Toimittajaluokka', value: (c: ContactsExcel) => c.supplierCategory })
  fields.push({ label: 'Juoksevat ostot (12kk)', value: (c: ContactsExcel) => c.runningSpend })
  fields.push({ label: 'Yhteyhenkilön etunimi', value: (c: ContactsExcel) => c.firstName })
  fields.push({ label: 'Yhteyhenkilön sukunimi', value: (c: ContactsExcel) => c.lastName })
  fields.push({ label: 'Yhteyhenkilön rooli', value: (c: ContactsExcel) => c.role })
  fields.push({ label: 'Yhteyhenkilön sähköposti', value: (c: ContactsExcel) => c.email })
  fields.push({ label: 'Yhteyhenkilön puhelinnumero', value: (c: ContactsExcel) => c.phone })
  const data: ContactsExcel[] = []
  for (const supplier of suppliers) {
    const contacts = displayContacts(supplier.contacts, supplier.additionalInfo)
    contacts.forEach((contact) => {
      const content: ContactsExcel = {
        businessId: supplier.businessId,
        supplierCategory: getCategoryValue(supplier.supplierCategory),
        runningSpend: supplier.runningSpend?.toString() || '',
        firstName: contact.firstName || '',
        lastName: contact.lastName || '',
        role: contact.role || '',
        email: contact.email || '',
        phone: contact.phone || ''
      }
      data.push(content)
    })
  }
  fileSave(fields, data, 'yhteystiedot.csv')
}

const saveExcel = (suppliers: Supplier[]) => {
  const fields: Fields<Supplier>[] = headCells
    .filter((hc) => hc.id !== 'feedback')
    .map((headCell) => {
      if (headCell.id === 'supplierCategory') {
        return {
          label: headCell.label,
          value: (supplier: Supplier) => getCategoryValue(supplier.supplierCategory)
        }
      }
      if (headCell.id === 'seasonContracts') {
        return {
          label: headCell.label,
          value: (supplier: Supplier) => displaySupplierContract(supplier.seasonContracts, true)
        }
      }
      if (headCell.id === 'fira2020') {
        return { label: headCell.label, value: 'fira2020AllValues' }
      }
      return { label: headCell.label, value: headCell.id }
    })
  fields.push({
    label: 'Yrityksen liikevaihto',
    value: (supplier: Supplier) => getRevenueCategory(supplier.revenue)
  })
  fields.push({
    label: 'Yrityksen koko',
    value: (supplier: Supplier) => getEmploymentCategory(supplier.employees)
  })
  fields.push({
    label: 'Yhteystiedot (Sähköposti)',
    value: (supplier: Supplier) => {
      const contacts = displayContacts(supplier.contacts, supplier.additionalInfo)
      const emails = contacts.map((cont) => cont.email).join(',')
      return emails
    }
  })
  fields.push({ label: 'Käyntiosoite', value: 'address' })
  fields.push({ label: 'Postiosoite', value: 'postalAddress' })
  fields.push({ label: 'Maakoodi', value: 'country' })

  fileSave(fields, suppliers, 'toimittajat.csv')
}

const SupplierTableEdit: FC<Props> = observer(({ isMobile }) => {
  const uiStore = useContext(uiContext)
  const userStore = useContext(userContext)
  const dataStore = useContext(dataContext)
  const userManagementStore = useContext(userManagementContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(null)

  const handleAddSupplier = () => {
    if (userStore.isViewer) {
      userManagementStore.addEditorRequest()
      return
    }
    uiStore.editNewSupplier()
  }

  const handleCloseExport = () => {
    setAnchorElExport(null)
  }

  const handleExportSuppliers = (type: ExcelType) => {
    // sort suppliers like in table
    const suppliers = dataStore.hasSearchEngineSorting
      ? dataStore.suppliers
      : customSort(dataStore.suppliers, dataStore.sortOrder)

    if (type === ExcelType.CONTACT) {
      saveExcelContacts(suppliers)
    }
    if (type === ExcelType.RESPONSIBLE_USER) {
      saveExcelResponsibleUsers(suppliers)
    }
    if (type === ExcelType.SUPPLIER) {
      saveExcel(suppliers)
    }
    handleCloseExport()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClickExport = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElExport(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    uiStore.applyEditedColumns()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    uiStore.setEditedColumns({ ...uiStore.editedColumns, [name]: checked })
  }

  return (
    <Container item>
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <StyledButton onClick={handleClickExport} className={'margin-large'}>
          <ButtonContent icon={<ExportIcon />} text={'Vie tiedot'} />
        </StyledButton>
        <Menu
          anchorEl={anchorElExport}
          keepMounted
          open={Boolean(anchorElExport)}
          onClose={handleCloseExport}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          getContentAnchorEl={null}
        >
          <MenuItem onClick={() => handleExportSuppliers(ExcelType.SUPPLIER)}>Toimittajat</MenuItem>
          <MenuItem onClick={() => handleExportSuppliers(ExcelType.CONTACT)}>Yhteystiedot</MenuItem>
          <MenuItem onClick={() => handleExportSuppliers(ExcelType.RESPONSIBLE_USER)}>
            Vastuuhenkilöt
          </MenuItem>
        </Menu>
        <StyledButton onClick={handleAddSupplier} className={'margin-large'}>
          <ButtonContent
            icon={<AddSupplierIcon />}
            text={userStore.isViewer ? 'Pyydä muokkausoikeuksia' : 'Lisää uusi toimittaja'}
          />
        </StyledButton>
        <StyledButton onClick={handleClick}>
          <ButtonContent icon={<GearIcon />} text="Muokkaa" />
        </StyledButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          getContentAnchorEl={null}
        >
          <FormControl component="fieldset">
            <FormGroup>
              {headCells.map(
                (headCell, index) =>
                  isBoolean(uiStore.selectedColumns[headCell.id]) && (
                    <div key={headCell.id}>
                      {index !== 0 && <Divider variant="middle" />}
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            size="small"
                            disabled={
                              isMobile
                                ? disabledMobileTableCells.includes(headCell.id)
                                : disabledTableCells.includes(headCell.id)
                            }
                            checked={uiStore.editedColumns[headCell.id]}
                            onChange={handleChange}
                            name={headCell.id}
                          />
                        }
                        label={<Typography variant="body2">{headCell.label}</Typography>}
                      />
                    </div>
                  )
              )}
            </FormGroup>
          </FormControl>
        </Menu>
      </Grid>
    </Container>
  )
})

export default SupplierTableEdit
