import React, { FC, useEffect, useState } from 'react'
import { SupplierIssue } from 'types/Supplier'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { formatIsoDate } from 'utils/utils'
import { SupplierForm } from 'api/db'
import styled from 'styled-components'
import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import { TableCell, TableRow, TextField, Typography } from '@material-ui/core'

interface Props {
  control: Control<SupplierForm>
  setIsDirtyIssues: (value: boolean) => void
}

const FieldContainer = styled.div`
  margin-bottom: 20px;
`
const Close = styled.div`
  margin-right: 16px;
  margin-bottom: -25px;
  float: right;
  cursor: pointer;
`
const HiddenTextField = styled(TextField)`
  display: none;
`
const Cell = styled(TableCell)`
  width: 100%;
  border-bottom: none;
  padding: 0;
`
const Title = styled(Typography)`
  font-weight: 500;
  display: inline;
  margin-bottom: 6px;
`
const Timestamp = styled(Typography)`
  font-size: 11px;
  color: #888b8d;
  display: inline;
  margin-left: 3px;
  margin-bottom: 6px;
`
const ContentRow = styled.div`
  width: 364px;
`

const EditDeliveryChallenges: FC<Props> = ({ control, setIsDirtyIssues }) => {
  const [initialLength, setInitialLength] = useState(0)
  const { fields, remove } = useFieldArray<SupplierIssue>({ control, name: 'issues' })

  useEffect(() => {
    setInitialLength(fields.length)
    // eslint-disable-next-line
  }, [])

  // use setIsDirtyIssues to handle removal of item (does not appear in dirtyFields)
  useEffect(() => {
    setIsDirtyIssues(initialLength > fields.length)
    // eslint-disable-next-line
  }, [fields.length])

  return (
    <>
      {fields.map((issue, index) => (
        <FieldContainer key={issue.id}>
          <Close onClick={() => remove(index)}>
            <ClearIcon />
          </Close>
          <TableRow>
            <Cell>
              <Title as="div">{issue.createdBy}</Title>
              <Timestamp as="div">{formatIsoDate(issue.timestamp)}</Timestamp>
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>
              <Title as="div">{issue.site}</Title>
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>
              <ContentRow>
                <Controller
                  as={<TextField variant="outlined" fullWidth multiline minRows={4} />}
                  name={`issues[${index}].details`}
                  control={control}
                  defaultValue={issue.details || ''}
                />
                {/* 
                    createdBy, timestamp and site fields are rendered as a hidden textfields.
                    This is because these properties needs to be also passed with the
                    changed comment text, but we don't want these to be edited manually.
                */}
                <Controller
                  as={<HiddenTextField />}
                  name={`issues[${index}].createdBy`}
                  control={control}
                  defaultValue={issue.createdBy || ''}
                />
                <Controller
                  as={<HiddenTextField />}
                  name={`issues[${index}].timestamp`}
                  control={control}
                  defaultValue={issue.timestamp || ''}
                />
                <Controller
                  as={<HiddenTextField />}
                  name={`issues[${index}].site`}
                  control={control}
                  defaultValue={issue.site || ''}
                />
              </ContentRow>
            </Cell>
          </TableRow>
        </FieldContainer>
      ))}
    </>
  )
}

export default EditDeliveryChallenges
