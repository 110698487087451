import React, { FC } from 'react'

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
}

/* prevent zoom effect on mobile */
@media screen and (max-width: 767px) {
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="password"] {
    font-size: 16px !important;
  }
}
`

const GlobalStyles: FC = () => {
  return <GlobalStyle />
}

export default GlobalStyles
