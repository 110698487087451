import { Feedback, SupplierIssue, Supplier, SupplierDocument } from 'types/Supplier'
import { action, observable, makeObservable } from 'mobx'
import { callGetLiabilityPdf, callGetSupplierLiability } from 'api/functions'
import { createSupplierInstance, createTimestamp } from 'utils/utils'
import db, { Changelog, SupplierForm } from 'api/db'

import { Country } from 'types/Country'
import RootStore from './RootStore'
import { SupplierCategory } from 'types/SupplierCategory'

/**
 * manage firestore, callable functions
 */
export default class DBStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable changelog: Changelog[] = []
  @observable feedbackSupplier: Supplier | undefined = undefined
  @observable liabilityPdf: string | null = null

  @observable actionCategory: SupplierCategory | undefined = undefined
  @observable actionNewSupplier: SupplierDocument | undefined = undefined
  @observable actionUpdatedSupplier: SupplierDocument | undefined = undefined

  /**
   * handle updating supplier when supplier is accepted or denied
   */
  handleUpdateSupplierAction = async (
    modifiedSupplier: SupplierDocument,
    initialSupplier?: SupplierDocument,
    newSupplier?: SupplierDocument
  ) => {
    const isNew = !!newSupplier
    const baseSupplier: SupplierDocument = isNew
      ? { ...newSupplier, businessId: modifiedSupplier.businessId }
      : { businessId: modifiedSupplier.businessId }

    const updatedSupplier = {
      ...baseSupplier,
      supplierCategory: this.actionCategory,
      addedAt: createTimestamp(),
      ...modifiedSupplier
    }
    // remove liability, liability data comes from the api.
    // It's only relevant while adding a supplier to new suppliers, so it can (and should) be removed here
    delete updatedSupplier.liability

    await db.updateAndDeleteNewSupplier(updatedSupplier, isNew)
    this.rootStore.dataStore.handleRemoveNewSupplier(updatedSupplier.businessId)
    const localSupplier: SupplierDocument = { ...initialSupplier, ...updatedSupplier }
    return localSupplier
  }

  getUserIdentifier = () => {
    return this.rootStore.userStore.identifier
  }

  @action
  updateSupplier = async (
    supplier: Supplier,
    supplierForm: SupplierForm,
    isNewSupplierEdit: boolean
  ) => {
    const modifiedBy = this.getUserIdentifier()
    const supplierDocument = db.createSupplierDocument(modifiedBy, supplier, supplierForm)
    const actionNewSupplier = this.actionNewSupplier
    const actionUpdatedSupplier = this.actionUpdatedSupplier

    try {
      if (this.rootStore.uiStore.isSupplierAction) {
        const updatedSupplier = await this.handleUpdateSupplierAction(
          supplierDocument,
          actionUpdatedSupplier,
          actionNewSupplier
        )
        this.rootStore.dataStore.handleUpdateSuppliers(updatedSupplier)
      } else if (isNewSupplierEdit) {
        await db.addNewSupplier(supplierDocument)
        this.rootStore.dataStore.handleAddNewSupplier(supplierDocument)
      } else {
        await db.updateSupplier(supplierDocument)
        this.rootStore.dataStore.handleUpdateSuppliers(supplierDocument)
      }
      this.rootStore.uiStore.displaySuccess()
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.rootStore.uiStore.removeSupplierSelection()
  }

  @action
  addNewSupplier = async (supplier: Supplier, supplierForm: SupplierForm) => {
    const modifiedBy = this.getUserIdentifier()
    const supplierDocument = db.createSupplierDocument(modifiedBy, supplier, supplierForm)
    supplierDocument.source = 'toimittajarekisteri'

    try {
      await db.addNewSupplier(supplierDocument)
      this.rootStore.dataStore.handleAddNewSupplier(supplierDocument)
      this.rootStore.uiStore.displaySuccess()
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.rootStore.uiStore.removeSupplierSelection()
  }

  @action
  handleNewSupplierAction = async (supplier: Supplier, supplierCategory: SupplierCategory) => {
    try {
      const newSupplier = await db.getNewSupplier(supplier.businessId)
      const updatedSupplier = newSupplier
        ? { ...supplier, ...newSupplier, supplierCategory }
        : { ...supplier, supplierCategory }

      // edit newly approved/denied supplier
      this.rootStore.uiStore.selectedSupplier = updatedSupplier
      this.rootStore.uiStore.editSupplier = updatedSupplier.businessId
      this.rootStore.uiStore.isSupplierAction = true
      this.actionCategory = supplierCategory
      this.actionNewSupplier = newSupplier
      this.actionUpdatedSupplier = updatedSupplier
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  getSupplierLiability = async (businessId: string, country: Country) => {
    try {
      const supplier = createSupplierInstance(businessId)
      this.rootStore.uiStore.toggleLoadingLiability()

      if (country === Country.Finland) {
        const callableResult = await callGetSupplierLiability(businessId)
        if (callableResult) {
          supplier.supplier = callableResult.supplier
          supplier.liability = callableResult.liability
        }
      }
      supplier.country = country
      this.rootStore.uiStore.handleAddSupplier(supplier)
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.rootStore.uiStore.toggleLoadingLiability()
  }

  @action
  getLiabilityPdf = async (businessId: string) => {
    try {
      this.liabilityPdf = null
      this.rootStore.uiStore.toggleLoadingPdf()
      const callableResult = await callGetLiabilityPdf(businessId)
      if (!callableResult) {
        this.rootStore.uiStore.displaySuccess('Tilaajavastuuta ei voitu hakea')
      }
      this.liabilityPdf = callableResult
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    this.rootStore.uiStore.toggleLoadingPdf()
  }

  @action
  setLiabilityPdf = () => {
    this.liabilityPdf = null
  }

  @action
  getChangelog = async (businessId: string) => {
    try {
      const changelog = await db.getChangelog(businessId)
      this.changelog = changelog
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  setFeedbackSupplier = (supplier: Supplier | undefined) => {
    this.feedbackSupplier = supplier
  }

  @action
  addFeedback = async (supplier: Supplier, rating: number, comment: string) => {
    const newFeedback: Feedback = {
      rating,
      comment,
      ratedBy: this.getUserIdentifier(),
      timestamp: createTimestamp()
    }
    try {
      const updatedSupplier = await db.addFeedback(supplier, newFeedback)
      this.rootStore.dataStore.handleUpdateSuppliers(updatedSupplier)
      this.rootStore.uiStore.displaySuccess()
      this.rootStore.uiStore.removeSupplierSelection()
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  addSupplierIssue = async (supplier: Supplier, site: string, details: string) => {
    const newIssue: SupplierIssue = {
      site,
      details,
      createdBy: this.getUserIdentifier(),
      timestamp: createTimestamp()
    }
    try {
      const updatedSupplier = await db.addSupplierIssue(supplier, newIssue)
      this.rootStore.dataStore.handleUpdateSuppliers(updatedSupplier)
      this.rootStore.uiStore.displaySuccess()
      this.rootStore.uiStore.removeSupplierSelection()
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  clearChangelog = () => {
    this.changelog = []
  }
}
