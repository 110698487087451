import { Container, Grid } from '@material-ui/core'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import React, { FC, useContext, useEffect } from 'react'
import { rootContext } from 'stores'

import Classification from './classification/Classification'
import NavBar from './nav/NavBar'
import Suppliers from 'components/table/Suppliers'
import SuppliersNew from './table/SuppliersNew'
import UserManagement from './user-management/UserManagement'
import { observer } from 'mobx-react-lite'
import { routes } from 'routes'

const Main: FC = observer(() => {
  const rootStore = useContext(rootContext)

  useEffect(() => {
    rootStore.initialize()
  }, [rootStore])

  return (
    <>
      <Route path={routes.root} component={NavBar} />
      <Container>
        <Grid container justifyContent="center" spacing={0}>
          <Switch>
            <Route exact path={routes.suppliersNew} component={SuppliersNew} />
            <Route exact path={routes.userManagement} component={UserManagement} />
            <Route exact path={routes.classification} component={Classification} />
            <Route path={routes.supplier} component={Suppliers} />
            <Redirect from={routes.root} to={routes.suppliers} exact />
            <Route render={() => <Link to={routes.suppliers}>Sivua ei löytynyt, Takaisin</Link>} />
          </Switch>
        </Grid>
      </Container>
    </>
  )
})

export default Main
