import { database } from 'firebaseConfig'

const USERS_COLLECTION = 'users'
const EDITORS_COLLECTION = 'editors'
const EDITOR_REQUESTS_COLLECTION = 'editorRequests'

export interface EditorDocument extends UserDocument {
  isAdmin?: boolean
}

export interface UserDocument {
  uid: string
  name: string
  timestamp: string
  email: string
}

/**
 * authorization managed by custom claims,
 * these are set with firebase functions -> firestore triggers
 */
class UserManagement {
  getUsers = async () => {
    const snapshot = await database.collection(USERS_COLLECTION).get()
    return snapshot.docs.map((doc) => doc.data() as UserDocument)
  }
  getEditors = async () => {
    const snapshot = await database.collection(EDITORS_COLLECTION).get()
    return snapshot.docs.map((doc) => doc.data() as EditorDocument)
  }
  getEditorRequests = async () => {
    const snapshot = await database.collection(EDITOR_REQUESTS_COLLECTION).get()
    return snapshot.docs.map((doc) => doc.data() as UserDocument)
  }
  addEditor = async (isEditorRequest: boolean, userData: UserDocument) => {
    await database.collection(EDITORS_COLLECTION).doc(userData.email).set(userData)
    if (isEditorRequest) {
      await this.removeEditorRequest(userData.email)
    }
  }
  addAdmin = async (userData: UserDocument) => {
    await database
      .collection(EDITORS_COLLECTION)
      .doc(userData.email)
      .set({ ...userData, isAdmin: true })
  }
  addEditorRequest = async (userData: UserDocument) => {
    await database.collection(EDITOR_REQUESTS_COLLECTION).doc(userData.email).set(userData)
  }
  checkEditorRequest = async (email: string) => {
    const docData = await database.collection(EDITOR_REQUESTS_COLLECTION).doc(email).get()
    return docData.exists
  }
  removeEditorRequest = async (email: string) => {
    await database.collection(EDITOR_REQUESTS_COLLECTION).doc(email).delete()
  }
  removeEditor = async (email: string) => {
    await database.collection(EDITORS_COLLECTION).doc(email).delete()
  }
  removeAdmin = async (email: string) => {
    await database.collection(EDITORS_COLLECTION).doc(email).delete()
  }
  setUserLoginState = async (userData: UserDocument) => {
    await database.collection(USERS_COLLECTION).doc(userData.email).set(userData, { merge: true })
  }
}

export default new UserManagement()
