/**
 * Country Codes ISO 3166
 */
export enum Country {
  Estonia = 'EE',
  Finland = 'FI',
  Latvia = 'LV',
  Lithuania = 'LT',
  Sweden = 'SE',
  Russia = 'RU',
  Germany = 'DE',
  Unknown = ''
}

export const displayCountry = (country: Country | undefined) => {
  switch (country) {
    case Country.Finland:
      return 'Suomi'
    case Country.Estonia:
      return 'Viro'
    case Country.Latvia:
      return 'Latvia'
    case Country.Lithuania:
      return 'Liettua'
    case Country.Sweden:
      return 'Ruotsi'
    case Country.Russia:
      return 'Venäjä'
    case Country.Germany:
      return 'Saksa'
    default:
      return ''
  }
}
