import React, { FC } from 'react'
import { TableCell, TableRow, Typography } from '@material-ui/core'
import { SupplierComment } from 'types/Supplier'
import { formatIsoDate } from 'utils/utils'
import styled from 'styled-components'

const Cell = styled(TableCell)`
  width: 100%;
  border-bottom: none;
  padding-left: 0;
`
const Title = styled(Typography)`
  font-weight: 500;
  display: inline;
`
const Timestamp = styled(Typography)`
  font-size: 11px;
  color: #888b8d;
  display: inline;
  margin-left: 3px;
`
const Comment = styled(Typography)`
  margin-top: 5px;
  word-break: break-word;
`
interface Props {
  comment: SupplierComment
}

const ViewCommentRow: FC<Props> = ({ comment }) => {
  return (
    <TableRow>
      <Cell>
        <Title as="div">{comment.createdBy}</Title>
        <Timestamp as="div">{formatIsoDate(comment.timestamp)}</Timestamp>
        <Comment as="div">{comment.comment}</Comment>
      </Cell>
    </TableRow>
  )
}

export default ViewCommentRow
