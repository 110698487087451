import {
  ArrowDownIcon,
  DrawerContentContainer,
  DrawerDivider,
  DrawerTable,
  Input,
  StyledSelectCss
} from 'components/common/Styled'
import { Box, MenuItem, Select, TableBody } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import {
  DisabledSupplierCategory,
  SupplierCategory,
  SupplierCategoryValues,
  getCategoryValue
} from 'types/SupplierCategory'
import React, { FC, useContext, useState } from 'react'
import { dbContext, uiContext } from 'stores'
import BasicInformation from '../shared/BasicInformation'
import ClassificationSelect from './ClassificationSelect'
import { DeepMap } from 'react-hook-form/dist/types/utils'
import DetailRow from '../shared/DetailRow'
import EditComments from './EditComments'
import DrawerContracts from '../shared/DrawerContracts'
import DrawerProjects from '../shared/DrawerProjects'
import DrawerSubHeader from '../shared/DrawerSubHeader'
import EditContacts from './EditContacts'
import FormButtons from './FormButtons'
import { Supplier } from 'types/Supplier'
import { SupplierForm } from 'api/db'
import { UserDocument } from 'api/userManagement'
import { displayContacts } from 'utils/utils'
import styled from 'styled-components'
import EditDeliveryChallenges from './EditDeliveryChallenges'

/**
 * create a supplier form based on fields that has been changed
 * --> we only need to submit values that has changed
 * @param dirtyFields react hook form changed fields
 */
const createModifiedForm = (
  dirtyFields: DeepMap<SupplierForm, true>,
  form: SupplierForm,
  dirtyContacts: boolean,
  dirtyComments: boolean,
  dirtyIssues: boolean
) => {
  const modifiedForm: SupplierForm = {}
  Object.keys(dirtyFields).forEach((field) => {
    modifiedForm[field] = form[field]
  })
  if (!modifiedForm.contacts && dirtyContacts) {
    modifiedForm.contacts = form.contacts || []
  }
  if (!modifiedForm.comments && dirtyComments) {
    modifiedForm.comments = form.comments || []
  }
  if (!modifiedForm.issues && dirtyIssues) {
    modifiedForm.issues = form.issues || []
  }
  return modifiedForm
}

const FirstMenuItem = styled(MenuItem)`
  height: 36px;
`

interface Props {
  supplier: Supplier
  isNewSupplier: boolean
  isNewSupplierEdit: boolean
  isSupplierAction: boolean
  closeDrawer: () => void
  authorizedUsers: UserDocument[]
}

const StyledSelect = styled((props) => (
  <Select {...props} variant="outlined" IconComponent={ArrowDownIcon} />
))`
  ${StyledSelectCss}
`

const EditSupplier: FC<Props> = ({
  supplier,
  isNewSupplier,
  isSupplierAction,
  closeDrawer,
  isNewSupplierEdit,
  authorizedUsers
}) => {
  const dbStore = useContext(dbContext)
  const uiStore = useContext(uiContext)
  const [dirtyContacts, setDirtyContacts] = useState(false)
  const [dirtyComments, setDirtyComments] = useState(false)
  const [dirtyIssues, setDirtyIssues] = useState(false)

  const contacts = displayContacts(supplier.contacts, supplier.additionalInfo)

  const { register, handleSubmit, errors, control, setValue, formState, getValues } =
    useForm<SupplierForm>({
      defaultValues: {
        supplier: supplier.supplier,
        placeOfBusiness: supplier.placeOfBusiness,
        contacts: contacts,
        fira2020: supplier.fira2020 || [],
        supplierCategory: supplier.supplierCategory,
        comments: supplier.comments || [], // hankinnan kommentit
        responsibleUser: supplier.responsibleUser || '',
        issues: supplier.issues || [] // delivery challenges = toimitushaasteet
      }
    })

  const { isDirty, dirtyFields } = formState

  const enableSubmitButton = () => {
    if (isNewSupplier) {
      return true
    }
    if (isSupplierAction) {
      return true
    }
    if (isDirty) {
      return true
    }
    return false
  }

  const onSubmit = handleSubmit((supplierForm) => {
    const modifiedForm = createModifiedForm(
      dirtyFields,
      supplierForm,
      dirtyContacts,
      dirtyComments,
      dirtyIssues
    )
    if (isNewSupplier) {
      // add name, category and liability for new suppliers
      modifiedForm.supplier = supplierForm.supplier
      modifiedForm.supplierCategory = supplierForm.supplierCategory
      modifiedForm.liability = supplier.liability
      modifiedForm.country = supplier.country
      dbStore.addNewSupplier(supplier, modifiedForm)
    } else {
      dbStore.updateSupplier(supplier, modifiedForm, isNewSupplierEdit)
    }
    closeDrawer()
  })

  const handleCancel = () => {
    uiStore.removeSupplierSelection()
  }

  return (
    <form onSubmit={onSubmit}>
      <FormButtons
        disableSubmit={!enableSubmitButton()}
        submitText="Tallenna"
        cancelText="Hylkää muutokset"
        handleCancel={handleCancel}
      />
      <DrawerContentContainer pt={2}>
        <BasicInformation
          supplier={supplier}
          isNewSupplier={isNewSupplier}
          name={
            <Input
              autoFocus
              name="supplier"
              error={errors.supplier}
              helperText={errors.supplier ? 'Syötä toimittajan' : ''}
              inputRef={register({ required: true, minLength: 1, maxLength: 99 })}
            />
          }
          classifications={
            <ClassificationSelect
              fira2020={supplier.fira2020}
              control={control}
              setValue={setValue}
            />
          }
          placeOfBusiness={<Input name="placeOfBusiness" inputRef={register({ maxLength: 99 })} />}
          supplierCategory={
            <Controller
              as={
                <StyledSelect>
                  {Object.keys(SupplierCategoryValues).map((category) => (
                    <MenuItem
                      key={category}
                      value={category}
                      disabled={DisabledSupplierCategory.includes(category as SupplierCategory)}
                    >
                      {getCategoryValue(category as SupplierCategory)}
                    </MenuItem>
                  ))}
                </StyledSelect>
              }
              name="supplierCategory"
              control={control}
            />
          }
          isViewer={true}
        />
        <DrawerDivider />
        <DrawerSubHeader header="Kausisopimukset" />
        <DrawerContracts contracts={supplier.seasonContracts} />
        <DrawerDivider />
        <DrawerSubHeader header="Työmaat" />
        <DrawerProjects projects={supplier.projects} purchases={supplier.purchases} />
        <DrawerDivider />
        <DrawerSubHeader header="Yhteystiedot" />
        <DetailRow
          title="Firan vastuuhenkilö"
          grayTitle
          content={
            <Controller
              as={
                <StyledSelect className="extra-margin">
                  <FirstMenuItem key="empty" value=""></FirstMenuItem>
                  {authorizedUsers.map((user, index) => (
                    <MenuItem key={index} value={user.email}>
                      {user.name}
                    </MenuItem>
                  ))}
                </StyledSelect>
              }
              name="responsibleUser"
              control={control}
            />
          }
        />
        <Box mt={1} />
        <EditContacts
          control={control}
          setIsDirtyContacts={(value) => setDirtyContacts(value)}
          errors={errors}
          getValues={getValues}
        />
        <DrawerDivider />
        <DrawerSubHeader header="Hankinnan kommentit" />
        <DrawerTable>
          <TableBody>
            <EditComments
              control={control}
              setIsDirtyComments={(value) => setDirtyComments(value)}
            />
          </TableBody>
        </DrawerTable>
        {supplier.issues && supplier.issues.length > 0 ? (
          <>
            <DrawerDivider />
            <DrawerSubHeader header="Toimitushaasteet" />
            <DrawerTable>
              <TableBody>
                <EditDeliveryChallenges
                  control={control}
                  setIsDirtyIssues={(value) => setDirtyIssues(value)}
                />
              </TableBody>
            </DrawerTable>
          </>
        ) : null}
      </DrawerContentContainer>
    </form>
  )
}

export default EditSupplier
