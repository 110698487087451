import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { PrimaryButton, SecondaryButton } from 'components/common/Styled'
import React, { FC } from 'react'

import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import styled from 'styled-components'

interface Props {
  deleteFira2020: () => void
  value: string
}

const StyledButton = styled(Button)`
  text-transform: none;
  padding-top: 0;
  padding-bottom: 0;
`

const DeleteDialog: FC<Props> = ({ deleteFira2020, value }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleDelete = () => {
    deleteFira2020()
    handleClose()
  }

  return (
    <>
      <StyledButton startIcon={<ClearIcon />} onClick={() => setIsOpen(true)}>
        Poista
      </StyledButton>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{`Poista luokka "${value}"?`}</DialogTitle>
        <DialogContent>
          <Typography>
            Poistamalla luokan, se poistuu myös toimittajilta johon luokka on lisätty.
          </Typography>
        </DialogContent>
        <DialogActions>
          <SecondaryButton variant="contained" onClick={handleClose}>
            Hylkää
          </SecondaryButton>
          <PrimaryButton variant="contained" onClick={handleDelete}>
            Hyväksy
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteDialog
