import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

import firebase from 'firebase/compat/app'

export const REGION = process.env.REACT_APP_REGION

const envConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

firebase.initializeApp(envConfig)

if (process.env.REACT_APP_DEPLOY_ENV === 'production') {
  try {
    firebase.analytics()
  } catch (e) {
    console.log('failed to load analytics')
  }
}

if (window.location.hostname === 'localhost') {
  console.log('running locally -- hitting local firestore emulator and functions')
  console.log('emulator init & url -- firebase emulators:start -- http://localhost:4000/firestore')
  firebase.firestore().settings({ host: 'localhost:8080', ssl: false })
  firebase.auth().useEmulator('http://localhost:9099/')
  firebase.app().functions(REGION).useEmulator('localhost', 5001)
  firebase.storage().useEmulator('localhost', 9199)
}

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.SESSION)
  .catch((e) => {
    console.log(e)
  })

export const powerBIUrls = {
  writtenFeedbackUrl:
    'https://app.powerbi.com/groups/me/reports/0f7eec5f-16a3-4894-9680-ff5e81bf9a7c/ReportSection0f5f1e3029ac27e2b987'
}

export const auth = firebase.auth()
export const storage = firebase.storage()
export const database = firebase.firestore()
export const functions = firebase.app().functions(REGION)
export const analytics = firebase.analytics()
export default firebase
