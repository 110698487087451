import { action, observable, makeObservable } from 'mobx'

import RootStore from './RootStore'
import { Supplier } from 'types/Supplier'
import { createSupplierInstance } from 'utils/utils'
import firebase from 'firebase/compat'

const localTableColumns = 'local-setting-table-columns'

/**
 * Available options for table columns
 */
export type TableColumnKeys = Pick<
  Supplier,
  | 'supplier'
  | 'creditRating'
  | 'liability'
  | 'businessId'
  | 'seasonContracts'
  | 'runningSpend'
  | 'fira2020'
  | 'supplierCategory'
  | 'feedback'
>

/**
 * Dictionary type of selected table columns
 */
export type TableColumns = Record<keyof TableColumnKeys, boolean>

const defaultColumns: TableColumns = {
  supplier: true,
  supplierCategory: true,
  creditRating: true,
  liability: true,
  businessId: false,
  seasonContracts: true,
  runningSpend: true,
  fira2020: true,
  feedback: true
}

export enum Severity {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success'
}

/**
 * State releated to the user interface
 */
export default class UIStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    const localSettings = localStorage.getItem(localTableColumns)
    if (localSettings) {
      const localTableColumns = JSON.parse(localSettings) as TableColumns
      this.selectedColumns = localTableColumns
      this.editedColumns = localTableColumns
    }
    this.setMobileColumns()
    makeObservable(this)
  }

  @observable hideNewBadge = false

  @observable isLoading = false
  @observable isLoadingLiability = false
  @observable isLoadingPdf = false

  // snackbar
  @observable message = ''
  @observable severity = Severity.info
  @observable isOpen = false
  @observable selectedColumns: TableColumns = defaultColumns
  @observable editedColumns: TableColumns = defaultColumns
  @observable mobileColumns: TableColumns = defaultColumns

  /**
   * supplier displayed in drawer
   */
  @observable selectedSupplier: Supplier | undefined = undefined
  /**
   * businessId of supplier being edited
   */
  @observable editSupplier: string | undefined = undefined
  /**
   * display add supplier content
   */
  @observable displayAddSupplier = false
  /**
   * edit new supplier
   */
  @observable isNewSupplierEdit = false
  /**
   * determine if a new supplier is being edited
   */
  @observable isNewSupplier = false
  /**
   * accept/deny new supplier action
   */
  @observable isSupplierAction = false

  displaySuccess = (msg?: string) => {
    this.addMessage(msg || 'Tiedot tallennettu onnistuneesti', Severity.info)
  }
  handleError = (error?: unknown) => {
    console.log(error)
    let severity = Severity.error
    let msg = 'Jotain meni pieleen'
    if ((error as firebase.FirebaseError).code === 'permission-denied') {
      severity = Severity.warning
      msg = 'Ei oikeuksia'
    }
    this.addMessage(msg, severity)
  }

  /**
   * Only the first two columns are visible on mobile, set rest of the columns to false
   */
  @action
  setMobileColumns = () => {
    const currentColumns = { ...this.selectedColumns }
    let availableMobileColumns = 2
    Object.keys(currentColumns).forEach((column) => {
      const columnKey = column as keyof TableColumns
      if (availableMobileColumns > 0 && currentColumns[columnKey]) {
        availableMobileColumns -= 1
      } else {
        currentColumns[columnKey] = false
      }
    })
    this.mobileColumns = currentColumns
  }

  @action
  addMessage = async (message: string, severity: Severity) => {
    this.message = message
    this.severity = severity
    this.toggleSnackbar()
  }

  @action
  toggleSnackbar = () => {
    this.isOpen = !this.isOpen
  }
  @action
  toggleLoadingLiability = () => {
    this.isLoadingLiability = !this.isLoadingLiability
  }
  @action
  toggleLoadingPdf = () => {
    this.isLoadingPdf = !this.isLoadingPdf
  }

  @action
  setHideNewBadge = (value: boolean) => {
    this.hideNewBadge = value
  }

  @action
  setSelectedSupplier = (supplier: Supplier | undefined) => {
    this.selectedSupplier = supplier
  }

  @action
  setEditedColumns = (editedColumns: TableColumns) => {
    this.editedColumns = editedColumns
  }

  @action
  applyEditedColumns = () => {
    this.selectedColumns = this.editedColumns
    try {
      localStorage.setItem(localTableColumns, JSON.stringify(this.selectedColumns))
    } catch (error) {}
    this.setMobileColumns()
  }

  @action
  setEditSupplier = (businessId: string | undefined, isNew: boolean) => {
    this.isNewSupplierEdit = isNew
    this.editSupplier = businessId
  }

  @action
  editNewSupplier = () => {
    this.displayAddSupplier = true
    const supplier = createSupplierInstance()
    this.selectedSupplier = supplier
  }

  @action
  removeSupplierSelection = () => {
    this.isNewSupplier = false
    this.displayAddSupplier = false
    this.selectedSupplier = undefined
    this.editSupplier = undefined
    this.isSupplierAction = false
    this.isNewSupplierEdit = false
    this.rootStore.dbStore.actionCategory = undefined
    this.rootStore.dbStore.actionNewSupplier = undefined
    this.rootStore.dbStore.actionUpdatedSupplier = undefined
    this.rootStore.dbStore.clearChangelog()
  }

  @action
  handleAddSupplier = (supplier: Supplier) => {
    this.displayAddSupplier = false
    this.isNewSupplier = true
    this.selectedSupplier = supplier
    this.editSupplier = supplier.businessId
  }
}
