import React, { FC, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import { routes } from 'routes'
import { userContext } from 'stores'

interface Props {
  component: FC
  path: string
  exact?: boolean
}

const PrivateRoute: FC<Props> = (props) => {
  const userStore = useContext(userContext)
  if (userStore.isAuthenticated) {
    return <Route path={props.path} exact={props.exact} component={props.component} />
  }
  return <Redirect to={routes.login} />
}

export default PrivateRoute
