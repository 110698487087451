import Fuse from 'fuse.js'
import { Supplier } from 'types/Supplier'
import { logSearch } from './analytics'

/**
 * See https://fusejs.io/api/options.html#basic-options
 * for details
 */
const defaultOptions: Fuse.IFuseOptions<Supplier> = {
  isCaseSensitive: false,
  includeScore: false,
  shouldSort: true,
  includeMatches: true,
  findAllMatches: true,
  ignoreLocation: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  useExtendedSearch: false,
  keys: [
    {
      name: 'fira2020AllValues',
      weight: 0.1
    },
    {
      name: 'supplier',
      weight: 0.2
    },
    {
      name: 'businessId',
      weight: 0.2
    }
  ]
}

const exactMatchOptions: Fuse.IFuseOptions<Supplier> = {
  ...defaultOptions,
  threshold: 0
}

export interface SearchResults {
  total: number
  suppliers: Supplier[]
  matches: string[]
}

export default class SearchEngine {
  private fuse: Fuse<Supplier> = new Fuse([], defaultOptions)
  private exactFuse: Fuse<Supplier> = new Fuse([], exactMatchOptions)

  constructor(list: Supplier[]) {
    this.fuse.setCollection(list)
    this.exactFuse.setCollection(list)
  }

  /**
   * Set the searchable collection
   */
  public setCollection(list: Supplier[]) {
    this.fuse.setCollection(list)
    this.exactFuse.setCollection(list)
  }

  public search(pattern: string, exactMatch?: boolean): SearchResults {
    logSearch(pattern)
    if (exactMatch) {
      const results: Fuse.FuseResult<Supplier>[] = this.exactFuse.search<Supplier>(pattern)
      return this.parseResults(results)
    }
    const results: Fuse.FuseResult<Supplier>[] = this.fuse.search<Supplier>(pattern)
    return this.parseResults(results)
  }

  private parseResults(results: Fuse.FuseResult<Supplier>[]): SearchResults {
    const suppliers: Supplier[] = []
    // unique set of matches
    const matches: Set<string> = new Set<string>()
    results.forEach((result) => {
      suppliers.push(result.item)
      // select first match (best result), and up to 5 matches
      if (result.matches?.length && result.matches[0].value) {
        if (matches.size < 5) {
          matches.add(result.matches[0].value)
        }
      }
    })

    return {
      suppliers,
      matches: Array.from(matches),
      total: results.length
    }
  }
}
