import React, { FC, MouseEvent, useContext } from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { TableColumnKeys, TableColumns } from 'stores/UIStore'

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg'
import { SortOrder } from './SupplierTable'
import { dataContext } from 'stores'
import styled from 'styled-components'

export interface HeadCell {
  id: keyof TableColumnKeys
  label: string
  sortable: boolean
}

interface Props {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableColumns) => void
  sortOrder: SortOrder[]
  disableActiveSort: boolean
  columns: TableColumns
  isNewSuppliers?: boolean
}

// note: the headcells array order needs to be the same as column order in the SupplierTableRow
export const headCells: HeadCell[] = [
  { id: 'supplier', label: 'Toimittajan nimi', sortable: true },
  { id: 'supplierCategory', label: 'Toimittajaluokka', sortable: true },
  { id: 'creditRating', label: 'Luottoluokka', sortable: true },
  { id: 'liability', label: 'Tilaajavastuu', sortable: true },
  { id: 'businessId', label: 'Y-tunnus', sortable: false },
  { id: 'seasonContracts', label: 'Kausisopimus', sortable: true },
  { id: 'runningSpend', label: 'Juoksevat ostot (12kk)', sortable: true },
  { id: 'fira2020', label: 'Luokat', sortable: false },
  { id: 'feedback', label: 'Suosittelupalaute', sortable: true }
]

export const newSupplierColumns: TableColumns = {
  supplier: true,
  supplierCategory: true,
  creditRating: true,
  liability: true,
  businessId: false,
  seasonContracts: false,
  runningSpend: false,
  fira2020: true,
  feedback: false
}

/**
 * Determine column width by specifying header cell width
 * (currently 'hardcoded' to fit table width)
 */
const Cell = styled(TableCell)`
  font-weight: normal;
  border-bottom: none;
  padding-bottom: 0;
  color: #888b8d;
  box-sizing: border-box;
`

const StyledArrowIcon = styled(ArrowIcon)`
  position: absolute;
  right: -20px;
  bottom: 40%;
`

const SortLabel = styled(TableSortLabel)`
  &.MuiTableSortLabel-active {
    color: #2c2a29;
    font-weight: 600;
  }
`

const getOrder = (sortOrder: SortOrder[], property: keyof TableColumns) => {
  return sortOrder.find((order) => order.property === property)?.order
}

const SupplierTableHead: FC<Props> = ({
  sortOrder,
  onRequestSort,
  disableActiveSort,
  columns,
  isNewSuppliers
}) => {
  const dataStore = useContext(dataContext)
  const createSortHandler = (property: keyof TableColumns) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
    dataStore.setHasSearchEngineSorting(false)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (headCell) =>
            (isNewSuppliers ? newSupplierColumns[headCell.id] : columns[headCell.id]) && (
              <Cell key={headCell.id}>
                {headCell.sortable ? (
                  <SortLabel
                    active={disableActiveSort && !!getOrder(sortOrder, headCell.id)}
                    direction={getOrder(sortOrder, headCell.id)}
                    onClick={createSortHandler(headCell.id)}
                    hideSortIcon
                    IconComponent={(props) => <StyledArrowIcon {...props} />}
                  >
                    {headCell.label}
                  </SortLabel>
                ) : (
                  headCell.label
                )}
              </Cell>
            )
        )}
        {isNewSuppliers && <Cell key="source">Lähde</Cell>}
      </TableRow>
    </TableHead>
  )
}

export default SupplierTableHead
