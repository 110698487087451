import { DrawerContentContainer, DrawerDivider, DrawerTable } from 'components/common/Styled'
import { Box, ListItem, ListItemText, Typography } from '@material-ui/core'
import React, { FC, RefObject, useEffect, useRef } from 'react'
import BasicInformation from 'components/drawer/shared/BasicInformation'
import ChangeLogs from './ChangeLogs'
import ClassificationDisplay from 'components/classification/ClassificationDisplay'
import CollapsibleDropdown from 'components/common/CollapsibleDropdown'
import DisplayFeedback from 'components/feedback/DisplayFeedback'
import DrawerContracts from 'components/drawer/shared/DrawerContracts'
import DrawerProjects from 'components/drawer/shared/DrawerProjects'
import DrawerSubHeader from 'components/drawer/shared/DrawerSubHeader'
import { Supplier, SupplierComment } from 'types/Supplier'
import { getCategoryValue } from 'types/SupplierCategory'
import { logViewSupplier } from 'api/analytics'
import styled from 'styled-components'
import { TableBody } from '@material-ui/core'
import ViewCommentRow from './ViewCommentRow'

interface Props {
  supplier: Supplier
  isNewSuppliers?: boolean
  closeDrawer: () => void
  isViewer: boolean
  handleScroll: (ref: RefObject<HTMLDivElement>) => void
}

const Highlight = styled.div`
  margin-top: 7px;
  &.denied {
    color: red;
  }
  &.clarify {
    color: orange;
  }
`

const NavMenu = styled(Box)`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: fixed;
  top: 64px;
  z-index: 100000;
  background-color: #fafafa;
  padding: 10px 16px 10px 24px;
  width: inherit;
`

const NavTitle = styled(Typography)`
  font-size: 12px;
  margin-right: 18px;
`

const ViewSupplier: FC<Props> = ({
  supplier,
  isNewSuppliers,
  closeDrawer,
  isViewer,
  handleScroll
}) => {
  const refOne = useRef<HTMLDivElement>(null)
  const refTwo = useRef<HTMLDivElement>(null)
  const refThree = useRef<HTMLDivElement>(null)
  const refFour = useRef<HTMLDivElement>(null)

  useEffect(() => {
    logViewSupplier(supplier)
  }, [supplier])

  const comments = (comments: SupplierComment[]) => (
    <>
      <DrawerSubHeader header="Hankinnan kommentit" />
      <DrawerTable>
        <TableBody>
          {comments
            .sort((a, b) => Date.parse(b.timestamp) - Date.parse(a.timestamp))
            .map((comment, index) => (
              <ViewCommentRow key={index} comment={comment} />
            ))}
        </TableBody>
      </DrawerTable>
      <DrawerDivider />
    </>
  )

  return (
    <>
      <NavMenu>
        <NavTitle onClick={() => handleScroll(refOne)}>Perustiedot</NavTitle>
        <NavTitle onClick={() => handleScroll(refTwo)}>Kausisopimukset</NavTitle>
        <NavTitle onClick={() => handleScroll(refThree)}>Työmaat</NavTitle>
        <NavTitle onClick={() => handleScroll(refFour)}>Arviot & toimitush.</NavTitle>
      </NavMenu>
      <DrawerContentContainer>
        {supplier.comments && supplier.comments.length > 0 ? comments(supplier.comments) : null}
        <div ref={refOne}>
          <BasicInformation
            supplier={supplier}
            isNewSupplier={isNewSuppliers}
            name={supplier.supplier}
            classifications={<ClassificationDisplay fira2020={supplier.fira2020} />}
            supplierCategory={
              <Highlight className={supplier.supplierCategory}>
                {getCategoryValue(supplier.supplierCategory)}
              </Highlight>
            }
            placeOfBusiness={
              <CollapsibleDropdown title={supplier.placeOfBusiness || ''} basic>
                <ListItem dense disableGutters>
                  <ListItemText primary="Käyntiosoite:" secondary={`${supplier.address || ''}`} />
                </ListItem>
                <ListItem dense disableGutters>
                  <ListItemText
                    primary="Postiosoite:"
                    secondary={`${supplier.postalAddress || ''}`}
                  />
                </ListItem>
              </CollapsibleDropdown>
            }
            isViewer={isViewer}
          />
        </div>
        <DrawerDivider />
        <div ref={refTwo}>
          <DrawerSubHeader header="Kausisopimukset" />
        </div>
        <DrawerContracts contracts={supplier.seasonContracts} />
        <DrawerDivider />
        <div ref={refThree}>
          <DrawerSubHeader header="Työmaat" />
        </div>
        <DrawerProjects projects={supplier.projects} purchases={supplier.purchases} />
        <DrawerDivider />
        <div ref={refFour}>
          <DrawerSubHeader header="Arviot & toimitushaasteet" />
        </div>
        <DisplayFeedback
          supplier={supplier}
          interactable
          isNewSuppliers={isNewSuppliers}
          closeDrawer={closeDrawer}
        />
        <ChangeLogs businessId={supplier.businessId} />
      </DrawerContentContainer>
    </>
  )
}

export default ViewSupplier
