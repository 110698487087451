import { Box, Hidden } from '@material-ui/core'
import React, { FC, MouseEvent, useContext } from 'react'

import { PrimaryButton } from './Styled'
import { Supplier } from 'types/Supplier'
import { dbContext } from 'stores'
import styled from 'styled-components'

interface Props {
  supplier: Supplier
}

const Primary = styled(PrimaryButton)`
  position: absolute;
  top: 16px;
  right: 20px;
  width: 130px;
`

const NewSupplierAction: FC<Props> = ({ supplier }) => {
  const dbStore = useContext(dbContext)
  const handleAccept = (e: MouseEvent) => {
    e.stopPropagation()
    dbStore.handleNewSupplierAction(supplier, 'approved')
  }

  return (
    <Hidden xsDown>
      <Box component="td" position="relative">
        <Primary variant="contained" onClick={handleAccept}>
          Tarkista tiedot
        </Primary>
      </Box>
    </Hidden>
  )
}

export default NewSupplierAction
