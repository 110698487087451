import { History, LocationState } from 'history'
import { action, computed, observable, makeObservable } from 'mobx'
import { callGetPersonalWorksiteSuppliers, callSetCustomClaimsNewUser } from 'api/functions'

import RootStore from './RootStore'
import { SupplierFilter } from 'types/Supplier'
import { auth } from 'firebaseConfig'
import firebase from 'firebaseConfig'
import { routes } from 'routes'

/**
 * user role managed with firebase custom claims.
 * access regulated with firebase security rules
 */
export enum UserRole {
  /**
   * admin, manage users, priviledged to do eveything
   */
  admin = 'admin',
  /**
   * able to write documents
   */
  editor = 'editor',
  /**
   * Default user role, anyone allowed to login can view content
   */
  viewer = 'viewer'
}

export default class UserStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore

    // observe user's sign-in state
    firebase.auth().onAuthStateChanged(async (user) => {
      this.setUserState(user)
      if (user) {
        this.setUserData(user)
        this.setUserRole(user)
        this.getAuthorizedUsers()
      }
    })
    // observe id token changes
    firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        this.setUserRole(user)
      }
    })
    makeObservable(this)
  }

  @observable user: firebase.User | null = null
  @observable userRole: UserRole = UserRole.viewer
  @observable hasSignInState = false
  @observable isAuthenticated = false
  @observable loginFailed = false
  @observable initials = 'T'
  @observable name = ''
  /**
   * identifier either name given by azure ad, or email
   */
  @observable identifier = ''
  @observable hasEntryUrl = false

  @observable isLoadingPersonalWorksiteSupplierIds = false
  @observable hasLoadedPersonalWorksiteSupplierIds = false
  @observable personalWorksiteSupplierIds: string[] = []

  @computed get isViewer() {
    return this.userRole === UserRole.viewer
  }
  @computed get isEditor() {
    return this.userRole === UserRole.editor
  }
  @computed get isAdmin() {
    return this.userRole === UserRole.admin
  }

  @action
  setUserState = (user: firebase.User | null) => {
    this.user = user
    this.isAuthenticated = !!user
    this.hasSignInState = true
  }

  @action
  setUserVariables = (name: string, identifier: string) => {
    this.initials = name[0].toUpperCase()
    this.name = name
    this.identifier = identifier
  }

  @action
  setUserData = (user: firebase.User) => {
    if (user.displayName) {
      this.setUserVariables(user.displayName, user.displayName)
    } else if (user.email) {
      const nameMatch = user.email.match(/^([^@]*)@/)
      const name = nameMatch ? nameMatch[1] : ' '
      this.setUserVariables(name, user.email)
    }
  }

  @action
  setIsLoadingPersonalWorksiteSupplierIds = (val: boolean) => {
    this.isLoadingPersonalWorksiteSupplierIds = val
  }
  @action
  setHasLoadedPersonalWorksiteSupplierIds = (val: boolean) => {
    this.hasLoadedPersonalWorksiteSupplierIds = val
  }

  @action
  getPersonalWorksiteSupplierIds = async () => {
    try {
      const email = this.user?.email || this.user?.uid || ''
      const worksiteSuppliers = await callGetPersonalWorksiteSuppliers(email)
      this.personalWorksiteSupplierIds = worksiteSuppliers?.businessIds || []
    } catch (error) {}
    this.hasLoadedPersonalWorksiteSupplierIds = true
    this.isLoadingPersonalWorksiteSupplierIds = false
    this.rootStore.dataStore.setActiveFilters(SupplierFilter.PersonalWorksiteSuppliers, true)
  }

  @action
  /**
   * Get a list of authorized users
   */
  getAuthorizedUsers = async () => {
    await this.rootStore.userManagementStore.getAuthorizedUsers()
  }

  @action
  setUserRole = async (user: firebase.User) => {
    try {
      const idTokenResult = await user.getIdTokenResult()
      // Confirm the user is an editor.
      if (!!idTokenResult.claims.editor) {
        this.userRole = UserRole.editor
      }
      // Confirm the user is an admin.
      if (!!idTokenResult.claims.admin) {
        this.userRole = UserRole.admin
      }
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  setUserLoginState = async (user?: firebase.User, name?: string, email?: string) => {
    const currentUser = user || this.user
    if ((!name && !this.name) || !currentUser) {
      return
    }

    const idEmail = email || currentUser.email || currentUser.uid
    const displayName = name || this.name || idEmail
    await this.rootStore.userManagementStore.setUserLoginState(
      currentUser.uid,
      idEmail,
      displayName
    )
  }

  @action
  login = async (email: string, password: string) => {
    try {
      await auth.signInWithEmailAndPassword(email, password)
      await this.setUserLoginState(
        this.user || undefined,
        this.user?.displayName || undefined,
        email
      )
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  /**
   * Extract the login info from the login result and handle the user login
   */
  @action
  extractLoginInfo = async () => {
    this.loginFailed = false
    try {
      const result = await auth.getRedirectResult()
      const { user, credential } = result

      if (!credential) {
        // This operation wasn't triggered by a redirect login, interrupt
        return
      }

      if (!user) {
        // No user info in the login response, can't proceed
        return
      }

      this.setUserLoginState(user, user.displayName || undefined, user.email || undefined)

      const userInfo = result.additionalUserInfo
      try {
        if (userInfo && userInfo.isNewUser) {
          const role = await callSetCustomClaimsNewUser()
          if (role) {
            this.userRole = role
          }
        }
      } catch (_e) {}
    } catch (error) {
      console.log(error)
      this.loginFailed = true
    }
  }

  @action
  logout = async (history: History<LocationState>) => {
    this.userRole = UserRole.viewer // Reset the permissions

    try {
      await auth.signOut()
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
    // redirect to login page
    history.push(routes.login)
  }

  /**
   * - add initial entry url to sessionStorage.
   * - Auth0 redirects to login page
   */
  @action
  setEntryUrl = (url: string) => {
    try {
      const path = new URL(url).pathname
      if (path !== routes.login) {
        sessionStorage.setItem('entryUrl', new URL(url).pathname)
      }
    } catch (error) {}
  }

  @action
  getEntryUrl = () => {
    try {
      const url = sessionStorage.getItem('entryUrl')
      this.hasEntryUrl = !!url
      return url
    } catch (error) {}
    return null
  }

  @action
  setHasEntryUrl = (toggle: boolean) => {
    this.hasEntryUrl = toggle
  }
}
