import { Grid, IconButton, LinearProgress, Typography } from '@material-ui/core'
import React, { FC, useContext, useEffect } from 'react'
import { dbContext, uiContext } from 'stores'

import { Liability } from 'types/Supplier'
import { ReactComponent as LiabilityHuomioi } from 'assets/svg/LiabilityHuomioi.svg'
import { ReactComponent as LiabilityOdotetaan } from 'assets/svg/LiabilityOdotetaan.svg'
import { ReactComponent as LiabilityOk } from 'assets/svg/LiabilityOk.svg'
import { ReactComponent as LiabilitySeis } from 'assets/svg/LiabilitySeis.svg'
import { ReactComponent as LiabilitySelvita } from 'assets/svg/LiabilitySelvitä.svg'
import { ReactComponent as PdfIcon } from 'assets/svg/pdf.svg'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

interface Props {
  isInline?: boolean
  liability?: Liability
  businessId: string
}

const Title = styled(Grid)`
  margin-top: 6px;
`

const Container = styled(Grid)`
  margin-top: 0px;
`
const PdfButton = styled(IconButton)`
  margin-top: -3px;
  float: right;
`

const LiabilityItem = styled(Grid)`
  margin-top: 5px;
  &.isInline {
    margin-top: 0px;
  }
`

const InlineText = styled(Typography)`
  font-size: 0.875rem;
  font-family: GT America, sans-serif;
  font-weight: 400;
  margin-left: 9px;
  font-size: 14px;
  margin-top: -3px;

  &.NotAvailable {
    margin-top: 10px;
    margin-left: 0px;
  }
`

const LiabilityText = styled(Grid)`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #000000;
  margin-left: 5px;
  margin-bottom: 5px;
  word-break: break-all;
`

const getLiabilityIcon = (liability?: Liability) => {
  switch (liability) {
    case 'Seis':
      return <LiabilitySeis />
    case 'Selvitä':
      return <LiabilitySelvita />
    case 'Tietoja odotetaan':
      return <LiabilityOdotetaan />
    case 'Huomioi':
      return <LiabilityHuomioi />
    case 'Ok':
      return <LiabilityOk />
    default:
      return ''
  }
}

const NotAvailable = 'Ei saatavilla'

function downloadPDF(pdf: string) {
  const linkSource = `data:application/pdf;base64,${pdf}`
  const downloadLink = document.createElement('a')
  const fileName = 'tilaajavastuu.pdf'
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

const getLiabilityText = (liability?: Liability) => {
  switch (liability) {
    case 'Seis':
      return 'Ei kunnossa'
    case 'Selvitä':
      return 'Vaatii lisäselvitystä'
    case 'Tietoja odotetaan':
      return 'Puuttuva tieto'
    case 'Huomioi':
      return 'Ei velvollisuutta'
    case 'Ok':
      return 'Kunnossa'
    default:
      return NotAvailable
  }
}

const LiabilityIndicator: FC<Props> = observer(({ businessId, liability, isInline }) => {
  const dbStore = useContext(dbContext)
  const uiStore = useContext(uiContext)

  useEffect(() => {
    if (dbStore.liabilityPdf) {
      downloadPDF(dbStore.liabilityPdf)
      dbStore.setLiabilityPdf()
    }
  }, [dbStore, dbStore.liabilityPdf])

  const getPdf = () => {
    dbStore.getLiabilityPdf(businessId)
  }
  const liabilityText = getLiabilityText(liability)
  return (
    <Container container direction="column" alignItems="flex-start">
      {!isInline && <Title item>{businessId}</Title>}
      <LiabilityItem item className={`${isInline && 'isInline'}`}>
        <Grid container direction="row" alignItems="center">
          <Grid item>{getLiabilityIcon(liability)}</Grid>
          {isInline ? (
            <InlineText className={`${liabilityText === NotAvailable && 'NotAvailable'}`}>
              {liabilityText}
            </InlineText>
          ) : (
            <LiabilityText item>
              Tilaajavastuu <br /> {liabilityText}
            </LiabilityText>
          )}
          {isInline && liabilityText !== NotAvailable && (
            <Grid item>
              <PdfButton onClick={getPdf}>
                <PdfIcon />
              </PdfButton>
            </Grid>
          )}
        </Grid>
        {uiStore.isLoadingPdf && <LinearProgress />}
      </LiabilityItem>
    </Container>
  )
})

export default LiabilityIndicator
