import { InputAdornment, TextField } from '@material-ui/core'
import React, { FC, useContext } from 'react'
import { dataContext, uiContext } from 'stores'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import { Loading } from 'components/common/Styled'
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'
import debounce from 'lodash.debounce'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

const Search = styled(TextField)`
  justify-content: center;

  fieldset {
    border-radius: 2px;
  }
  && input[type='text'] {
    font-size: 14px;
    height: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  && .MuiAutocomplete-inputRoot {
    padding: 0px;
  }
`

const StyledOption = styled.div`
  width: 100%;
  height: 100%;
  padding: 6px 16px;
`
const StyledSearch = styled(SearchIcon)`
  margin-left: 10px;
`
const StyledList = styled.ul`
  & > li {
    padding: 0;
  }
`

const StyledAutocomplete = styled(Autocomplete)`
  && .MuiAutocomplete-endAdornment {
    margin-right: 7px;
  }

  @media all and (min-width: 600px) {
    width: 300px;
  }
` as typeof Autocomplete

const SupplierSearch: FC = observer(() => {
  const dataStore = useContext(dataContext)
  const uiStore = useContext(uiContext)

  const debouncedSearch = debounce((pattern: string, reason: string) => {
    // don't trigger search on option select
    if (reason !== 'reset') {
      dataStore.search(pattern, false)
    }
  }, 500)

  const handleInputChange = (_e: unknown, value: string, reason: string) => {
    debouncedSearch(value, reason)
  }

  const handleOptionClick = (option: string) => {
    dataStore.search(option, true)
  }

  return (
    <>
      <StyledAutocomplete
        key={dataStore.searchFieldKey.toString()}
        freeSolo
        onInputChange={handleInputChange}
        options={dataStore.searchMatches}
        closeIcon={<ClearIcon />}
        ListboxComponent={StyledList}
        renderOption={(option) => (
          <StyledOption onClick={() => handleOptionClick(option)}>{option}</StyledOption>
        )}
        renderInput={(params) => (
          <Search
            {...params}
            placeholder="Etsi toimittajarekisteristä"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <StyledSearch />
                </InputAdornment>
              )
            }}
          />
        )}
      />
      {uiStore.isLoading && <Loading />}
    </>
  )
})

export default SupplierSearch
