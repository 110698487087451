import { BackgroundSVG, PrimaryButton } from './common/Styled'
import { Box, Grid, LinearProgress, Paper, TextField, Typography } from '@material-ui/core'
import React, { FormEvent, useContext, useEffect, useState } from 'react'

import { ReactComponent as FiraLogoDark } from 'assets/svg/FiraLogoDark.svg'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { routes } from 'routes'
import styled from 'styled-components'
import { userContext } from 'stores'
import firebase, { auth } from 'firebaseConfig'

const Title = styled(Typography)`
  display: inline;
`

const HelperText = styled(Typography)`
  color: #f5f2ed;
  text-align: center;
`

const TitleContainer = styled(Grid)`
  margin-bottom: 57px;
`

const StyledContainer = styled(Grid)`
  min-height: 100vh;
`

const ContentContainer = styled(Grid)`
  border-bottom: 8px solid #01ff95;

  @media all and (min-width: 550px) {
    width: 360px;
    min-height: 400px;
  }
`

const StyledPaper = styled(Paper)`
  @media all and (max-width: 549px) {
    width: 80vw;
  }
`

const StyledLogo = styled(FiraLogoDark)`
  margin: 100px 0 25px 0;
`

const authProvider = new firebase.auth.OAuthProvider('microsoft.com')
authProvider.setCustomParameters({
  // eslint-disable-next-line
  login_hint: 'user@fira.com',
  tenant: 'd1329110-9a49-4ab9-8040-3cc617eff3fa'
})

const SignIn = observer(() => {
  const userStore = useContext(userContext)
  const [showAdmin, setShowAdmin] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const isAuthenticated = !!auth.currentUser

  const loginWithRedirect = async () => {
    await auth.signInWithRedirect(authProvider)
  }

  const handleSubmit = (event: FormEvent<EventTarget>) => {
    event.preventDefault()
    userStore.login(email, password)
  }

  const displayLoading = () => {
    if (userStore.loginFailed) {
      return false
    }
    return isAuthenticated || !userStore.hasSignInState
  }

  useEffect(() => {
    if (isAuthenticated) {
      userStore.extractLoginInfo()
    }
  }, [isAuthenticated, userStore])

  if (userStore.isAuthenticated) {
    const entryUrl = userStore.getEntryUrl()
    return <Redirect to={!entryUrl || entryUrl === routes.login ? routes.suppliers : entryUrl} />
  }

  return (
    <>
      <BackgroundSVG />
      <StyledContainer container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <Box mb={8} />
          <StyledPaper elevation={3}>
            <ContentContainer
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box ml={2} mr={2} mb={4}>
                <TitleContainer
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <StyledLogo />
                  <Title variant="subtitle2">Toimittajarekisteri</Title>
                </TitleContainer>
                <Typography
                  component="h1"
                  variant="h5"
                  align="center"
                  onDoubleClick={() => setShowAdmin(!showAdmin)}
                >
                  Kirjaudu
                </Typography>
                <Box mb={2} />
                {showAdmin && (
                  <form noValidate onSubmit={handleSubmit}>
                    <TextField
                      color="secondary"
                      variant="outlined"
                      label="Admin email"
                      margin="normal"
                      fullWidth
                      autoComplete="email"
                      autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                      color="secondary"
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      label="Password"
                      type="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box mb={1} />
                    <PrimaryButton type="submit" variant="contained">
                      Kirjaudu (Admin)
                    </PrimaryButton>
                  </form>
                )}
              </Box>
              <PrimaryButton
                variant="contained"
                onClick={loginWithRedirect}
                disabled={displayLoading()}
              >
                Kirjaudu
              </PrimaryButton>
              <Box mt={1} width="130px">
                {displayLoading() && <LinearProgress />}
              </Box>
              <Box mb={2} />
            </ContentContainer>
          </StyledPaper>
        </Grid>
        <Box mb={8} />
        <Grid item>
          <HelperText variant="body2" color="inherit">
            Ongelmia kirjautumisessa?
          </HelperText>
        </Grid>
        <Grid item>
          <HelperText variant="body2" color="initial">
            Jos et pääse sisäänkirjautumaan, ota yhteyttä asiakastuki@atea.fi
          </HelperText>
        </Grid>
      </StyledContainer>
    </>
  )
})

export default SignIn
