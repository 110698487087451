import { BrowserRouter, Route, Switch } from 'react-router-dom'
import React, { FC, useContext } from 'react'
import { StylesProvider, ThemeProvider } from '@material-ui/styles'

import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback'
import Login from 'components/Login'
import Main from 'components/Main'
import Notifications from 'components/Notifications'
import PrivateRoute from 'components/PrivateRoute'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { routes } from 'routes'
import theme from 'theme'
import { userContext } from 'stores'

const App: FC = () => {
  const userStore = useContext(userContext)
  userStore.setEntryUrl(window.location.href)

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Switch>
                <Route exact path={routes.login} component={Login} />
                <PrivateRoute path={routes.root} component={Main} />
              </Switch>
              <Notifications />
            </ErrorBoundary>
          </BrowserRouter>
        </StyledThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default App
