import React, { FC, ReactNode } from 'react'

import { Typography } from '@material-ui/core'
import styled from 'styled-components'

interface Props {
  icon: ReactNode
  text: string
  secondary?: boolean
}

const ButtonText = styled(Typography)`
  font-size: 14px;
  line-height: 15px;
  text-transform: none;
  margin-left: 9px;
  margin-top: 3px;
`

const ButtonContent: FC<Props> = ({ icon, text, secondary }) => (
  <>
    {icon}
    <ButtonText color={secondary ? 'textSecondary' : undefined}>{text}</ButtonText>
  </>
)

export default ButtonContent
