import React, { FC, useState } from 'react'

import { Box } from '@material-ui/core'
import { NotAvailable } from 'components/common/Styled'
import { StyledButton } from './ClassificationDisplay'
import styled from 'styled-components'

const ListHorizontal = styled.div`
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
`

interface Props {
  content: string[]
  additionalContent?: string[]
  isHorizontal: boolean
}

const VisibleClassifications: FC<Props> = ({ content, isHorizontal, additionalContent }) => {
  const [displayAdditionalContent, setDisplayAdditionalContent] = useState(false)

  // TODO: fix hardcode
  const isNotAvailable = content[0] === 'Ei saatavilla - Not available' || !content

  return (
    <>
      {isHorizontal ? (
        <ListHorizontal>{isNotAvailable ? <NotAvailable /> : content.join(', ')}</ListHorizontal>
      ) : (
        <>
          {content.map((value, key) => (
            <Box mb={0.4} key={key}>
              {value}
              <br />
            </Box>
          ))}
          {displayAdditionalContent &&
            additionalContent?.map((value, key) => (
              <Box mb={0.4} key={key}>
                {value}
                <br />
              </Box>
            ))}
          {(additionalContent?.length || 0) > 0 && (
            <StyledButton
              variant="text"
              disableRipple
              disableFocusRipple
              color="primary"
              onClick={() => setDisplayAdditionalContent(!displayAdditionalContent)}
            >
              {displayAdditionalContent
                ? `- näytä vähemmän`
                : `+ näytä lisää (${additionalContent?.length})`}
            </StyledButton>
          )}
        </>
      )}
    </>
  )
}

export default VisibleClassifications
