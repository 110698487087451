import React, { FC } from 'react'
import { TableBody, TableCell, TableRow } from '@material-ui/core'

import { ADDITIONAL_INFO_NAME_KEY } from 'types/Supplier'
import CollapsibleDropdown from 'components/common/CollapsibleDropdown'
import { DrawerTable } from 'components/common/Styled'
import { parseAdditionalInfo } from 'utils/utils'
import styled from 'styled-components'

interface Props {
  additionalInfo: string
}

const Name = styled(TableCell)`
  vertical-align: top;
  padding-left: 0;
  width: 100%;
  word-break: break-word;
`

const AdditionalInfo: FC<Props> = ({ additionalInfo }) => {
  const entries = parseAdditionalInfo(additionalInfo)

  if (!entries.length || entries.length === 1) {
    return <></>
  }
  return (
    <CollapsibleDropdown title={'Lisätietoja (fira.fi)'} fullDrawer>
      <DrawerTable>
        <TableBody>
          {entries.map((info, index) => {
            if (info.name === ADDITIONAL_INFO_NAME_KEY) {
              return null
            }
            return (
              <TableRow key={index}>
                <Name>
                  <b>{info.name}</b>
                  <br />
                  {info.value}
                </Name>
              </TableRow>
            )
          })}
        </TableBody>
      </DrawerTable>
    </CollapsibleDropdown>
  )
}

export default AdditionalInfo
