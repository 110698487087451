import RootStore from 'stores/RootStore'
import { createContext } from 'react'

const rootStore = new RootStore()

// provide the stores to components with createContext(defaultValue)
// defaultValue being a mobx store, which handles component updating

export const rootContext = createContext(rootStore)
export const userContext = createContext(rootStore.userStore)
export const dataContext = createContext(rootStore.dataStore)
export const dbContext = createContext(rootStore.dbStore)
export const uiContext = createContext(rootStore.uiStore)
export const userManagementContext = createContext(rootStore.userManagementStore)
export const classificationContext = createContext(rootStore.classificationStore)
