import { ArrowDownIcon, StyledSelectCss } from 'components/common/Styled'
import { Chip, MenuItem, Select } from '@material-ui/core'
import { Control, Controller } from 'react-hook-form'
import React, { ChangeEvent, FC, useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

import { ReactComponent as ClearIcon } from 'assets/svg/clearWhiteSmall.svg'
import { SetValueConfig } from 'react-hook-form/dist/types/form'
import { SupplierForm } from 'api/db'
import { classificationContext } from 'stores'

const DeleteIcon = styled.div`
  background: #2c2a29;
  border-radius: 11px;
  width: 16px;
  height: 16px;
  text-align: center;
`
const StyledClearIcon = styled(ClearIcon)`
  position: relative;
  top: calc(50% - 9px);
`

const StyledChip = styled(Chip)`
  background: #f5f2ed;
  border-radius: 11px;
  height: 22px;
  margin-bottom: 2px;
  && .MuiChip-label {
    max-width: 167px;
  }
`

const StyledSelect = styled((props) => (
  <Select {...props} displayEmpty variant="outlined" multiple IconComponent={ArrowDownIcon} />
))`
  ${StyledSelectCss}
`

interface Props {
  fira2020?: string[]
  control: Control<SupplierForm>
  setValue: (name: string, value: string[], options?: SetValueConfig) => void
}

const ClassificationSelect: FC<Props> = observer(({ fira2020, setValue, control }) => {
  const classifications = fira2020 || []
  const classificationStore = useContext(classificationContext)
  const [selected, setSelected] = useState(classifications)

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const selection = event.target.value as string[]
    setSelected(selection)
    setValue('fira2020', selection, { shouldDirty: true })
  }
  const handleDelete = (deleted: string) => {
    const selection = selected.filter((sel) => sel !== deleted)
    setSelected(selection)
    setValue('fira2020', selection, { shouldDirty: true })
  }

  return (
    <Controller
      as={
        <>
          <StyledSelect value={selected} onChange={handleChange} renderValue={() => <>Valitse</>}>
            {classificationStore.sortedFira2020Keys.map((key) => {
              const names = classificationStore.fira2020[key]?.names || []
              if (names.length === 0) {
                return undefined
              }

              return (
                <MenuItem key={key} value={key}>
                  {names[0]}
                </MenuItem>
              )
            })}
          </StyledSelect>
          {selected.map((key) => {
            const names = classificationStore.fira2020[key]?.names || []
            if (names.length === 0) {
              return undefined
            }
            return (
              <StyledChip
                key={key}
                label={names[0]}
                onDelete={() => handleDelete(key)}
                deleteIcon={
                  <DeleteIcon>
                    <StyledClearIcon />
                  </DeleteIcon>
                }
              />
            )
          })}
        </>
      }
      control={control}
      name="fira2020"
    />
  )
})

export default ClassificationSelect
