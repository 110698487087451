import { Country } from './Country'
import { SupplierCategory } from './SupplierCategory'

export interface Supplier extends Partial<SupplierOptionalFields> {
  businessId: string
}

export interface SupplierPurchase {
  businessId: string
  amount: number
  projectSegmentName: MainSegment | string | null
}

export enum SupplierFilter {
  ActiveContracts,
  ArchivedSuppliersOnly,
  OwnSuppliers,
  PersonalWorksiteSuppliers
}

export type creditRatingCategory = 'AAA' | 'AA' | 'A' | 'AN' | 'EIRA' | 'B' | 'C' | ''
export type revenueCategory = '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09'
export type employmentCategory = '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09'

export type SupplierSource = 'sitemanager' | 'toimittajarekisteri' | 'fira.fi'

interface SupplierOptionalFields {
  duns: string
  supplier: string
  creditRating: creditRatingCategory
  previousCreditRating: creditRatingCategory
  creditRatingDate: string
  revenue: revenueCategory
  employees: employmentCategory
  placeOfBusiness: string
  purchasesFromFira: number | null
  liability: Liability
  feedback: Feedback[]
  issues: SupplierIssue[]
  supplierCategory: SupplierCategory
  modifiedAt: string
  modifiedBy: string
  seasonContracts: SeasonContract[]
  projects: SupplierProject[]
  /**
   * supplier contacts stored in firestore,
   * additional contacts can be found in additionalInfo with the key name 'sitemanager_contacts'
   */
  contacts: SupplierContact[]
  purchases: SupplierPurchase[]
  comments: SupplierComment[]
  tradeRegister: string
  tradeRegisterDate: string
  defaultCode: string
  defaultDate: string
  address: string
  postalAddress: string
  grade: Grade
  /**
   * Fira2020 keys
   */
  fira2020: Fira2020Key[]
  /**
   * Used for search indexing
   */
  fira2020AllValues: string[]
  runningSpend: number
  /**
   * origin of supplier
   */
  source: SupplierSource
  /**
   * country code of supplier (Perustusmaa)
   */
  country: Country
  /**
   * Firan vastuuhenkilön email (hankinta)
   */
  responsibleUser: string
  /**
   * timestamp a supplier category value is added to the supplier.
   * Used to highlight new suppliers withing time-range
   */
  addedAt: string
  /**
   * additinal information of the supplier
   * string format: [{"name":"a", "value":"b"}]
   */
  additionalInfo: string
  website: string
}

type contractState = 0 | 1

export interface SeasonContract {
  businessId: string
  /**
   * link to the season contract
   */
  viewUrl: string
  name: string
  endDate: string
  /**
   * Vastuuhenkilö
   */
  personInCharge: string
  /**
   * Hankinta
   */
  acquisition: string
  /**
   * Sopimustyyppi (Kausitarjous)
   */
  seasonType: string
  /**
   * Sopimustyyppi (Sitova)
   */
  bindingType: string
  /**
   * Determines if season contract is active
   */
  active: contractState
  /**
   * Determines if season contract is binding (sitova)
   */
  binding: contractState
}

export interface SupplierContact {
  firstName?: string
  lastName?: string
  name?: string
  email: string
  phone: string
  role?: string
}

export interface SupplierProject {
  businessId: string
  projectSegmentName: MainSegment | string | null
  projectName: string | null
  promisee: string
}

/**
 * Rakentamisen linjat
 */
export enum MainSegment {
  ResidentalConstruction = 'Asuntorakentaminen',
  CommercialConstruction = 'Liike- ja toimitilarakentaminen',
  Modernisation = 'Modernisointi',
  PipeRenovations = 'Putkiremontit'
}

export const MainSegments = [
  MainSegment.ResidentalConstruction,
  MainSegment.CommercialConstruction,
  MainSegment.Modernisation,
  MainSegment.PipeRenovations
]

export interface SupplierComment {
  createdBy: string
  timestamp: string
  comment: string
}

export interface SupplierIssue {
  createdBy: string
  timestamp: string
  site: string
  details: string
}

export interface Feedback {
  rating: number
  ratedBy: string
  comment: string
  timestamp: string
}

export interface Grade {
  businessId: string
  safety: number | null
  quality: number | null
  schedule: number | null
  cooperation: number | null
  compilance: number | null
  billing: number | null
  additionalWork: number | null
  average: number | null
  count: number
  recyclingAndEnvironment: number | null
  managementOfSubcontractors: number | null
}

/**
 * string key ex. "0" | "1" ...
 */
export type Fira2020Key = string

export type Liability = 'Seis' | 'Selvitä' | 'Tietoja odotetaan' | 'Huomioi' | 'Ok' | ''

/**
 * Supplier Firestore Document fields
 */
export interface SupplierDocument extends Partial<SupplierDocumentFields> {
  businessId: string
}

interface SupplierDocumentFields {
  businessId: string
  supplier: string
  placeOfBusiness: string
  contacts: SupplierContact[]
  supplierCategory: SupplierCategory
  comments: SupplierComment[]
  feedback: Feedback[]
  issues: SupplierIssue[]
  modifiedBy: string
  modifiedAt: string
  liability: Liability
  fira2020: Fira2020Key[]
  country: Country
  source: SupplierSource
  responsibleUser: string
  addedAt: string
}

// sitemanager contacts key in additional info
export const ADDITIONAL_INFO_NAME_KEY = 'sitemanager_contacts'
export interface AdditionalInfo {
  name: string
  value: string
}
