import { Box, ButtonBase, Grid, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { FC, useContext } from 'react'
import { uiContext, userContext } from 'stores'

import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import { ReactComponent as DotsIcon } from 'assets/svg/dots.svg'
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg'
import { StyledListItemIcon } from 'components/common/Styled'
import { Supplier } from 'types/Supplier'
import { routes } from 'routes'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import DrawerNavBackground from 'assets/svg/DrawerNavBackground.svg'

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;

  @media all and (max-width: 404px) {
    width: 83%;
  }
`

const Container = styled(Box)`
  background-image: url(${DrawerNavBackground});
  position: fixed;
  top: 0;
  width: inherit;
  overflow-x: hidden;
  z-index: 10000;
  min-height: 64px;

  @media all and (max-width: 404px) {
    width: 100%;
  }
`

interface Props {
  supplier: Supplier
  closeDrawer: () => void
  displayAddSupplier: boolean
}

const DrawerHeader: FC<Props> = ({ supplier, closeDrawer, displayAddSupplier }) => {
  const currentLocation = useLocation()
  const uiStore = useContext(uiContext)
  const userStore = useContext(userContext)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const isNewSuppliers = currentLocation.pathname === routes.suppliersNew

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const disableEdit = () => {
    if (uiStore.isNewSupplierEdit) {
      return true
    }
    return userStore.isViewer
  }

  const handleEdit = () => {
    uiStore.setEditSupplier(supplier.businessId, isNewSuppliers)
    closeMenu()
  }

  return (
    <>
      <Container>
        <Box ml={3} mr={2} mt={1.6}>
          <Grid container direction="row">
            <Title variant="h6">
              {displayAddSupplier
                ? 'Lisää uusi toimittaja'
                : supplier.supplier || 'Uusi toimittaja'}
            </Title>
            <Box mr={1.25} component={ButtonBase} onClick={openMenu}>
              <DotsIcon />
            </Box>
            <Box component={ButtonBase} onClick={closeDrawer}>
              <ClearIcon />
            </Box>
          </Grid>
        </Box>
      </Container>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem onClick={handleEdit} disabled={disableEdit()}>
          <StyledListItemIcon>
            <EditIcon />
          </StyledListItemIcon>
          <ListItemText primary="Muokkaa" />
        </MenuItem>
      </Menu>
    </>
  )
}

export default DrawerHeader
