import {
  Avatar,
  Badge,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import { History, LocationState } from 'history'
import React, { FC, useContext, useState } from 'react'
import { dataContext, uiContext, userContext } from 'stores'

import { ReactComponent as AddSupplierIcon } from 'assets/svg/addSupplier.svg'
import { ReactComponent as HashtagSmallIcon } from 'assets/svg/hashtag-small.svg'
import { ReactComponent as LogoutIcon } from 'assets/svg/logout.svg'
import { StyledListItemIcon } from 'components/common/Styled'
import { ReactComponent as UserIcon } from 'assets/svg/user.svg'
import { observer } from 'mobx-react-lite'
import { routes } from 'routes'
import styled from 'styled-components'

const CompanyName = styled(Typography)`
  font-size: 10px;
  line-height: 11px;
`

const Container = styled(Grid)`
  margin-top: 10px;
  margin-left: auto;
  margin-right: 0;
`

const UserName = styled(Typography)`
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 3px;
  text-transform: capitalize;
`

const StyledAvatar = styled(Avatar)`
  background-color: white;
  color: black;
  width: 50px;
  height: 50px;
`

const StyledBadge = styled(Badge)`
  && .MuiBadge-badge {
    background-color: #25b900;
    color: white;
  }
`
const StyledButton = styled(IconButton)`
  padding-top: 0;
  padding-bottom: 0;
`

interface Props {
  name: string
  initials: string
  history: History<LocationState>
  logout: () => void
}

const NavMenu: FC<Props> = observer(({ initials, name, logout, history }) => {
  const uiStore = useContext(uiContext)
  const dataStore = useContext(dataContext)
  const userStore = useContext(userContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    uiStore.setHideNewBadge(true)
  }

  const redirectSuppliersNew = () => {
    history.push(routes.suppliersNew)
    handleClose()
  }

  const redirectAdminPanel = () => {
    history.push(routes.userManagement)
    handleClose()
  }

  const redirectClassificationPanel = () => {
    history.push(routes.classification)
    handleClose()
  }

  const disableBadge = () => {
    if (userStore.isViewer) {
      return true
    }
    return uiStore.hideNewBadge || dataStore.totalNewSuppliers === 0
  }

  return (
    <Container>
      <Grid container direction="row" alignItems="center">
        <Grid>
          <UserName>{name}</UserName>
          <CompanyName align="right">Fira</CompanyName>
        </Grid>
        <StyledButton onClick={handleClick}>
          <StyledBadge
            overlap="circular"
            badgeContent={dataStore.totalNewSuppliers}
            invisible={disableBadge()}
          >
            <StyledAvatar>{initials}</StyledAvatar>
          </StyledBadge>
        </StyledButton>
        <Menu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={redirectSuppliersNew} disabled={userStore.isViewer}>
            <StyledListItemIcon>
              <StyledBadge variant="dot" invisible={disableBadge()}>
                <AddSupplierIcon />
              </StyledBadge>
            </StyledListItemIcon>
            <ListItemText primary="Uudet toimittajat" />
          </MenuItem>
          <Divider variant="middle" />
          <MenuItem onClick={redirectAdminPanel} disabled={!userStore.isAdmin}>
            <StyledListItemIcon>
              <UserIcon />
            </StyledListItemIcon>
            <ListItemText primary="Hallitse käyttäjiä" />
          </MenuItem>
          <Divider variant="middle" />
          <MenuItem onClick={redirectClassificationPanel} disabled={userStore.isViewer}>
            <StyledListItemIcon>
              <HashtagSmallIcon />
            </StyledListItemIcon>
            <ListItemText primary="Hallitse luokkia" />
          </MenuItem>
          <Divider variant="middle" />
          <MenuItem onClick={logout}>
            <StyledListItemIcon>
              <LogoutIcon />
            </StyledListItemIcon>
            <ListItemText primary="Kirjaudu ulos" />
          </MenuItem>
        </Menu>
      </Grid>
    </Container>
  )
})

export default NavMenu
