import {
  Grid,
  Hidden,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow
} from '@material-ui/core'
import { MainSegment, Supplier } from 'types/Supplier'
import { Order, customSort } from 'utils/sorting'
import React, { ChangeEvent, FC, useContext, useState } from 'react'

import SupplierTableHead from './SupplierTableHead'
import SupplierTableRow from './SupplierTableRow'
import { TableColumns } from 'stores/UIStore'
import { dataContext } from 'stores'
import styled from 'styled-components'

const Pagination = styled(TablePagination)`
  border-bottom: none;
  padding: auto;
  height: 72px;
`

const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 8px;
  table-layout: auto;
  width: 1392px;

  &.th,
  td {
    width: 140px;
    overflow-wrap: anywhere;
  }

  &.th:nth-child(1),
  td:nth-child(1) {
    width: 145px;
  }

  &.th:last-child,
  td:last-child {
    width: 170px;
  }

  @media all and (max-width: 600px) {
    width: 100%;

    &.th,
    td {
      width: 50%;
      overflow-wrap: anywhere;
    }
  }
`

interface Props {
  page: number
  hasSearchEngineSorting: boolean
  suppliers: Supplier[]
  columns: TableColumns
  handleChangePage: (_e: unknown, page: number) => void
  isNewSuppliers?: boolean
  selectedLinja?: MainSegment[]
}

/**
 * change order: desc -> asc -> undefined -> desc -> ...
 */
const changeOrder = (order: Order): Order => {
  if (!order) {
    return 'desc'
  }
  if (order === 'desc') {
    return 'asc'
  }
  return undefined
}

/**
 * append/delete/update sort order
 * sorting depends on the array order.
 * first item gets ordered first, then the second, ...
 */
const updateSortOrder = (
  sortOrder: SortOrder[],
  property: keyof TableColumns,
  columns: TableColumns
): SortOrder[] => {
  // filter out only visible columns
  const newSortOrder = sortOrder.filter(({ property }) => columns[property])

  const currentIndex = sortOrder.findIndex((item) => item.property === property)
  // append if property does not exist
  if (currentIndex === -1) {
    newSortOrder.push({ property, order: 'desc' })
    return newSortOrder
  }
  const newOrder = changeOrder(newSortOrder[currentIndex].order)
  // remove if order is undefined
  if (!newOrder) {
    newSortOrder.splice(currentIndex, 1)
    return newSortOrder
  }

  // update new order
  newSortOrder[currentIndex].order = newOrder
  return newSortOrder
}

export interface SortOrder {
  order: Order
  property: keyof TableColumns
}

export const sortBySupplierCategory: SortOrder = {
  property: 'supplierCategory',
  order: 'desc'
}

export const sortSearchResults: SortOrder[] = [
  { property: 'seasonContracts', order: 'desc' },
  { property: 'supplierCategory', order: 'desc' }
]

const SupplierTable: FC<Props> = (props) => {
  const dataStore = useContext(dataContext)

  const { suppliers, handleChangePage, hasSearchEngineSorting, page, columns } = props
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [sortOrder, setSortOrder] = useState<SortOrder[]>([sortBySupplierCategory])

  const handleRequestSort = (_e: React.MouseEvent<unknown>, property: keyof TableColumns) => {
    const newSortOrder = updateSortOrder(sortOrder, property, columns)
    dataStore.setSortOrder(sortOrder)
    setSortOrder(newSortOrder)
    handleChangePage(null, 0)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value))
    handleChangePage(null, 0)
  }

  const sorting = () => {
    // when hasSearchEngineSorting is true, apply custom sorting
    return hasSearchEngineSorting
      ? customSort(suppliers, sortSearchResults)
      : customSort(suppliers, sortOrder)
  }

  const paginatedSuppliers = () => {
    return sorting().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }

  return (
    <Grid item xs={12}>
      <TableContainer>
        <StyledTable stickyHeader>
          <SupplierTableHead
            sortOrder={sortOrder}
            onRequestSort={handleRequestSort}
            disableActiveSort={!hasSearchEngineSorting}
            columns={columns}
            isNewSuppliers={props.isNewSuppliers}
          />
          <TableBody>
            {paginatedSuppliers().map((supplier) => (
              <SupplierTableRow
                supplier={supplier}
                key={supplier.businessId}
                columns={columns}
                isNewSuppliers={props.isNewSuppliers}
                selectedLinja={props.selectedLinja}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <Pagination
                rowsPerPageOptions={[15, 30, 50]}
                count={suppliers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={
                  <>
                    <Hidden xsDown>Toimittajaa per sivu:</Hidden>
                    <Hidden smUp>Sivu:</Hidden>
                  </>
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </StyledTable>
      </TableContainer>
    </Grid>
  )
}

export default SupplierTable
