import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import React, { FC } from 'react'

import { ArrowDownIcon } from 'components/common/Styled'
import { ReactComponent as ClearIcon } from 'assets/svg/clear.svg'
import styled from 'styled-components'

const Container = styled(Grid)`
  margin-top: 164px;
`

const CloseButton = styled(Box)`
  float: right;
`
const Red = styled.span`
  color: red;
`
const Orange = styled.span`
  color: orange;
`
const Green = styled.span`
  color: green;
`
const ExpandIcon = styled(ArrowDownIcon)`
  margin-bottom: 7px;
`
const Title = styled(Typography)`
  font-weight: 500;
  font-size: 24px;
`

const StyledPaper = styled(Paper)`
  width: 690px;
  max-width: 100%;
`

interface Props {
  handleClose: () => void
}

const Guide: FC<Props> = ({ handleClose }) => {
  return (
    <Container
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      onClick={handleClose}
    >
      <StyledPaper onClick={(e) => e.stopPropagation()}>
        <Box width="100%">
          <CloseButton component={ButtonBase} onClick={handleClose} mt={2} mr={2}>
            <ClearIcon />
          </CloseButton>
        </Box>
        <Box m={3}>
          <Title>Selitteet</Title>
          <Box mb={3} />
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              <Typography>
                <b>Luottoluokka</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Luottoluokka kertoo hankinnan arvion toimittajasta.
                <Box mt={1} />
                <Green>AAA Korkein luottoluokitus</Green>
                <br /> Luottoluokitus edellyttää vakiintunutta toimintaa, positiivista tai
                ei-negatiivista taustaa ja maksutapaa, sekä hyvää taloutta.
                <Box mt={1} />
                <Green>AA Hyvä luottokelpoisuus</Green>
                <br />
                Luottoluokitus edellyttää vakiintunutta toimintaa, positiivista tai ei-negatiivista
                taustaa ja maksutapaa, sekä tyydyttävää taloutta.
                <Box mt={1} />
                <Green>A Luottokelpoinen Luottoluokitus</Green>
                <br />
                Edellyttää vakiintunutta toimintaa, positiivista tai ei-negatiivista taustaa ja
                maksutapaa, sekä hyvää taloutta.
                <Box mt={1} />
                <Orange>Uusi yritys (AN)/ tausta ei-negatiivinen</Orange>
                <br />
                Luottoluokitus edellyttää vakiintunutta toimintaa, positiivista tai ei-negatiivista
                taustaa ja maksutapaa, sekä tyydyttävää taloutta.
                <Box mt={1} />
                <Orange>Ei Rating</Orange>
                <br />
                Yrityksen tiedot ovat puutteelliset tai ristiriitaiset tai kyseessä on kohde, joka
                ei käsitteellisesti ole liikeyritys.
                <Box mt={1} />
                <Red>B Epätyydyttävä</Red>
                <br />
                Luottoluokitus johtuu normaalisti negatiivisesta maksutavasta tai siitä, että
                tilinpäätösanalyysin mukaan yrityksen talous on epätyydyttävä.
                <Box mt={1} />
                <Red>C Luotonantoa ei puolleta</Red>
                <br />
                Luottoluokitus johtuu normaalisti erittäin negatiivisesta maksutavasta tai siitä,
                että tilinpäätösanalyysin mukaan yrityksen talous on heikko.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              <Typography>
                <b>Toimittajaluokka</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Toimittajaluokka kertoo toimittajan asemasta Firalla toimittajien joukossa.
                <Box mt={1} />
                <b>Ei hyväksytyt</b>
                <br /> toimittajat eivät täytä lakisääteisiä tai Firan asettamia velvoitteita tai
                yrityksen taloudellinen tila on heikko.
                <Box mt={1} />
                <b>Hyväksytyt</b>
                <br /> toimittajat täyttävät lakisääteiset sekä Firan asettamat velvoitteet ja ovat
                taloudellisesti terveellä pohjalla.
                <Box mt={1} />
                <b>Suositellut</b>
                <br /> toimittajat ovat säännöllisiä yhteistyökumppaneita, joiden aikaisempi
                suoriutuminen on ollut kiitettävää.
                <Box mt={1} />
                <b>Kausisopimuskumppanit</b>
                <br />
                on valikoitu kausisopimuskilpailutusprosessin kautta yhteistyökumppaneiksi.
                <Box mt={1} />
                <b>Avaintoimittajat</b> <br />
                ovat rakentamisen liiketoiminnan kannalta kriittisiä tai strategisesti merkittäviä
                yhteistyökumppaneita.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              <Typography>
                <b>Kausisopimus</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box mt={1} />
                <b>Sitovat kausisopimukset</b>
                <br />
                Ovat yhdessä tai useammassa rakentamisen linjassa pääasiassa käytettäviä sopimuksia,
                joihin kumppani on valikoitu kausisopimuskilpailutusprosessin kautta.
                <Box mt={1} />
                <b>Kausitarjoukset</b>
                <br />
                Ovat hankinnan toimesta kilpailutettuja tarjouksia, joita saa hyödyntää tuotannossa.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              <Typography>
                <b>Ostot 2019</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box mt={1} />
                <b>Ostot 2019</b>
                <br />
                Ostot Firalla vuonna 2019. Kauttalaskutuksella laskuttavien toimittajien osalta
                ostot eivät ole näkyvissä.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              <Typography>
                <b>Tilaajavastuu</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box mt={1} />
                <Red>Seis</Red>
                <br />
                Yritystä ei voi käyttää Firan työmailla.
                <Box mt={1} />
                <Orange>Selvitä</Orange>
                <br />
                Tiedoissa puutteita, pyydä toimittajalta selvitys laiminlyönnistä.
                <Box mt={1} />
                <Orange>Tietoja odotetaan</Orange>
                <br />
                Jokin olennainen tieto puuttuu, pyydä selvitys toimittajalta.
                <Box mt={1} />
                <Orange>Huomioi</Orange>
                <br />
                Vapautus rekisteriin kuulumisesta, pyydä tarvittavat lisäselvitykset esimerkiksi
                toimittajan kotimaan rekistereistä.
                <Box mt={1} />
                <Green>Ok</Green>
                <br />
                Yritys on huolehtinut tilaajavastuulain mukaisista velvoitteista.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              <Typography>
                <b>Toimittajaluokkien säännöt</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Toimittajaluokat määräytyvät seuraavien sääntöjen mukaisesti
                <Box mt={1} />
                <b>Sääntö: Manuaaliset toimittajat</b>
                <br />
                Manuaalisesti ylläpidettävät luokitukset: Avain, Ostokielto, Arkistoitu
                <Box mt={1} />
                <b>Sääntö: Kausisopimus toimittajat</b>
                <br />
                Toimittajalla on voimassaoleva sitova kausisopimus
                <Box mt={1} />
                <b>Sääntö: Suositellut toimittajat</b>
                <br />
                Luottoluokitus on: AAA, AA, A, AN tai B
                <br />
                ja
                <br />
                Tilaajavastuu on: OK
                <br />
                ja
                <br />
                Toimittaja-arvioiden keskiarvo on `{'>='}` 4 (viim. 2v ja vähintään 3kpl)
                <br />
                tai
                <br />
                Suositelluksi pääsee myös, jos on väh. 4 suosittelupalautetta, joiden keskiarvo on
                yli 90 %.
                <Box mt={1} />
                <b>Sääntö: Hyväksytyt toimittajat</b>
                <br />
                Luottoluokitus on: AAA, AA, A, AN tai B
                <br />
                ja
                <br />
                Tilaajavastuu on: OK
                <br />
                ja
                <br />
                Toimittaja-arvioiden keskiarvo on `{'>='}` 2 (viim. 2v ja vähintään 3kpl) tai
                vähemmän kuin 3kpl
                <Box mt={1} />
                <b>Sääntö: Selvitä-tilaiset toimittajat</b>
                <br />
                Luottoluokitus ei ole: C
                <br />
                Tilaajavastuu ei ole: Seis
                <br />
                Luottoluokitus on: EIRA tai tietoja ei ole
                <br />
                tai
                <br />
                Tilaajavastuu on: Selvitä, Tietoja odotetaan, Huomioi tai tietoja ei ole
                <br />
                ja
                <br />
                Toimittaja-arvioiden keskiarvo on `{'>='}` 2 (viim. 2v ja vähintään 3kpl) tai
                vähemmän kuin 3kpl
                <Box mt={1} />
                <b>Sääntö: Ei-hyväksytyt toimittajat</b>
                <br />
                Luottoluokitus on: C
                <br />
                tai
                <br />
                Tilaajavastuu on: Seis
                <br />
                tai
                <br />
                Toimittaja-arvioiden keskiarvo on `{'<'}` 2 (viim. 2v ja vähintään 3kpl) tai
                vähemmän kuin 3kpl
                <Box mt={1} />
                <b>Sääntö: Arkistoidut toimittajat</b>
                <br />
                Kaupparekisteritä poistuneet siirtyvät automaattisesti arkistoiduksi
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </StyledPaper>
    </Container>
  )
}

export default Guide
