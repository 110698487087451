import { action, computed, makeObservable, observable } from 'mobx'

import { Fira2020, Fira2020Value, SupplierCategory } from 'types/Classification'
import { Fira2020Key } from 'types/Supplier'
import RootStore from './RootStore'
import classification from 'api/classification'
import { sortAlphabetically } from 'utils/sorting'

type Fira2020Occurrences = Record<Fira2020Key, string[] | undefined>

export default class ClassificationStore {
  rootStore: RootStore
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable fira2020: Fira2020 = {}
  @observable fira2020Occurrences: Fira2020Occurrences = {}

  @computed get sortedFira2020Keys() {
    const entries = Object.entries(this.fira2020).map(([key, value]) => ({ key, value }))
    const sorted = sortAlphabetically(entries)
    return sorted.map((item) => item.key)
  }

  @action
  countFira2020Occurrences = async () => {
    this.fira2020Occurrences = {}
    const totalSuppliers = [
      ...this.rootStore.dataStore.suppliersAll,
      ...this.rootStore.dataStore.newSuppliers
    ]
    totalSuppliers.forEach((supplier) => {
      supplier.fira2020?.forEach((key) => {
        this.fira2020Occurrences[key] = [
          ...(this.fira2020Occurrences[key] || []),
          supplier.businessId
        ]
      })
    })
  }

  @action
  addFira2020 = async (value: SupplierCategory) => {
    try {
      // get latest fira2020 values and determine maxKey
      const latestFira2020 = await classification.getFira2020()
      if (!latestFira2020) {
        this.rootStore.uiStore.handleError()
        return
      }
      const maxKey = Math.max(...Object.keys(latestFira2020).map((key) => +key))
      if (isNaN(maxKey)) {
        this.rootStore.uiStore.handleError()
        return
      }
      const newKey = `${maxKey + 1}`
      this.updateFira2020(newKey, value)
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  deleteFira2020 = async (key: string) => {
    try {
      await classification.deleteFira2020(key)
      this.fira2020[key] = undefined
      this.rootStore.dataStore.reindexSearchValues()
      this.rootStore.uiStore.displaySuccess()
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  getFira2020 = async () => {
    try {
      const fira2020 = await classification.getFira2020()
      if (fira2020) {
        this.fira2020 = fira2020
      }
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }

  @action
  updateFira2020 = async (key: string, value: Fira2020Value) => {
    try {
      await classification.updateFira2020(key, value)
      this.fira2020[key] = value
      this.rootStore.dataStore.reindexSearchValues()
      this.rootStore.uiStore.displaySuccess()
    } catch (error) {
      this.rootStore.uiStore.handleError(error)
    }
  }
}
