import React, { FC } from 'react'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'

interface Props {
  log: string
}

const DisplayLog: FC<Props> = ({ log }) => {
  let entries: Record<string, unknown> = {}
  try {
    entries = JSON.parse(log)
  } catch (error) {}

  return (
    <Table size="small" padding="none">
      <TableBody>
        {Object.entries(entries).map(([key, value]) => (
          <TableRow key={key}>
            <TableCell component="th" scope="row" style={{ width: '35%' }}>
              {key}
            </TableCell>
            <TableCell style={{ width: '65%' }}>
              {typeof value === 'string' ? value : JSON.stringify(value)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default DisplayLog
