import React, { FC, useContext } from 'react'

import { Button } from '@material-ui/core'
import CustomTooltip from 'components/common/CustomTooltip'
import { Fira2020 } from 'types/Classification'
import VisibleClassifications from './VisibleClassifications'
import { classificationContext } from 'stores'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  font-style: normal;
  font-weight: normal;
  line-height: 1.43;
  text-transform: lowercase;
  padding: 0;
`

interface Props {
  isHorizontal?: boolean
  fira2020?: string[]
}

export const getFira2020DisplayValues = (array: string[] | undefined, fira2020: Fira2020) => {
  // only display the first value form the arrays
  return (
    array?.flatMap((key) => {
      const supplierCategoryNames = fira2020[key]?.names || []
      return supplierCategoryNames.length > 0 ? supplierCategoryNames[0] : []
    }) || []
  )
}

const ClassificationDisplay: FC<Props> = observer(({ fira2020, isHorizontal }) => {
  const classificationStore = useContext(classificationContext)
  // only display the first value form the arrays
  const classifications = getFira2020DisplayValues(fira2020, classificationStore.fira2020)

  const visible = isHorizontal ? 2 : 4
  const visibleClassifications = classifications.slice(0, visible)
  const tooltipClassifications = classifications.slice(visible)
  const tooltipLength = tooltipClassifications.length
  return (
    <>
      <VisibleClassifications
        content={visibleClassifications}
        isHorizontal={!!isHorizontal}
        additionalContent={tooltipClassifications}
      />
      {!!isHorizontal && tooltipLength !== 0 && (
        <CustomTooltip disableHover={false} title={tooltipClassifications.join(', ')}>
          <StyledButton variant="text" disableRipple disableFocusRipple color="primary">
            {`+${tooltipLength} muuta`}
          </StyledButton>
        </CustomTooltip>
      )}
    </>
  )
})

export default ClassificationDisplay
