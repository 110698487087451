import React, { FC, useContext, useEffect } from 'react'
import { Theme, useMediaQuery } from '@material-ui/core'
import { dataContext, uiContext } from 'stores'

import { RouteComponentProps } from 'react-router-dom'
import SupplierDrawer from 'components/drawer/SupplierDrawer'
import SupplierTable from './base/SupplierTable'
import SupplierTableController from './controls/SupplierTableController'
import { observer } from 'mobx-react-lite'

export type SupplierRouteParams = { businessId?: string; path?: string }
interface Props extends RouteComponentProps<SupplierRouteParams> {}

const Suppliers: FC<Props> = observer(() => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const dataStore = useContext(dataContext)
  const uiStore = useContext(uiContext)

  const handleChangePage = (_e: unknown, newPage: number) => {
    dataStore.setPage(newPage)
  }

  useEffect(() => {
    dataStore.clearSearch()
  }, [dataStore])

  return (
    <>
      <SupplierTableController />
      <SupplierTable
        suppliers={dataStore.suppliers}
        page={dataStore.page}
        handleChangePage={handleChangePage}
        columns={isMobile ? uiStore.mobileColumns : uiStore.selectedColumns}
        hasSearchEngineSorting={dataStore.hasSearchEngineSorting}
        selectedLinja={dataStore.selectedLinja}
      />
      <SupplierDrawer supplier={uiStore.selectedSupplier} />
    </>
  )
})

export default Suppliers
